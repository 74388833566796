import { Stack, StackProps, Typography, TypographyProps } from "@mui/material";
import { isEmpty } from "lodash";
import React, { ReactNode } from "react";
import { Link, To } from "react-router-dom";

interface InfoLabelProps {
  label: ReactNode;
  value: ReactNode;
  stackProps?: StackProps;
  labelProps?: TypographyProps;
  valueProps?: TypographyProps;
  to?: To;
}

const InfoLabel: React.FC<InfoLabelProps> = ({
  label,
  value,
  to,
  ...props
}) => {
  return (
    <Stack {...props?.stackProps}>
      <Typography
        variant="caption"
        {...props?.labelProps}
        sx={{ whiteSpace: "nowrap", ...props?.labelProps?.sx }}
      >
        {label}
      </Typography>
      <Typography
        component={to ? Link : "span"}
        to={to}
        variant="subtitle2"
        {...props?.valueProps}
        sx={{
          whiteSpace: "nowrap",
          color: "inherit",
          textDecoration: "none",
          ...props?.valueProps?.sx,
        }}
      >
        {isEmpty(value?.toString()) ? "-" : value}
      </Typography>
    </Stack>
  );
};

export default InfoLabel;
