import React, { useCallback, useRef } from "react";
import { CompanyUnit } from "../../models/company-unit.model";
import http from "../../services/http";
import AutocompleteField, {
  AutoCompleteComponentRef,
  AutoCompleteFieldOption,
  AutoCompleteFieldProps,
} from "../fields/autocomplete-field";

type UnitAutoCompleteFieldProps = AutoCompleteFieldProps & {};

const UnitAutoCompleteField: React.FC<UnitAutoCompleteFieldProps> = ({
  ...props
}) => {
  const environmentInputRef = useRef<AutoCompleteComponentRef>();

  const fetchUnits = useCallback(
    async (query?: string): Promise<AutoCompleteFieldOption[]> => {
      const { data, status } = await http.get<CompanyUnit[]>("units", {
        params: {
          ...(query ? { search: query } : {}),
        },
      });

      if (status === 200) {
        return data.map((unit: CompanyUnit) => ({
          title: unit.name,
          value: unit.id,
          keywords: [
            unit.name,
            unit.fantasyName,
            unit.businessName,
            unit.document,
          ],
          data: unit,
        }));
      }
      return [];
    },
    []
  );

  return (
    <AutocompleteField
      label="Unidade"
      onSearchOptions={fetchUnits}
      {...props}
      ref={environmentInputRef}
    />
  );
};

export default UnitAutoCompleteField;
