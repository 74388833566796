import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
  CircularProgress,
  FilterOptionsState,
  TextField,
} from "@mui/material";
import { Field, FieldProps, useField } from "formik";
import { isFunction, uniqBy, debounce } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

export interface AutoCompleteOnSelectedHelper {
  open(): void;
  addOptions(options: AutoCompleteFieldOption[]): void;
}

export interface AutoCompleteFieldOption<T = unknown> {
  title: string | number;
  value: any;
  keywords?: string[];
  ref?: string | number;
  data?: T | Record<string, any>;
  onSelected?(
    option: AutoCompleteFieldOption<T>,
    helpers: AutoCompleteOnSelectedHelper
  ): void | Promise<void>;
}

export type AutoCompleteFieldProps = Partial<
  AutocompleteProps<any, any, any, any>
> & {
  name: string;
  label?: string;
  autoFocus?: boolean;
  defaultOptions?: AutoCompleteFieldOption[];
  onSearchOptions?(
    query?: string
  ): Promise<AutoCompleteFieldOption[]> | AutoCompleteFieldOption[];
  onSelectOption?(value?: AutoCompleteFieldOption): Promise<void> | void;
  renderOption?(
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState
  ): React.ReactNode | undefined;
  onSetValue?(value: AutoCompleteFieldOption): Promise<any> | any;
};

export interface AutoCompleteComponentRef {
  addOption(option: AutoCompleteFieldOption, select: boolean): void;
  resetField(): void;
}

const onSetValueDefault: AutoCompleteFieldProps["onSetValue"] = (value) =>
  value?.value;

const AutoCompleteField: React.ForwardRefRenderFunction<
  AutoCompleteComponentRef | undefined,
  AutoCompleteFieldProps
> = (
  {
    name,
    label,
    onSearchOptions,
    defaultOptions = [],
    renderOption,
    onSelectOption,
    onSetValue = onSetValueDefault,
    autoFocus,
    onChange: nativeOnChange,
    ...props
  },
  ref
) => {
  const inputRef = useRef(ref);
  const [{ value: valueFormField }, { initialValue }, { setValue }] =
    useField(name);

  const ready = useRef<boolean>(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] =
    useState<AutoCompleteFieldOption[]>(defaultOptions);

  const addOptions = useCallback((newOptions: AutoCompleteFieldOption[]) => {
    setOptions([...uniqBy([...newOptions], (o) => o.value)]);
  }, []);

  const searchOptions = useMemo(
    () =>
      debounce(async (query?: string) => {
        setLoading(true);
        if (onSearchOptions) {
          addOptions(await onSearchOptions(query));
        } else if (defaultOptions) {
          addOptions(defaultOptions);
        }
        setLoading(false);
      }, 400),
    [addOptions, defaultOptions, onSearchOptions]
  );

  const filterOptions = (
    op: AutoCompleteFieldOption[],
    state: FilterOptionsState<any>
  ) => {
    return op.filter((o) => {
      if (!o.keywords && !isFunction(o.value)) {
        return (
          String(o.title).indexOf(state.inputValue.toString()) !== -1 ||
          o.value === state.inputValue
        );
      }
      if (
        !o.keywords ||
        (Array.isArray(o.keywords) && o.keywords.length === 0)
      ) {
        return true;
      }
      const keywords = (o.keywords ?? []).reduce((count, keyword) => {
        if (
          String(keyword ?? "")
            .toLocaleLowerCase()
            .indexOf(state.inputValue.toLocaleLowerCase()) > -1
        ) {
          return count + 1;
        }
        return count;
      }, 0);

      if (Array.isArray(valueFormField)) {
        return ![...valueFormField].includes(onSetValue(o));
      }

      return keywords > 0;
    });
  };

  const [valueField, setValueField] = useState("");
  const onChange = useCallback(
    async (
      _: React.SyntheticEvent<Element, Event>,
      value: AutoCompleteFieldOption,
      reason: AutocompleteChangeReason,
      ...args: any[]
    ) => {
      if (nativeOnChange) {
        nativeOnChange(_, value, reason, ...args);
      }
      if (typeof value?.value === "function") {
        const returnValue = await value.value(value);
        if (returnValue) {
          setValueField(returnValue);
        } else {
          setValueField("");
        }
        setValue(await onSetValue(initialValue));
        return;
      }

      if (reason === "selectOption" && typeof value?.value !== "function") {
        if (Array.isArray(value)) {
          const valueArray = await Promise.all(
            value.map((v) => {
              return onSetValue(v);
            })
          );
          setValue(valueArray);
          setValueField("");
        } else {
          setValue(await onSetValue(value));
          setValueField(value.title.toString());
        }

        if (value.onSelected) {
          await value.onSelected(value, {
            open: () => setOpen(true),
            addOptions: setOptions,
          });
        }
        if (onSelectOption) {
          await onSelectOption({ ...value });
        }
      }
      if (reason === "removeOption" && Array.isArray(value)) {
        const valueArray = await Promise.all(
          value.map((v) => {
            return onSetValue(v);
          })
        );
        setValue(valueArray);
      }
      if (reason === "clear") {
        setValueField("");
        setValue(null);
        if (onSelectOption) {
          onSelectOption(undefined);
        }
      }
    },
    [
      setValue,
      onSelectOption,
      setValueField,
      initialValue,
      setOptions,
      onSetValue,
      nativeOnChange,
    ]
  );

  useImperativeHandle(
    ref,
    (): AutoCompleteComponentRef => ({
      addOption(option: AutoCompleteFieldOption, select: boolean = false) {
        addOptions([option]);

        if (select) {
          setValue(option.value);
          setTimeout(() => {
            setValueField(option.title.toString());
          }, 300);
        }
      },
      resetField() {
        setValue(undefined);
        setValueField("");
      },
    }),
    [addOptions, setValue]
  );

  useEffect(() => {
    (async () => {
      if (!ready.current && open) {
        ready.current = true;
        searchOptions();
      }
    })();
  }, [searchOptions, open]);

  useEffect(() => {
    if (!open) {
      // setOptions(defaultOptions);
      if (props.multiple) {
        setValueField("");
      }
      ready.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, setValueField, props.multiple]);

  useEffect(() => {
    if (valueFormField) {
      const option = options.find((o) => o.value === valueFormField);
      if (option) {
        setValueField(String(option.title));
      }
      // else if (defaultOptions.length > 0) {
      //   const optionDefault = defaultOptions.find((o) => {
      //     return o.value === valueFormField;
      //   });

      //   if (optionDefault) setValueField(optionDefault.title?.toString());
      // }
    } else if (valueFormField === undefined) {
      // setValueField("");
    }
  }, [valueFormField, options]);

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <Autocomplete
          {...props}
          ref={inputRef}
          open={open}
          inputValue={valueField ?? ""}
          // defaultValue={valueFormField}
          onOpen={() => {
            setOpen(true);
          }}
          ChipProps={{ size: "small" }}
          onClose={() => {
            setOpen(false);
          }}
          filterOptions={filterOptions}
          isOptionEqualToValue={(option, value) => {
            return option.title === value.title;
          }}
          getOptionLabel={(option: any) => {
            return option.title;
          }}
          options={options}
          loading={loading}
          loadingText="Carregando..."
          noOptionsText="Nenhum encontrado"
          renderOption={renderOption as any}
          onInputChange={(
            _,
            value: string,
            reason: AutocompleteInputChangeReason
          ) => {
            if (reason === "input" && onSearchOptions) {
              debounce(() => searchOptions(value), 500)();
            }
            if (reason === "input" && !Array.isArray(value)) {
              setValueField(value);
            }
          }}
          clearOnBlur
          onChange={onChange}
          onBlur={field.onBlur}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus={autoFocus}
              label={label}
              name={field.name}
              value={field.value ?? meta.initialValue ?? ""}
              error={!!meta.error ?? !!meta.initialError}
              helperText={meta.error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    </Field>
  );
};

export default forwardRef(AutoCompleteField);
