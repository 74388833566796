import { Person } from "../models/person.model";

export const generateKeywords = (person: Person): string[] => {
  return [
    person.fullName,
    ...(person.documents
      ? [...person.documents.map((d: any) => d.document)]
      : []),
  ];
};

export const getGender = (gender: Person["gender"] | undefined) => {
  switch (gender) {
    case "male":
      return "Masculino";
    case "female":
      return "Feminino";
    default:
      return "Outros";
  }
};
