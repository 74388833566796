import { TextField, TextFieldProps } from "@mui/material";
import {
  DatePicker,
  TimePicker,
  DatePickerProps,
  TimePickerProps,
} from "@mui/x-date-pickers";
import { Field, FieldProps } from "formik";
import moment, { Moment } from "moment";
import React, { forwardRef } from "react";

interface DatePickerFieldProps extends Partial<DatePickerProps<any, any>> {
  type: "date";
}

interface TimePickerFieldProps extends Partial<TimePickerProps<any, any>> {
  type: "time";
}

type DateTimeFieldProps = (DatePickerFieldProps | TimePickerFieldProps) & {
  name: string;
  label?: string;
  format?: string;
  textFieldProps?: TextFieldProps;
};

moment.updateLocale(moment.locale(), { invalidDate: "" });

const DateTimeField: React.ForwardRefRenderFunction<any, DateTimeFieldProps> = (
  { name, label, type, format = "DD/MM/YYYY", textFieldProps, ...props },
  ref
) => {
  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => (
        <>
          {type === "date" && (
            <DatePicker
              {...(props as DatePickerFieldProps)}
              ref={ref}
              label={label}
              value={field.value ?? meta.initialValue ?? ""}
              onChange={(...args) => {
                const date: Moment = args[0];
                if (date?.isValid()) {
                  form.setFieldValue(name, date);
                  if (props.onChange) {
                    props.onChange(...args);
                  }
                }
              }}
              inputFormat={format}
              renderInput={(p) => (
                <TextField
                  {...p}
                  name={name}
                  variant={p.variant}
                  onBlur={field.onBlur}
                  helperText={meta.error}
                  error={!!meta.error ?? !!meta.initialError}
                  {...textFieldProps}
                />
              )}
            />
          )}
          {type === "time" && (
            <TimePicker
              {...(props as TimePickerFieldProps)}
              ref={ref}
              label={label}
              value={field.value ?? meta.initialValue ?? ""}
              onChange={(...args) => {
                const date: Moment = args[0];
                if (date?.isValid()) {
                  form.setFieldValue(name, date);
                  if (props.onChange) {
                    props.onChange(...args);
                  }
                }
              }}
              inputFormat={format}
              renderInput={(p) => (
                <TextField
                  {...p}
                  name={name}
                  onBlur={field.onBlur}
                  onFocus={field.onBlur}
                  helperText={meta.error}
                  error={!!meta.error ?? !!meta.initialError}
                />
              )}
            />
          )}
        </>
      )}
    </Field>
  );
};

export default forwardRef(DateTimeField);
