export interface Role {
  label: {
    male: string;
    female: string;
    other: string;
  };
  value: string;
}

export const roles: Role[] = [
  {
    label: {
      male: "Administrador",
      female: "Adminsitrador",
      other: "Administrador",
    },
    value: "administrator",
  },
  {
    label: { male: "Gerente", female: "Gerente", other: "Gerência" },
    value: "manager",
  },
  {
    label: {
      male: "Recepcionista",
      female: "Recepcionista",
      other: "Recepção",
    },
    value: "receptionist",
  },
  {
    label: { male: "Enfermeiro", female: "Enfermeira", other: "Enfermagem" },
    value: "nurse",
  },
  {
    label: { male: "Doutor", female: "Doutora", other: "Doutorado" },
    value: "doctor",
  },
  {
    label: { male: "Suporte", female: "Suporte", other: "Suporte" },
    value: "support",
  },
  {
    label: { male: "Técnico", female: "Técnico", other: "Técnico" },
    value: "technician",
  },
  {
    label: { male: "Financeiro", female: "Financeiro", other: "Financeiro" },
    value: "financial",
  },
];

export const getRole = (value: string): Role => {
  return (
    roles.find((s) => s.value === value) ?? {
      label: { male: value, female: value, other: value },
      value,
    }
  );
};
