import React, { useCallback, useMemo, useState } from "react";
import {
  Chip,
  Collapse,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useFormikContext } from "formik";

import { sumBy } from "lodash";
import PartnerAgreementAutoCompleteField from "../autocompletes/partner-agreement-autocomplete-field";
import TextField from "../fields/text-field";
import { Validator } from "../fields/form-wrapper";
import CheckboxField from "../fields/checkbox-field";
import { formatDate } from "../../helpers/date";
import InfoLabel from "../info-label";
import { formatNumber } from "../../helpers/currency";
import { Invoice } from "../../models/invoice.model";
import { Transaction } from "../../models/transaction.model";
import { ServicePartnerAgreementPricing } from "../../models/service.model";

export const TransactionSchemaValidation = Validator.object().shape({
  // value: Validator.number().min(0).required().label("Valor"),
  orders: Validator.array()
    .min(1)
    .of(
      Validator.object().shape({
        id: Validator.string().uuid().required(),
        selected: Validator.boolean().required(),
      })
    )
    .transform((v) => {
      return Array.isArray(v) ? v.filter((vv: any) => !!vv.selected) : v;
    }),
  type: Validator.string()
    .required()
    .oneOf(["healthInsurance", "posDebit", "posCredit", "pix", "money"])
    .label("Tipo"),
  healthInsurance: Validator.object().when("type", {
    is: (type: string) => type === "healthInsurance",
    then: (schema) =>
      schema.shape({
        partnerAgreement: Validator.object().shape({
          id: Validator.string().nullable().required().label("Convênio"),
        }),
        code: Validator.string()
          .nullable()
          .required()
          .label("N. Carteirinha / Voucher"),
        voucher: Validator.string().nullable().label("Autorização"),
      }),
  }),
});

const TransactionForm: React.FC<{ invoice: Invoice }> = ({ invoice }) => {
  const form = useFormikContext<any>();

  const [pricings, setPricings] = useState<ServicePartnerAgreementPricing[]>(
    []
  );

  const availableValue = useMemo(() => {
    if (!invoice) return 0;
    return +invoice.transactions
      .reduce((total: number, transaction: Transaction) => {
        if (transaction.status === "concluded") {
          return +total - +transaction.value;
        }
        return total;
      }, +invoice.totalValues)
      .toFixed(2);
  }, [invoice]);

  const totalSelectedOrders = useMemo(() => {
    if (!form.values.orders) return 0;
    return sumBy(
      (form.values?.orders ?? []).filter((o: any) => !!o.selected),
      (order: any) => {
        return +order.value;
      }
    );
  }, [form.values?.orders]);

  const serviceResetSelected = useCallback(() => {
    form.setFieldValue(
      "orders",
      (form.values?.orders ?? []).map((o: any) => ({
        ...o,
        selected: false,
      }))
    );
  }, [form]);

  return (
    <Stack spacing={2}>
      <ToggleButtonGroup
        fullWidth
        exclusive
        color="primary"
        value={form.values.type}
        onChange={(_, value) => {
          form.setFieldValue("type", value);
          if (value === "healthInsurance") {
            serviceResetSelected();
          }
        }}
      >
        {[
          { label: "Convênio", value: "healthInsurance" },
          { label: "Débito", value: "posDebit" },
          { label: "Crédito", value: "posCredit" },
          { label: "Pix", value: "pix" },
          { label: "Dinheiro", value: "money" },
        ].map((item) => (
          <ToggleButton key={item.value} value={item.value}>
            {item.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Collapse in={form?.values?.type === "healthInsurance"} unmountOnExit>
        <Stack spacing={2}>
          <PartnerAgreementAutoCompleteField
            label="Convênio"
            name="healthInsurance.partnerAgreement.id"
            onChange={(_, value) => {
              setPricings(value?.data?.pricings ?? []);
              serviceResetSelected();
            }}
          />
          <TextField
            name="healthInsurance.code"
            label="N. Carteirinha / Voucher"
          />
          <TextField
            name="healthInsurance.voucher"
            label="Código de Autorização"
          />
        </Stack>
      </Collapse>
      {(form.values?.orders ?? []).map((order: any, key: number) => (
        <Paper
          component={Stack}
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          key={key}
          sx={{ px: 2, py: 1 }}
          variant="outlined"
        >
          <CheckboxField
            disabled={
              (form.values.type === "healthInsurance" || !form.values.type) &&
              !pricings.find((p) => p.service.id === order?.service?.id)
            }
            type="checkbox"
            name={`orders.[${key}].selected`}
            label={order.name}
          />
          {form.values.type === "healthInsurance" &&
            !!form.values?.healthInsurance?.partnerAgreement?.id &&
            !pricings.find((p) => p.service.id === order?.service?.id) && (
              <Chip label="sem cobertura" size="small" color="warning" />
            )}
          {!!order.createdAt && formatDate(order.createdAt)}
        </Paper>
      ))}
      <Collapse in={form.values.type !== "healthInsurance"} unmountOnExit>
        <Stack direction="row" spacing={1}>
          <Paper elevation={0} sx={{ p: 2, flex: 1, bgcolor: "grey.100" }}>
            <InfoLabel
              label="Valor Pendente"
              value={formatNumber(availableValue ?? 0)}
            />
          </Paper>
          <Paper elevation={0} sx={{ p: 2, flex: 1, bgcolor: "grey.100" }}>
            <InfoLabel
              label="Valor Selecionado"
              value={formatNumber(totalSelectedOrders ?? 0)}
            />
          </Paper>
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default TransactionForm;
