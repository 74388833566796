import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { isFunction } from "lodash";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

interface ModalItem {
  title?: ReactNode;
  content: ReactNode | ((modal: ModalItemWithIdentity) => ReactNode);
  onClose?(any: any): void | Promise<void>;
  options?: Omit<DialogProps, "open">;
}

interface ModalContextProperties {
  open(modal: ModalItem): void;
  closeAll(params?: ModalItemClose["params"]): void;
}

interface ModalItemContextProperties {
  id: number;
  close(params?: ModalItemClose["params"]): void;
}

interface ModalItemWithIdentity extends ModalItem {
  id: number;
}

interface ModalItemClose {
  id: ModalItemWithIdentity["id"];
  onClose?: ModalItemWithIdentity["onClose"];
  params?: any;
}

const ModalContext = createContext<ModalContextProperties | undefined>(
  undefined
);

const ModalItemContext = createContext<ModalItemContextProperties | undefined>(
  undefined
);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) throw new Error("modal provider required");
  return context;
};

export const useItemModal = () => {
  const context = useContext(ModalItemContext);
  if (!context) throw new Error("item modal provider required");
  return context;
};

export const ModalLoading = () => (
  <Box
    display="flex"
    flex={1}
    flexGrow={1}
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress color="primary" />
  </Box>
);

const ModalProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const theme = useTheme();
  const modalRef = useRef(0);
  const [modals, setModals] = useState<ModalItemWithIdentity[]>([]);
  const [current, setCurrent] = useState<number[]>([]);

  const addModal = useCallback((item: ModalItem) => {
    const currentIdentity = modalRef.current;
    modalRef.current += 1;
    setModals((old) => {
      old.push({
        ...item,
        id: currentIdentity,
      });
      return [...old];
    });

    setTimeout(() => {
      setCurrent((old) => {
        return [...old, currentIdentity];
      });
    }, 0);
  }, []);

  const openModal = useCallback(
    (item: ModalItem) => {
      addModal(item);
    },
    [addModal]
  );

  const closeModal = useCallback(
    (modalItem: ModalItemClose) => {
      setCurrent((old) => {
        return [...old.filter((index) => index !== modalItem.id)];
      });

      setTimeout(() => {
        setModals((old) => {
          return [...old.filter((d) => d.id !== modalItem.id)];
        });
        if (modalItem.onClose) modalItem.onClose(modalItem.params ?? undefined);
      }, theme.transitions.duration.leavingScreen);
    },
    [theme]
  );

  const closeAll = useCallback(
    (params?: ModalItemClose["params"]) => {
      modals.forEach((modal) => {
        closeModal({
          id: modal.id,
          onClose: modal.onClose,
          params,
        });
      });
    },
    [modals, closeModal]
  );

  const props = useMemo(
    () => ({
      open: openModal,
      closeAll,
    }),
    [openModal, closeAll]
  );

  const propsItem = useCallback(
    (modalItem: Omit<ModalItemWithIdentity, "content">) => ({
      id: modalItem.id,
      close(params?: any) {
        closeModal({
          id: modalItem.id,
          onClose: modalItem.onClose,
          params,
        });
      },
    }),
    [closeModal]
  );

  return (
    <ModalContext.Provider value={props}>
      {modals.map((modal: ModalItemWithIdentity) => (
        <Dialog
          key={modal.id}
          {...modal?.options}
          onClose={() => {
            closeModal({
              id: modal.id,
              onClose: modal.onClose,
            });
          }}
          open={current.includes(modal.id)}
        >
          <ModalItemContext.Provider
            value={propsItem({
              id: modal.id,
              onClose: modal.onClose,
            })}
          >
            {modal.title && (
              <DialogTitle sx={{ textTransform: "uppercase" }}>
                {modal.title}
              </DialogTitle>
            )}
            <DialogContent>
              {isFunction(modal.content) ? modal.content(modal) : modal.content}
            </DialogContent>
          </ModalItemContext.Provider>
        </Dialog>
      ))}
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
