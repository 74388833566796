import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Paper, Stack } from "@mui/material";
import { DefaultHeader } from "../default-header";
import http from "../../services/http";
import { DrawerLoading, useItemDrawer } from "../../hooks/drawer.hook";
import { Order } from "../../models/order.model";

interface OrderPrintViewerProps {
  orders: Order[];
}

const OrderPrintViewer: React.FC<OrderPrintViewerProps> = ({ orders }) => {
  const itemDrawer = useItemDrawer();

  const pdfIframeRef = useRef<HTMLIFrameElement>(null);

  const [loading, setLoading] = useState(false);
  const [urlPDF, setUrlPDF] = useState<string>();

  const fetchOrders = useCallback(async (ids: string[]) => {
    setLoading(true);
    const { status, data } = await http.get(`orders`, {
      headers: {
        "Content-Type": "text/html",
        Accept: "text/html",
      },
      responseType: "blob",
      params: {
        id: ids,
      },
    });
    setLoading(false);
    if (status === 200) {
      const blob = new Blob([data], {
        type: "text/html",
      });

      setUrlPDF(URL.createObjectURL(blob));
    }
  }, []);

  useEffect(() => {
    fetchOrders(orders.map((o) => o.id));
  }, [orders, fetchOrders]);

  return (
    <>
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.grey[100],
          position: "relative",
        }}
      >
        <Paper sx={{ p: 2 }} square>
          <DefaultHeader title="Imprimir" gutterBottom={false}>
            <Stack spacing={1} direction="row">
              <Button
                variant="contained"
                disabled={!pdfIframeRef.current || loading}
                onClick={() => pdfIframeRef.current?.contentWindow?.print()}
              >
                Imprimir
              </Button>
              <Button onClick={() => itemDrawer.close()}>Fechar</Button>
            </Stack>
          </DefaultHeader>
        </Paper>
      </Box>
      <Box height="100%" p={3} display={!loading && !!urlPDF ? "flex" : "none"}>
        <iframe
          ref={pdfIframeRef}
          frameBorder={0}
          src={urlPDF ?? undefined}
          title="View"
          style={{ width: "100%", flexGrow: 1 }}
        />
      </Box>
      {loading && <DrawerLoading />}
    </>
  );
};

export default OrderPrintViewer;
