import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  InputBase,
  IconButton,
  Button,
  ButtonProps,
  InputBaseProps,
  InputAdornment,
  Box,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { debounce, isEmpty } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Search } from "@mui/icons-material";

interface SearchFormButton {
  label: string;
  props: ButtonProps;
}

interface SearchMiddlewareValue {
  queryParamName: string;
  value: string;
  formatValue: string;
}

interface SearchFieldProps extends InputBaseProps {
  queryParamName?: string;
  queryParamPageName?: string;
  modeOnQueryChange?: "auto" | "manual";
  buttons?: SearchFormButton[];
  middlewareValue?(
    value: Omit<SearchMiddlewareValue, "formatValue">
  ): SearchMiddlewareValue | Promise<SearchMiddlewareValue>;
}

const SearchField: React.FC<React.PropsWithChildren<SearchFieldProps>> = ({
  queryParamName = "q",
  queryParamPageName = "page",
  modeOnQueryChange = "auto",
  middlewareValue,
  buttons = [],
  children,
  ...props
}) => {
  const inputRef = useRef<any>();
  const [query] = useSearchParams();
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState(
    () => query.get(queryParamName) ?? null
  );

  const [searchQueryValue, setSearchQueryValue] = useState<string | null>();

  const getSearchQueryValue = useCallback(async () => {
    const q = query.get(queryParamName);
    if (!q) {
      setSearchQueryValue(null);
    } else if (middlewareValue) {
      const v = await middlewareValue({ queryParamName, value: q });
      setSearchQueryValue(v.formatValue ?? v.value);
    } else {
      setSearchQueryValue(q);
    }
  }, [query, queryParamName, middlewareValue]);

  const onChangeQuery = useCallback(
    async (value: string) => {
      if (isEmpty(value)) {
        query.delete(queryParamName);
      } else {
        let searchValue = {
          value,
          queryParamName,
        };

        if (middlewareValue) {
          searchValue = await middlewareValue(searchValue);
        }

        query.set(searchValue.queryParamName, searchValue.value);
        if (query.has(queryParamPageName)) {
          query.delete(queryParamPageName);
        }
      }
      navigate({ search: query.toString() });
    },
    [navigate, query, queryParamName, queryParamPageName, middlewareValue]
  );

  const onChangeHandler = debounce((event) => {
    setInputValue(event.target.value ?? "");
    if (modeOnQueryChange === "auto") {
      onChangeQuery(event.target.value);
    }
  }, 300);

  const onClickSearchButtonHandler = useCallback(() => {
    if (inputValue) {
      onChangeQuery(inputValue);
    }
  }, [inputValue, onChangeQuery]);

  const onKeyPressHandler = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onChangeQuery(event.target.value);
      }
    },
    [onChangeQuery]
  );

  useEffect(() => {
    getSearchQueryValue();
  }, [getSearchQueryValue]);

  useEffect(() => {
    if (inputRef.current && !query.has(queryParamName)) {
      inputRef.current.value = "";
    }
  }, [inputRef, searchQueryValue, query, queryParamName]);

  if (searchQueryValue === undefined) return null;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <InputBase
        {...props}
        inputRef={inputRef}
        defaultValue={searchQueryValue}
        onChange={onChangeHandler}
        onKeyPress={onKeyPressHandler}
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">{children}</InputAdornment>
        }
      />
      {modeOnQueryChange === "manual" && (
        <IconButton
          onClick={onClickSearchButtonHandler}
          sx={{ padding: 10 }}
          size="large"
        >
          <SearchIcon />
        </IconButton>
      )}
      {buttons.length > 0 && (
        <Stack spacing={1} pr={2}>
          {buttons.map((button, key) => (
            <Button key={key} {...button.props}>
              {button.label}
            </Button>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default SearchField;
