import io, { ManagerOptions, SocketOptions } from "socket.io-client";

const ws = (options?: Partial<ManagerOptions & SocketOptions>) =>
  io(process.env.REACT_APP_WS_ENDPOINT as string, {
    path: process.env.REACT_APP_WS_PATH,
    autoConnect: false,
    reconnection: true,
    transports: ["websocket"],
    withCredentials: true,
    ...options,
  });

export default ws;
