import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
  useTheme,
} from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import { Field, FieldProps } from "formik";
import React, { forwardRef } from "react";

export interface InputSelectOption {
  label: string;
  value: any;
}

interface InputSelectProps extends SelectProps {
  options?: InputSelectOption[];
}

const InputSelect: React.ForwardRefRenderFunction<any, InputSelectProps> = (
  { name, options = [], ...props },
  ref
) => {
  const theme = useTheme();
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <FormControl>
          <InputLabel>{props.label}</InputLabel>
          <Select
            {...props}
            ref={ref}
            label={props.label}
            name={field.name}
            value={field.value ?? meta.initialValue ?? ""}
            onChange={(...args) => {
              field.onChange(args[0]);
              if (props.onChange) {
                props.onChange(...args);
              }
            }}
            onBlur={field.onBlur}
            onFocus={field.onBlur}
            error={!!meta.error ?? !!meta.initialError}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: theme.palette.error.main }}>
            {meta.error || meta.initialError}
          </FormHelperText>
        </FormControl>
      )}
    </Field>
  );
};

export default forwardRef(InputSelect);
