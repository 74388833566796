import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { useSnackbar } from "notistack";
import { Delete, Download, Upload } from "@mui/icons-material";
import { DefaultHeader } from "../default-header";
import InfoLabel from "../info-label";
import http from "../../services/http";

import { Order, OrderExamDocument } from "../../models/order.model";
import { getChannelDelivery, getOrderExamStatus } from "../../structs/order";
import { ModalLoading, useItemModal, useModal } from "../../hooks/modal.hook";
import FormWrapper, { SubmitButton, Validator } from "../fields/form-wrapper";
import { useDialog } from "../../hooks/dialog.hook";
import TextField from "../fields/text-field";
import { AuthContextProvider } from "../../hooks/auth.hook";
import ProfessionalAutoCompleteField from "../autocompletes/professional-autocomplete-field";
import { useDrawer, useItemDrawer } from "../../hooks/drawer.hook";
import ExamPrintViewer from "./exam-print";
import { age, formatDate } from "../../helpers/date";
import { getGender } from "../../helpers/person";

interface OrderExamManagerProps {
  order: Order;
  authProvider: AuthContextProvider;
}

interface OrderExamConfirmModalProps {
  exam: Order["exam"];
}
interface OrderExamStartModalProps {
  exam: Order["exam"];
}

const orderExamValidationSchema = Validator.object().shape({
  receiverName: Validator.string()
    .required()
    .label("Responsável do recebimento"),
});

const orderExamStartValidationSchema = Validator.object().shape({
  id: Validator.string().required().label("Responsável técnico"),
  password: Validator.string().required().label("Senha responsável técnico"),
});

const OrderExamStartModal: React.FC<OrderExamStartModalProps> = ({ exam }) => {
  const modalItem = useItemModal();
  const dialog = useDialog();

  const startExam = useCallback(
    async (values: any) => {
      const { status, data } = await http.put<Order["exam"]>(
        `exams/${exam.id}`,
        {
          status: "processing",
          professionalTechnician: {
            id: values.id,
            password: values.password,
          },
        }
      );

      if (status === 200) {
        modalItem.close(data);
      } else {
        const { code }: any = data;
        switch (code) {
          case "PROFESSIONAL.PASSWORD_CHECK_INVALID": {
            dialog.open({
              title: "Confirmação de senha",
              message: "Senha do profissional inválida",
            });
            break;
          }
          default: {
            dialog.open({
              title: "Ocorreu um erro",
              message: "Não foi possivel iniciar o procedimento",
            });
          }
        }
      }
    },
    [exam.id, modalItem, dialog]
  );

  return (
    <FormWrapper
      initialValues={{ receiveName: null }}
      validationSchema={orderExamStartValidationSchema}
      onSubmit={startExam}
    >
      <Stack spacing={2}>
        <ProfessionalAutoCompleteField
          name="id"
          label="Responsável técnico"
          roles={["nurse", "doctor", "technician"]}
        />
        <TextField name="password" type="password" label="Senha" />
        <Stack direction="row" spacing={1}>
          <Button fullWidth onClick={() => modalItem.close()}>
            Cancelar
          </Button>
          <SubmitButton variant="contained" fullWidth>
            Iniciar
          </SubmitButton>
        </Stack>
      </Stack>
    </FormWrapper>
  );
};

const OrderExamConfirmModal: React.FC<OrderExamConfirmModalProps> = ({
  exam,
}) => {
  const modalItem = useItemModal();
  const dialog = useDialog();

  const saveExam = useCallback(
    async (values: any) => {
      const { status, data } = await http.put(`exams/${exam.id}`, {
        status: "finished",
        ...values,
      });

      if (status === 200) {
        dialog.open({
          title: "Sucesso",
          message: "Confirmação de entrega efetuada com sucesso",
          buttons: [
            {
              label: "OK",
              async callback() {
                modalItem.close(data);
              },
            },
          ],
        });
      }
    },
    [dialog, exam, modalItem]
  );

  return (
    <FormWrapper
      initialValues={{ receiveName: null }}
      validationSchema={orderExamValidationSchema}
      onSubmit={saveExam}
    >
      <Stack spacing={2}>
        <TextField name="receiverName" label="Responsável do recebimento" />
        <Stack direction="row" spacing={1}>
          <Button fullWidth onClick={() => modalItem.close()}>
            Cancelar
          </Button>
          <SubmitButton variant="contained" fullWidth>
            Confirmar
          </SubmitButton>
        </Stack>
      </Stack>
    </FormWrapper>
  );
};

const OrderExamManager: React.FC<OrderExamManagerProps> = ({
  order,
  authProvider: auth,
}) => {
  const snackbar = useSnackbar();
  const drawer = useDrawer();
  const drawerItem = useItemDrawer();
  const dialog = useDialog();
  const modal = useModal();

  const [tabIndex, setTabIndex] = useState(0);
  const [loadingExam, setLoadingExam] = useState(false);
  const [exam, setExam] = useState<Order["exam"]>();
  const [documents, setDocuments] = useState<OrderExamDocument[]>([]);

  const fetchOrderExamDocuments = useCallback(async () => {
    const { status, data } = await http.get(`exams/${order.exam.id}/documents`);
    if (status === 200) setDocuments(data);
  }, [order.exam.id]);

  const fetchOrderExam = useCallback(async () => {
    setLoadingExam(true);
    const { status, data } = await http.get<Order["exam"]>(
      `exams/${order.exam.id}`
    );
    setLoadingExam(false);
    if (status === 200) {
      setExam(data);
      fetchOrderExamDocuments();
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }, [fetchOrderExamDocuments, order.exam.id]);

  const printLabel = useCallback(() => {
    drawer.open({
      element: <ExamPrintViewer exams={[order.exam]} />,
      options: {
        PaperProps: {
          sx: {
            width: "40vw",
          },
        },
      },
    });
  }, [drawer, order]);

  const finishExam = useCallback(async () => {
    const { status, data } = await http.put(`exams/${order.exam.id}`, {
      status: "done",
    });

    if (status === 200) {
      setExam((old) => {
        return {
          ...old,
          ...data,
        };
      });
      dialog.open({
        title: "Imprimir Etiqueta",
        message: "Deseja imprimir etiqueta?",
        buttons: [
          {
            label: "Não",
            async callback() {
              drawerItem.close(exam);
            },
          },
          {
            label: "Imprimir",
            options: {
              variant: "contained",
            },
            async callback() {
              printLabel();
              drawerItem.close(exam);
            },
          },
        ],
      });
    } else if (data.code) {
      switch (data.code) {
        case "ORDER_EXAM.DOCUMENT.NOT_FOUND": {
          snackbar.enqueueSnackbar(
            "Para finalizar o exame é necessário carregar os resultados",
            {
              variant: "warning",
            }
          );
          setTabIndex(2);
          break;
        }
        default: {
          snackbar.enqueueSnackbar("Ocorreu um erro ao finalizar o exame", {
            variant: "error",
          });
          break;
        }
      }
    }
  }, [order.exam.id, dialog, drawerItem, exam, printLabel, snackbar]);

  const [uploadProgress, setUploadProgress] = useState(0);

  const upload = useCallback(
    async (files: FileList) => {
      const formData = new FormData();

      if (files?.length === 0) return;

      Array.from(files ?? []).forEach((file) => formData.append("files", file));

      const { status } = await http.post(
        `exams/${order.exam.id}/documents`,
        formData,
        {
          responseType: "stream",
          onUploadProgress: (progressEvent) => {
            setUploadProgress(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );
      if (status === 201) {
        setUploadProgress(0);
        fetchOrderExamDocuments();
      }
    },
    [fetchOrderExamDocuments, order.exam.id]
  );

  const deleteDocument = useCallback(
    async (document: OrderExamDocument) => {
      const { status } = await http.delete(
        `exams/${order.exam.id}/documents/${document.id}`
      );

      if (status === 200) {
        snackbar.enqueueSnackbar("Documento deletado com sucesso", {
          variant: "success",
        });
        fetchOrderExamDocuments();
      } else {
        snackbar.enqueueSnackbar("Não foi possível deletar o documento", {
          variant: "error",
        });
      }
    },
    [fetchOrderExamDocuments, order.exam.id, snackbar]
  );

  useEffect(() => {
    fetchOrderExam();
  }, [fetchOrderExam, fetchOrderExamDocuments]);

  return (
    <Box sx={{ height: "100%" }}>
      <Collapse
        in={!loadingExam && !!exam}
        sx={{ height: "100%" }}
        unmountOnExit
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backdropFilter: "blur(10px)",
          }}
        >
          <Paper elevation={0} sx={{ p: 2, bgcolor: "grey.100" }} square>
            <DefaultHeader
              gutterBottom={false}
              title={
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar alt={order.patient?.person?.fullName} />{" "}
                    <span>{`${
                      order.patient?.person?.fullName ?? "Paciente"
                    } `}</span>
                    <Chip
                      sx={{ verticalAlign: "text-bottom" }}
                      label={`#${order?.patient.code ?? "-"}`}
                    />
                  </Stack>
                </Stack>
              }
            />
          </Paper>
          <Divider />
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ p: 1 }}
          >
            {["finished", "done"].includes(exam ? exam?.status : "") && (
              <Button onClick={printLabel}>Imprimir Etiqueta</Button>
            )}
            {["done", "verified_report"].includes(exam ? exam?.status : "") && (
              <Button
                variant="contained"
                sx={{ alignSelf: "flex-start" }}
                disabled={
                  !["done", "verified_report"].includes(exam?.status ?? "") ||
                  !auth?.hasRole([
                    "technician",
                    "doctor",
                    "manager",
                    "nurse",
                    "receptionist",
                    "administrator",
                  ])
                }
                onClick={() => {
                  modal.open({
                    title: "Confirmar recebimento",
                    content: exam ? (
                      <OrderExamConfirmModal exam={exam} />
                    ) : null,
                    onClose(data) {
                      setExam((old) => {
                        return {
                          ...old,
                          ...data,
                        };
                      });
                      drawerItem.close(exam);
                    },
                  });
                }}
              >
                Confirmar recebimento
              </Button>
            )}
            {["pending"].includes(exam ? exam?.status : "") && (
              <Button
                variant="contained"
                sx={{ alignSelf: "flex-start" }}
                disabled={
                  exam?.status !== "pending" ||
                  !auth?.hasRole([
                    "technician",
                    "doctor",
                    "nurse",
                    "administrator",
                  ])
                }
                onClick={() => {
                  modal.open({
                    title: "Iniciar procedimento",
                    content: exam ? <OrderExamStartModal exam={exam} /> : null,
                    onClose(data) {
                      setExam((old) => {
                        return {
                          ...old,
                          ...data,
                        };
                      });
                    },
                  });
                }}
              >
                Iniciar procedimento
              </Button>
            )}
            {["processing"].includes(exam ? exam?.status : "") && (
              <Button
                variant="contained"
                sx={{ alignSelf: "flex-start" }}
                disabled={
                  exam?.status !== "processing" ||
                  !auth?.hasRole([
                    "technician",
                    "doctor",
                    "nurse",
                    "administrator",
                  ])
                }
                onClick={() => {
                  dialog.open({
                    title: "CONFIRMAÇÃO",
                    message: "Deseja finalizar o procedimento?",
                    buttons: [
                      {
                        label: "Não",
                      },
                      {
                        label: "Sim",
                        options: {
                          variant: "contained",
                        },
                        callback: finishExam,
                      },
                    ],
                  });
                }}
              >
                Finalizar procedimento
              </Button>
            )}
          </Stack>
          <Divider />
          <Tabs
            value={tabIndex}
            onChange={(_, index) => {
              setTabIndex(index);
            }}
          >
            <Tab label="Perfil do Paciente" />
            {!!exam && <Tab label="Exame" />}
            {!!exam && (
              <Tab
                disabled={
                  !["processing", "verified_report"].includes(exam.status)
                }
                label="Documentos"
              />
            )}
          </Tabs>
        </Box>
        <Collapse in={tabIndex === 0} unmountOnExit>
          <Stack
            p={3}
            direction="column"
            spacing={3}
            divider={<Divider flexItem />}
          >
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <InfoLabel
                label="Gênero"
                value={getGender(exam?.order?.patient?.person.gender)}
              />
              <InfoLabel
                label="Data de Nascimento"
                value={
                  exam?.order?.patient?.person?.birthDate
                    ? format(
                        new Date(exam?.order?.patient?.person?.birthDate!),
                        "dd/MM/yyyy"
                      )
                    : null
                }
              />
              <InfoLabel
                label="Idade"
                value={
                  exam?.order?.patient?.person?.birthDate
                    ? age(new Date(exam?.order?.patient?.person?.birthDate))
                    : null
                }
              />
            </Stack>
          </Stack>
        </Collapse>
        <Collapse in={tabIndex === 1} unmountOnExit>
          <Stack
            p={3}
            direction="column"
            spacing={3}
            divider={<Divider flexItem />}
          >
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <InfoLabel label="Exame" value={exam?.order?.service?.name} />
              <InfoLabel
                label="Situação"
                value={
                  <Chip
                    size="small"
                    label={exam ? getOrderExamStatus(exam.status).label : null}
                  />
                }
              />
              <InfoLabel
                label="Tipo de entrega"
                value={
                  exam?.channelDelivery
                    ? getChannelDelivery(exam?.channelDelivery)?.label
                    : null
                }
              />
              <InfoLabel
                label="Previsão de entrega"
                value={
                  exam?.completionAt
                    ? format(parseISO(exam?.completionAt), "dd/MM/yyyy")
                    : null
                }
              />
            </Stack>
            <InfoLabel
              label="Detalhes"
              value={exam?.order?.service?.description}
              valueProps={{ sx: { whiteSpace: "normal" } }}
            />

            <InfoLabel
              label="Procedimentos"
              valueProps={{ sx: { whiteSpace: "normal" } }}
              value={exam?.order?.service?.procedures}
            />
            <Box>
              <Grid container>
                <Grid item md={6}>
                  <InfoLabel
                    label="Responsável técnico"
                    value={exam?.professionalTechnician?.person?.fullName}
                  />
                </Grid>
                <Grid item md={6}>
                  <InfoLabel
                    label="Responsável pelo Laudo"
                    value={exam?.reportProfessional?.person?.fullName}
                  />
                </Grid>
                <Grid item md={6}>
                  <InfoLabel
                    label="Responsável pelo recebimento"
                    value={exam?.receiverName}
                  />
                </Grid>
                <Grid item md={6}>
                  <InfoLabel label="Solicitante" value={exam?.requester} />
                </Grid>
              </Grid>
            </Box>
            <InfoLabel
              label="Laudo"
              valueProps={{ sx: { whiteSpace: "normal" } }}
              value={exam?.report}
            />
          </Stack>
        </Collapse>
        <Collapse in={tabIndex === 2}>
          <Stack sx={{ p: 3 }} spacing={1}>
            <Paper
              component={(props) => (
                <Button {...props} color="primary" component="label" />
              )}
              sx={{ p: 0 }}
              disabled={uploadProgress > 0}
              disableElevation
              variant="outlined"
              startIcon={
                uploadProgress > 0 ? (
                  <CircularProgress
                    variant="determinate"
                    value={uploadProgress}
                  />
                ) : (
                  <Upload />
                )
              }
            >
              <Stack sx={{ p: 3 }}>
                <Typography
                  sx={{ fontSize: 16, fontWeight: "bold", textAlign: "center" }}
                >
                  {uploadProgress > 0
                    ? `Carregando (${Math.round(uploadProgress)}%)`
                    : "Carregar documento"}
                </Typography>
              </Stack>
              <input
                multiple
                hidden
                type="file"
                onChange={(event) => {
                  const { files } = event.target;
                  if (files) upload(files);
                }}
              />
            </Paper>
            <Typography sx={{ fontWeight: "bold" }}>Documentos</Typography>
            {documents.map((document) => (
              <Paper
                key={document.id}
                component={Stack}
                spacing={1}
                variant="outlined"
                direction="row"
                sx={{ p: 3 }}
              >
                <InfoLabel
                  label="Descrição"
                  stackProps={{ flex: 1 }}
                  value={document.description}
                />
                <InfoLabel
                  label="Data de Upload"
                  value={formatDate(document.createdAt)}
                />
                <IconButton
                  component="a"
                  target="_blank"
                  download="true"
                  href={document.url}
                >
                  <Download />
                </IconButton>
                <IconButton onClick={() => deleteDocument(document)}>
                  <Delete />
                </IconButton>
              </Paper>
            ))}
            {documents.length === 0 && (
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography sx={{ fontSize: 14, color: "grey.500" }}>
                  Nenhum documento disponível
                </Typography>
              </Paper>
            )}
          </Stack>
        </Collapse>
      </Collapse>
      <Collapse in={loadingExam} unmountOnExit>
        <ModalLoading />
      </Collapse>
    </Box>
  );
};

export default OrderExamManager;
