export interface DeviceStatus {
  label: string;
  value: string;
  color: "default" | "success" | "error" | "warning" | "info";
}

export const status: DeviceStatus[] = [
  { value: "created", label: "criado", color: "default" },
  { value: "connected", label: "conectado", color: "success" },
  { value: "disconnected", label: "desconectado", color: "error" },
  { value: "validating", label: "em validação", color: "info" },
  { value: "active", label: "ativo", color: "success" },
  { value: "blocked", label: "bloqueado", color: "error" },
];
