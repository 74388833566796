import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Socket } from "socket.io-client";
import ws from "../services/ws";

type TAuthCallback = () => string | null;

export interface ISocketContextValue {
  socket: Socket | null;
  connected: boolean;
  subscribe: (event: string, callback: (data: any) => void) => void;
  unsubscribe: (event: string, callback: (data: any) => void) => void;
  restart(): void;
}

export const SocketContext = createContext<ISocketContextValue>({
  connected: false,
} as unknown as ISocketContextValue);

interface ISocketProvider {
  namespace: string;
  auth?: TAuthCallback;
}

const initWs = (namespace: string, token: string | null) =>
  ws({
    query: {
      namespace,
    },
    async auth(cb) {
      return cb({
        token,
      });
    },
  });

const SocketProvider: React.FC<PropsWithChildren<ISocketProvider>> = ({
  namespace,
  auth,
  children,
}) => {
  const [connected, setConnected] = useState<boolean>(false);
  const socketInstance = useRef<Socket | null>(
    initWs(namespace, auth?.() ?? null)
  );

  const socket = socketInstance.current;

  const subscriptions = useRef<{ [event: string]: ((data: any) => void)[] }>(
    {}
  );

  // useEffect(() => {
  //   if (socket?.io.opts.query) {
  //     socket?.io.opts.query = {
  //       ...socket?.io.opts.query,
  //       token: "123123",
  //     };
  //   }
  // }, [socket?.io.opts.query]);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        setConnected(true);
        Object.entries(subscriptions.current).forEach(([event]) => {
          socket?.emit("subscribe", { event });
        });
      });
      socket.on("disconnect", () => {
        setConnected(false);
        Object.entries(subscriptions.current).forEach(([event, callbacks]) => {
          (callbacks ?? []).forEach((c) => {
            socket.off(event, c);
          });
        });
      });
    }
    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  const contextValue = useMemo<ISocketContextValue>(() => {
    const subscribe = (event: string, callback: (data: any) => void) => {
      if (!subscriptions.current[event]) {
        socket?.emit("subscribe", { event });
      }
      subscriptions.current[event] = [
        ...(subscriptions.current[event] || []),
        callback,
      ];
      socket?.on(event, callback);
    };

    const unsubscribe = (event: string, callback: (data: any) => void) => {
      subscriptions.current[event] = subscriptions.current[event]?.filter(
        (cb) => cb !== callback
      );

      socket?.off(event, callback);

      if (subscriptions.current[event]?.length === 0) {
        socket?.emit("unsubscribe", { event });
      }
    };

    return {
      socket,
      connected,
      subscribe,
      unsubscribe,
      restart() {
        socket?.disconnect();
        socketInstance.current = initWs(namespace, auth?.() ?? null);
        socketInstance.current.connect();
      },
    };
  }, [socket, connected, namespace, auth]);

  if (!connected) {
    socket?.connect();
    return null;
  }

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);

export default SocketProvider;
