import {
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Pagination as MuiPagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import SearchField from "../../components/fields/search-field";
import InfoLabel from "../../components/info-label";
import PageContent from "../../components/page-content";
import { Pagination } from "../../models/pagination.model";
import { Service } from "../../models/service.model";
import http from "../../services/http";

import { CompanyUnit } from "../../models/company-unit.model";
import { getUnitStatus } from "../../helpers/unit";
import { DefaultHeader } from "../../components/default-header";

const UnitListPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loadingUnits, setLoadingUnits] = useState(false);
  const [units, setUnits] = useState<CompanyUnit[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<Pagination<Service>["meta"]>();

  const fetchunits = useCallback(async (searchQueryParams: URLSearchParams) => {
    setLoadingUnits(true);
    const { data, status } = await http.get<Pagination<CompanyUnit>>("units", {
      params: {
        paginate: true,
        ...Object.fromEntries(searchQueryParams),
      },
    });
    setLoadingUnits(false);

    if (status === 200) {
      setUnits(data.items);
      setPaginationMeta(data.meta);
    }
  }, []);

  useEffect(() => {
    fetchunits(searchParams);
  }, [fetchunits, searchParams]);

  return (
    <PageContent header={<DefaultHeader title="Unidades" />}>
      <Paper>
        <SearchField
          sx={{ m: 2 }}
          queryParamName="search"
          fullWidth
          placeholder="Buscar por nome ou documento"
        />
        <Divider />
        <Table>
          <TableBody>
            {units.map((unit) => (
              <TableRow key={unit.id}>
                <TableCell sx={{ width: "0%", whiteSpace: "nowrap" }}>
                  <Tooltip title="Editar">
                    <IconButton component={Link} to={`./${unit.id}`}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <InfoLabel label="Nome" value={unit.name} />
                </TableCell>
                <TableCell>
                  <InfoLabel label="Razão Social" value={unit.businessName} />
                </TableCell>
                <TableCell>
                  <InfoLabel label="Nome Fantasia" value={unit.fantasyName} />
                </TableCell>
                <TableCell>
                  <InfoLabel label="Documento" value={unit.document} />
                </TableCell>
                <TableCell>
                  <InfoLabel
                    stackProps={{ sx: { textAlign: "center" } }}
                    label="Ambientes"
                    value={unit.environments.length.toString()}
                  />
                </TableCell>
                <TableCell>
                  {unit.main && (
                    <Chip label="Matriz" variant="outlined" color="success" />
                  )}
                </TableCell>
                <TableCell sx={{ textAlign: "center", width: "0%" }}>
                  <Chip
                    label={getUnitStatus(unit.status).label.toLocaleUpperCase()}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Collapse in={!loadingUnits && units.length === 0}>
          <Typography
            variant="body2"
            sx={{ p: 2, display: "block", textAlign: "center" }}
          >
            Nenhuma unidade encontrado
          </Typography>
        </Collapse>
        <Collapse in={loadingUnits} sx={{ textAlign: "center" }}>
          <CircularProgress size={14} sx={{ my: 2 }} />
        </Collapse>
        <Divider />
        <Grid container sx={{ p: 2 }} justifyContent="space-between">
          <Grid item>
            {(paginationMeta?.totalPages ?? 0) > 1 && (
              <MuiPagination
                color="primary"
                page={paginationMeta?.currentPage}
                count={paginationMeta?.totalPages}
                onChange={(_, page) => {
                  searchParams.set("page", String(page));
                  navigate({
                    search: searchParams.toString(),
                  });
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Button component={Link} variant="contained" to="/units/new">
              Nova Unidade
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </PageContent>
  );
};

export default UnitListPage;
