import { Category } from "../models/category.model";

interface CategoryWithParent extends Category {
  parent: CategoryWithParent[];
}

export const mapParent = (categories: Category[]): CategoryWithParent[] => {
  const map = (
    root: Category[],
    parent?: Partial<Category>[]
  ): CategoryWithParent[] => {
    return root.map(({ children, ...cat }) => {
      return {
        ...cat,
        parent: parent as CategoryWithParent[],
        children: map(children, [...(parent ?? []), cat]),
      };
    });
  };

  return map(categories, []);
};

export const flatten = (categories: Category[]): Partial<Category>[] => {
  const array: Partial<Category>[] = [];
  const flat = (root: Category[]) => {
    root.forEach(({ children, ...category }) => {
      array.push(category);
      if (children && children.length > 0) {
        flat(children);
      }
    });
  };
  flat(categories);
  return array;
};
