import React, { useCallback, useRef, useState } from "react";
import { Box, Button, Collapse, Grid, Paper, Typography } from "@mui/material";

import { FormikValues } from "formik";
import { Navigate } from "react-router-dom";
import { useDialog } from "../hooks/dialog.hook";
import { useAuth } from "../hooks/auth.hook";

import logoDark from "../assets/images/hub-logo-dark.svg";

import FormWrapper, {
  SubmitButton,
  Validator,
} from "../components/fields/form-wrapper";
import InputText from "../components/fields/text-field";

const loginValidationSchema = Validator.object().shape({
  username: Validator.string().nullable().required().label("Usuário"),
  password: Validator.string().nullable().required().label("Usuário"),
});

const workspaceValidationSchema = Validator.lazy(() =>
  Validator.object().shape({
    workspace: Validator.string()
      .nullable()
      .trim()
      .matches(/^[/.-_ A-Za-z0-9]+$/, "Workspace Inválido")
      .required()
      .label("Nome da conta"),
  })
);

const LoginPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const auth = useAuth();
  const dialog = useDialog();

  const usernameInputRef = useRef<any>();

  const [workspaceName, setWorkspaceName] = useState<string>("");
  const [username, setUsername] = useState<string>("");

  const loginWorkspaceHandler = useCallback(
    async (values: FormikValues): Promise<void> => {
      const loginResult = await auth.loginWorkspace(values.workspace);
      if (!loginResult.valid) {
        switch (loginResult.code) {
          case "WORKSPACE_INVALID":
            dialog.open({ title: "Ops!", message: "Conta inválida" });
            break;
          default:
            dialog.open({
              title: "Ops!",
              message: "Ocorreu um erro",
            });
            break;
        }
      } else {
        setTimeout(() => {
          usernameInputRef.current.focus();
        }, 300);
      }
    },
    [dialog, auth]
  );

  const loginHandler = useCallback(
    async (values: FormikValues): Promise<void> => {
      const loginResult = await auth.login(values.username, values.password);
      if (!loginResult.authenticated) {
        switch (loginResult.code) {
          case "USER_BLOCKED":
            dialog.open({ title: "Ops!", message: "Usuário bloqueado" });
            break;

          default:
            dialog.open({
              title: "Ops!",
              message: "Usuário ou senha inválido",
            });
            break;
        }
      }
    },
    [dialog, auth]
  );

  if (auth.authenticated) {
    return <Navigate to={auth.getInitialRoute()} />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: (theme) => theme.palette.primary.main,
      }}
    >
      <Box sx={{ mb: 5, textAlign: "center" }}>
        <img src={logoDark} alt="Meditech" height="80" />
      </Box>
      <Paper
        elevation={10}
        sx={{
          p: { xs: 2, md: 5 },
          minWidth: { xs: "90%", md: 450 },
          borderTop: (theme) => `5px solid ${theme.palette.secondary.main}`,
        }}
      >
        <Collapse in={!auth.workspace}>
          <FormWrapper
            validationSchema={workspaceValidationSchema}
            initialValues={{ workspace: null }}
            onSubmit={(values) => loginWorkspaceHandler(values)}
          >
            {(form) => (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <InputText
                    autoFocus
                    label="Nome da conta"
                    name="workspace"
                    placeholder="ex: nome-da-empresa"
                    value={workspaceName}
                    onChange={(event) => {
                      const value = String(
                        event.currentTarget.value ?? ""
                      ).replace(/[^[a-z]+/, "");
                      setWorkspaceName(value);
                      form.setFieldValue("workspace", value);
                    }}
                  />
                </Grid>
                <Grid item style={{ textAlign: "right" }}>
                  <SubmitButton variant="contained" color="primary">
                    CONTINUAR
                  </SubmitButton>
                </Grid>
              </Grid>
            )}
          </FormWrapper>
        </Collapse>
        <Collapse in={!!auth.workspace}>
          <FormWrapper
            validationSchema={loginValidationSchema}
            initialValues={{ username: null, password: null }}
            onSubmit={loginHandler}
          >
            {(form) => (
              <Grid container direction="column" spacing={2}>
                {auth?.workspace && (
                  <Grid item>
                    <Collapse
                      in={!!auth.workspace?.company?.logoUrl}
                      unmountOnExit
                    >
                      <Box sx={{ mb: 2, textAlign: "center" }}>
                        <img
                          src={auth.workspace.company.logoUrl!}
                          alt="Meditech"
                          height="70"
                        />
                      </Box>
                    </Collapse>
                    <Collapse
                      in={!auth.workspace?.company?.logoUrl}
                      unmountOnExit
                    >
                      <Typography
                        color="gray.100"
                        sx={{ textAlign: "center", textTransform: "uppercase" }}
                      >
                        <strong>{auth.workspace?.company.fantasyName}</strong>
                      </Typography>
                    </Collapse>
                  </Grid>
                )}
                <Grid item>
                  <InputText
                    ref={usernameInputRef}
                    label="Usuário"
                    name="username"
                    autoFocus
                    value={username}
                    onChange={(event: any) => {
                      const value = String(
                        event.currentTarget.value ?? ""
                      ).replace(/[^[a-z0-9.]+/, "");
                      setUsername(value);
                      form.setFieldValue("username", value);
                    }}
                  />
                </Grid>
                <Grid item>
                  <InputText label="Senha" name="password" type="password" />
                </Grid>
                <Grid item style={{ textAlign: "right" }}>
                  <SubmitButton variant="contained" color="primary">
                    Acessar
                  </SubmitButton>
                </Grid>
              </Grid>
            )}
          </FormWrapper>
        </Collapse>
      </Paper>
      <Collapse in={!!auth.workspace}>
        <Button
          fullWidth
          sx={{ mt: 2 }}
          color="secondary"
          onClick={() => {
            auth.logout();
            setWorkspaceName("");
            setUsername("");
          }}
        >
          Acessar com outra conta
        </Button>
      </Collapse>
      {/* <Collapse in={!auth.workspace && !sso}>
        <Button
          fullWidth
          sx={{ mt: 2 }}
          color="secondary"
          onClick={() => {
            setSso(true);
            setWorkspaceName("");
            setUsername("");
          }}
        >
          Acessar com uma conta única
        </Button>
      </Collapse> */}
    </Box>
  );
};

export default LoginPage;
