export interface ProfessionalStatus {
  label: string;
  value: string;
}

export const status: ProfessionalStatus[] = [
  { value: "in_validation", label: "em validação" },
  { value: "connected", label: "conectado" },
  { value: "disconnected", label: "desconectado" },
  { value: "blocked", label: "bloqueado" },
];
