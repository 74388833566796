import React, { useContext, useMemo } from "react";
import { Professional } from "../models/professional.model";
import { useAuth } from "./auth.hook";

interface ProfessionalContextProps {
  professional: Professional | null;
}

const ProfessionalContext = React.createContext<
  ProfessionalContextProps | undefined
>(undefined);

export const useProfessional = () => {
  const context = useContext(ProfessionalContext);
  if (!context) throw new Error("Professional required provider");
  return context;
};

const ProfessionalProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const auth = useAuth();

  const contextProps = useMemo(
    () => ({
      professional: auth.user?.professional ?? null,
    }),
    [auth]
  );

  return (
    <ProfessionalContext.Provider value={contextProps}>
      {children}
    </ProfessionalContext.Provider>
  );
};

export default ProfessionalProvider;
