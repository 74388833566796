import React, { useCallback, useRef } from "react";
import { Category } from "../../models/category.model";
import http from "../../services/http";
import AutocompleteField, {
  AutoCompleteComponentRef,
  AutoCompleteFieldOption,
  AutoCompleteFieldProps,
} from "../fields/autocomplete-field";

type PartnerAgreementAutoCompleteFieldProps = AutoCompleteFieldProps;

const PartnerAgreementAutoCompleteField: React.FC<
  PartnerAgreementAutoCompleteFieldProps
> = ({ ...props }) => {
  const inputRef = useRef<AutoCompleteComponentRef>();
  const fetchPartnerAgreements = useCallback(
    async (query?: string): Promise<AutoCompleteFieldOption[]> => {
      const { data, status } = await http.get<Category[]>(
        "partner-agreements",
        {
          params: {
            ...(query ? { search: query } : {}),
          },
        }
      );

      // const newItem = {
      //   title: "Nova Categoria",
      //   value: () => {
      //     drawer.open({
      //       element: <CategoryFormDrawer />,
      //       onClose: (newCategory) => {
      //         if (newCategory) {
      //           console.log(newCategory);
      //         }
      //       },
      //     });
      //     return false;
      //   },
      //   ref: "new",
      // };

      if (status === 200) {
        return [
          ...data.map((partner: Category) => ({
            title: partner.description,
            value: partner.id,
            keywords: [partner.description],
            data: partner,
          })),
          // newItem,
        ];
      }
      return [];
    },
    []
  );

  return (
    <AutocompleteField
      label="Convênio"
      onSearchOptions={fetchPartnerAgreements}
      {...props}
      ref={inputRef}
    />
  );
};

export default PartnerAgreementAutoCompleteField;
