import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { PlayCircle } from "@mui/icons-material";
import ReactAudioPlayer from "react-audio-player";

import { AutoTextSize, updateTextSize } from "auto-text-size";
import { useTvQueue } from "./use-tv-queue";

export const DisplayTvPage = () => {
  const parentRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const [audioRef, setAudioRef] = useState<HTMLAudioElement | null>(null);

  const [ready, setReady] = useState(false);
  const [audioReady, setAudioReady] = useState(false);
  const { queue } = useTvQueue({ audioRef });

  useEffect(() => {
    if (queue && parentRef.current && innerRef.current) {
      updateTextSize({
        containerEl: parentRef.current,
        innerEl: innerRef.current,
      });
    }
  }, [queue]);

  if (!ready)
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100vw", height: "100vh", backdropFilter: "blur(50px)" }}
      >
        <IconButton
          onClick={() => {
            setReady(true);
          }}
          sx={{ color: "white" }}
        >
          <PlayCircle sx={{ fontSize: 300 }} />
        </IconButton>
      </Stack>
    );

  return (
    <>
      {!audioReady && (
        <Typography sx={{ fontSize: 50 }}>
          <CircularProgress color="secondary" size={100} />
        </Typography>
      )}
      {audioReady && (
        <Stack direction="row" sx={{ width: "100%", height: "100%" }}>
          <Stack sx={{ flex: 1 }}>
            <Box
              component="video"
              src="/video.mp4"
              autoPlay
              loop
              muted
              sx={{ width: 1, height: 1, objectFit: "cover" }}
            />
            {/* <Box
          component="iframe"
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${code}?controls=0&start=25&autoplay=1&mute=1&playsinline=1&playlist=${code}&loop=1`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          sx={{ border: "none", pointerEvents: "none" }}
        /> */}
          </Stack>
          <Stack sx={{ position: "relative", height: "100%", width: "40%" }}>
            <Paper
              component={Stack}
              square
              elevation={10}
              sx={{
                flex: 1,
                bgcolor: "primary.main",
                color: "primary.contrastText",
              }}
            >
              <Stack
                ref={parentRef}
                sx={{
                  flex: queue.filed.length ? 0.5 : 1,
                  p: 3,
                  flexBasis: "55%",
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Collapse
                  in={queue.current?.type === "sequential"}
                  unmountOnExit
                  sx={{ width: 1 }}
                >
                  <Typography
                    component="div"
                    sx={{ fontSize: "2rem", textAlign: "center", width: 1 }}
                  >
                    SENHA
                  </Typography>
                </Collapse>
                <Typography
                  component={AutoTextSize}
                  minFontSizePx={60}
                  maxFontSizePx={150}
                  mode={
                    queue.current?.type === "sequential"
                      ? "boxoneline"
                      : "multiline"
                  }
                  sx={{
                    width: 1,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {queue.current?.value ?? "-"}
                </Typography>
                <Typography
                  component={AutoTextSize}
                  minFontSizePx={30}
                  maxFontSizePx={100}
                  mode="multiline"
                  sx={{
                    width: 1,
                    // fontSize: "4em",
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                >
                  {queue.current?.requester ?? "-"}
                </Typography>
              </Stack>
              {queue.filed.length > 0 && (
                <Stack
                  elevation={10}
                  component={Paper}
                  square
                  sx={{ flexBasis: "45%" }}
                  alignItems="start"
                >
                  <Stack
                    sx={{ pt: 0, flex: 1, width: 1 }}
                    divider={<Divider />}
                  >
                    <Typography
                      sx={{ fontSize: "1.5rem", p: 2, color: "grey.600" }}
                    >
                      Últimas chamadas
                    </Typography>
                    {queue.filed.map((filed) => (
                      <Stack
                        key={filed.id}
                        sx={{ flex: 1, p: 3 }}
                        spacing={filed.type === "sequential" ? 3 : -1}
                        justifyContent={
                          filed.type === "sequential"
                            ? "space-between"
                            : "center"
                        }
                        alignItems={
                          filed.type === "sequential" ? "center" : "start"
                        }
                        direction={
                          filed.type === "sequential" ? "row" : "column"
                        }
                      >
                        {filed.type === "sequential" ? (
                          <Typography
                            sx={{
                              fontSize: 50,
                              fontWeight: "bold",
                            }}
                          >
                            {filed.value}
                          </Typography>
                        ) : (
                          <Typography
                            component={AutoTextSize}
                            minFontSizePx={30}
                            maxFontSizePx={30}
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            {filed.value}
                          </Typography>
                        )}
                        <Typography
                          sx={{
                            fontSize: filed.type === "sequential" ? 50 : 30,
                            flex: filed.type === "sequential" ? 1 : undefined,
                          }}
                        >
                          {filed.requester}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              )}
            </Paper>
          </Stack>
        </Stack>
      )}
      <ReactAudioPlayer
        src="/notification.mp3"
        onCanPlayThrough={() => {
          setAudioReady(true);
        }}
        ref={(element) => {
          setAudioRef(element?.audioEl?.current ?? null);
        }}
      />
    </>
  );
};
