import React, { useCallback } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";

import { FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/auth.hook";
import { useDialog } from "../hooks/dialog.hook";

import logoDark from "../assets/images/hub-logo-dark.svg";

import FormWrapper, {
  SubmitButton,
  Validator,
} from "../components/fields/form-wrapper";
import InputText from "../components/fields/text-field";

const loginValidationSchema = Validator.object().shape({
  password: Validator.string().nullable().required().label("Nova Senha"),
  confirmPassword: Validator.string()
    .nullable()
    .oneOf([Validator.ref("password"), null], "Senha não confere")
    .required()
    .label("Confirmar Senha"),
});

const LoginWelcomePage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const auth = useAuth();
  const dialog = useDialog();
  const navigate = useNavigate();

  const activateAccount = useCallback(
    async (values: FormikValues): Promise<void> => {
      const isActivated = await auth.activate(values.password);
      if (!isActivated) {
        dialog.open({ title: "Ops!", message: "Não foi possível ativar" });
      } else {
        dialog.open({
          title: "Ativação efetuada",
          message: "Insira seu nome de usuário e crie uma nova senha.",
        });
        auth.logout();
      }
    },
    [dialog, auth]
  );

  if (
    !auth.authenticated ||
    (auth.authenticated && auth.user?.status === "activated")
  ) {
    navigate(auth.getInitialRoute());
    return null;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: (theme) => theme.palette.primary.main,
      }}
    >
      <Box sx={{ mb: 5, textAlign: "center" }}>
        <img src={logoDark} alt="Meditech" height="80" />
      </Box>
      <Paper
        elevation={10}
        sx={{
          p: 5,
          minWidth: 350,
          borderTop: (theme) => `5px solid ${theme.palette.secondary.main}`,
        }}
      >
        <FormWrapper
          validationSchema={loginValidationSchema}
          initialValues={{ confirmPassword: null, password: null }}
          onSubmit={activateAccount}
        >
          {() => (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography
                  color="gray.100"
                  sx={{ textAlign: "center", textTransform: "uppercase" }}
                >
                  <strong>DIGITE UMA NOVA SENHA</strong>
                </Typography>
              </Grid>
              <Grid item>
                <InputText label="Senha" name="password" type="password" />
              </Grid>
              <Grid item>
                <InputText
                  label="Confirmar Senha"
                  name="confirmPassword"
                  type="password"
                />
              </Grid>
              <Grid item style={{ textAlign: "right" }}>
                <Grid container>
                  <Grid item md={6}>
                    <Button
                      sx={{ mr: "auto" }}
                      fullWidth
                      onClick={() => auth.logout()}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item md={6}>
                    <SubmitButton variant="contained" fullWidth color="primary">
                      Continuar
                    </SubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </FormWrapper>
      </Paper>
    </Box>
  );
};

export default LoginWelcomePage;
