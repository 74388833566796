import { Add, Article, Forum, Print, Receipt } from "@mui/icons-material";
import {
  Badge,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Pagination as MuiPagination,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { groupBy, uniq } from "lodash";
import SearchField from "../../../components/fields/search-field";
import InfoLabel from "../../../components/info-label";
import PageContent from "../../../components/page-content";
import {
  getChannelDelivery,
  getOrderExamStatus,
  status as orderStatus,
} from "../../../structs/order";
import { Order } from "../../../models/order.model";
import { Pagination } from "../../../models/pagination.model";
import { Service } from "../../../models/service.model";
import http from "../../../services/http";
import { DefaultHeader } from "../../../components/default-header";
import { useDrawer } from "../../../hooks/drawer.hook";
import OrderPrintViewer from "../../../components/contexts/order-print";
import OrderExamManager from "../../../components/contexts/order-exam-manager";
import { useAuth } from "../../../hooks/auth.hook";
import { getInvoiceStatus } from "../../../structs/invoice";
import OrderLogsViewer from "../../../components/contexts/order-logs-viewer";
import { useSocket } from "../../../hooks/socket.hook";
import { CallPatientQueueButton } from "../../../components/call-queue-button";

const OrderListPage: React.FC = () => {
  const auth = useAuth();
  const socket = useSocket();
  const drawer = useDrawer();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<Pagination<Service>["meta"]>();

  const orderGroups = useMemo(() => {
    return Object.entries(
      groupBy(orders, (order) => {
        if (order.invoice) return `invoice-${order.invoice.id}`;
        return `order-${order.id}`;
      })
    );
  }, [orders]);

  const selectOrder = useCallback((order: Order) => {
    setSelectedOrders((old) => {
      return uniq([...old, order.id]);
    });
  }, []);

  const unselectOrder = useCallback((order: Order) => {
    setSelectedOrders((old) => {
      return [...old].filter((o) => o !== order.id);
    });
  }, []);

  const fetchOrders = useCallback(
    async (searchQueryParams: URLSearchParams) => {
      setLoadingOrder(true);

      if (searchQueryParams.get("date") === "all")
        searchQueryParams.delete("date");

      const { data, status } = await http.get<Pagination<Order>>("orders", {
        params: {
          paginate: true,
          ...Object.fromEntries(searchQueryParams),
          ...(searchQueryParams.has("id")
            ? {
                id: searchQueryParams.getAll("id"),
              }
            : {}),
        },
      });
      setLoadingOrder(false);

      if (status === 200) {
        setOrders(data.items);
        setPaginationMeta(data.meta);
      }
    },
    []
  );

  const onTabChange = useCallback(
    (_: any, value: string) => {
      if (value === null) {
        searchParams.delete("status");
      } else {
        searchParams.set("status", value);
        searchParams.delete("id");
      }
      searchParams.delete("page");
      navigate({ search: searchParams.toString() }, { replace: true });
      setSelectedOrders([]);
    },
    [navigate, searchParams]
  );

  const openPrintDrawer = useCallback(
    (ids: string[]) => {
      const o = orders.filter((oo) => ids.includes(oo.id));
      drawer.open({
        element: <OrderPrintViewer orders={o} />,
      });
    },
    [drawer, orders]
  );

  useEffect(() => {
    fetchOrders(searchParams);
  }, [fetchOrders, searchParams, navigate]);

  useEffect(() => {
    const onOrderUpdated = async (ooo: Order) => {
      const { data: order, status } = await http.get(`orders/${ooo.id}`);
      if (status === 200) {
        setOrders((o) =>
          o.map((oo) => {
            if (oo.id === order.id) return order;
            return oo;
          })
        );
      }
    };
    socket.subscribe("order::updated", onOrderUpdated);
    return () => {
      socket.unsubscribe("order::updated", onOrderUpdated);
    };
  }, [socket]);

  const OrderControl: React.FC<{ orders: Order[]; isGroup: boolean }> =
    useCallback(
      ({ orders: oo, isGroup }) => {
        const selectedCount = oo.reduce((count, order) => {
          if (selectedOrders.includes(order.id)) return count + 1;
          return count;
        }, 0);

        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack justifyContent="center" alignItems="center">
              <Checkbox
                checked={selectedCount === oo.length}
                indeterminate={selectedCount > 0 && selectedCount < oo.length}
                onChange={(event, checked) => {
                  if (checked) {
                    oo.forEach((o) => {
                      selectOrder(o);
                    });
                  } else {
                    oo.forEach((o) => {
                      unselectOrder(o);
                    });
                  }
                }}
              />
              {oo.at(0) && <CallPatientQueueButton patient={oo[0].patient} />}
            </Stack>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <InfoLabel
                  label="Nº"
                  value={oo.at(0)?.patient.code}
                  valueProps={{ sx: { whiteSpace: "normal" } }}
                />
                <InfoLabel
                  label="Paciente"
                  value={`${oo.at(0)?.patient?.person?.fullName}`}
                  valueProps={{ sx: { whiteSpace: "normal" } }}
                />
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Tooltip title="Imprimir Guia">
                  <Badge
                    color="secondary"
                    badgeContent={
                      isGroup ? oo.at(0)?.invoice?.totalOrders : undefined
                    }
                  >
                    <IconButton
                      edge="start"
                      onClick={() => {
                        openPrintDrawer(oo.map((o) => o.id));
                      }}
                    >
                      <Print />
                    </IconButton>
                  </Badge>
                </Tooltip>
                {oo?.at(0)?.invoice && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Tooltip title="Gerenciar Fatura">
                      <IconButton
                        edge="start"
                        component={Link}
                        to={`/invoices/${oo?.[0].invoice?.id}`}
                      >
                        <Receipt />
                      </IconButton>
                    </Tooltip>
                    <InfoLabel
                      label="Fatura"
                      value={
                        <Chip
                          size="small"
                          sx={{
                            bgcolor: getInvoiceStatus(oo?.[0].invoice?.status)
                              ?.color,
                            color: "white",
                          }}
                          label={
                            getInvoiceStatus(oo?.[0].invoice?.status)?.label
                          }
                        />
                      }
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        );
      },
      [selectedOrders, selectOrder, unselectOrder, openPrintDrawer]
    );

  return (
    <PageContent overlayBar header={<DefaultHeader title="Atendimento" />}>
      <Paper sx={{ position: "relative" }}>
        <Paper
          elevation={0}
          sx={{
            position: "sticky",
            top: 0,
            backdropFilter: "blur(10px)",
            backgroundColor: "transparent",
            zIndex: 2,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Tabs
            value={searchParams.get("status")}
            onChange={onTabChange}
            sx={(theme) => ({
              borderTopLeftRadius: theme.shape.borderRadius,
              borderTopRightRadius: theme.shape.borderRadius,
            })}
          >
            {searchParams.has("id") && (
              <Tab
                label={
                  <Stack direction="row" spacing={1} alignItems="center">
                    <span>{`${
                      searchParams.getAll("id").length > 1
                        ? "Selecionados"
                        : "Selecionado"
                    }`}</span>
                    <Chip
                      size="small"
                      label={searchParams.getAll("id").length}
                    />
                  </Stack>
                }
                value={null}
              />
            )}
            {orderStatus.map((status) => (
              <Tab
                label={status.label}
                value={status.value}
                key={status.value}
              />
            ))}
          </Tabs>
          <SearchField
            sx={{ p: 2 }}
            queryParamName="search"
            fullWidth
            placeholder="Buscar por Nome, Documento ou Serviço"
          >
            <Stack
              spacing={2}
              direction="row"
              divider={<Divider flexItem orientation="vertical" />}
            >
              <FormControlLabel
                checked={
                  searchParams.get("date") === moment().format("YYYY-MM-DD")
                }
                onChange={(_, value) => {
                  if (value) {
                    searchParams.set("date", moment().format("YYYY-MM-DD"));
                  } else {
                    searchParams.set("date", "all");
                  }
                  navigate({
                    search: searchParams.toString(),
                  });
                }}
                control={<Checkbox />}
                label="Hoje"
              />
              <RadioGroup
                row
                sx={{ flexWrap: "nowrap" }}
                value={searchParams.get("type") ?? "all"}
                onChange={(_, value) => {
                  if (value && value !== "all") {
                    searchParams.set("type", value);
                  } else {
                    searchParams.delete("type");
                  }
                  navigate({
                    search: searchParams.toString(),
                  });
                }}
              >
                <FormControlLabel
                  value="exam"
                  control={<Radio />}
                  label="Exames"
                />
                <FormControlLabel
                  value="query"
                  control={<Radio />}
                  label="Consultas"
                />
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="Todos"
                />
              </RadioGroup>
            </Stack>
          </SearchField>
          <Divider />
        </Paper>
        <TableContainer>
          <Table>
            <TableBody>
              {orderGroups.map(([key, os], index: number) => (
                <React.Fragment key={key + index}>
                  {os.map((order, orderKey) => (
                    <TableRow
                      key={order.id}
                      sx={{
                        ".MuiTableCell-root": {
                          bgcolor: index % 2 === 0 ? "grey.100" : "transparent",
                        },
                      }}
                    >
                      {os.length > 1 && orderKey === 0 ? (
                        <TableCell
                          scope="col"
                          sx={{
                            width: "20%",
                            whiteSpace: "nowrap",
                            borderLeft: (theme) =>
                              `5px solid ${theme.palette.divider}`,
                            bgcolor:
                              index % 2 === 0 ? "grey.100" : "transparent",
                          }}
                          rowSpan={os.length}
                        >
                          <OrderControl orders={os} isGroup={os.length > 1} />
                        </TableCell>
                      ) : (
                        os.length === 1 && (
                          <TableCell
                            sx={{
                              width: "20%",
                              whiteSpace: "nowrap",

                              bgcolor:
                                index % 2 === 0 ? "grey.100" : "transparent",
                            }}
                          >
                            <OrderControl orders={os} isGroup={os.length > 1} />
                          </TableCell>
                        )
                      )}
                      <TableCell
                        sx={{
                          width: "1%",
                          borderLeft: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                        }}
                      >
                        <Tooltip title="Observações">
                          <Badge
                            badgeContent={
                              (order.logs ?? []).filter(
                                (l) => l.type === "attendance"
                              ).length
                            }
                            color="warning"
                          >
                            <IconButton
                              edge="end"
                              onClick={() => {
                                drawer.open({
                                  element: <OrderLogsViewer order={order} />,
                                  onClose(orderLog) {
                                    if (orderLog) {
                                      fetchOrders(searchParams);
                                    }
                                  },
                                  options: {
                                    PaperProps: {
                                      sx: {
                                        width: "50vw",
                                      },
                                    },
                                  },
                                });
                              }}
                            >
                              <Forum
                                sx={{
                                  color:
                                    (order.logs ?? []).filter(
                                      (l) => l.type === "attendance"
                                    ).length > 0
                                      ? "warning.main"
                                      : undefined,
                                }}
                              />
                            </IconButton>
                          </Badge>
                        </Tooltip>
                      </TableCell>
                      <TableCell colSpan={order.exam ? 0 : 3}>
                        <Stack spacing={1}>
                          <InfoLabel
                            label="Serviço"
                            value={`${order.service?.name}`}
                            valueProps={{
                              sx: { whiteSpace: "normal" },
                            }}
                          />
                          <InfoLabel
                            label="Origem"
                            value={
                              order.origin === "totem"
                                ? "Totem"
                                : order.origin === "crm" &&
                                  order.professional?.person?.fullName
                                ? `Criado por ${order.professional?.person?.fullName}`
                                : "CRM"
                            }
                          />
                        </Stack>
                      </TableCell>
                      {!!order.exam && (
                        <>
                          <TableCell sx={{ width: "1%" }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Tooltip title="Gerenciar Exames">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    drawer.open({
                                      element: (
                                        <OrderExamManager
                                          order={order}
                                          authProvider={auth}
                                        />
                                      ),
                                      options: {
                                        PaperProps: {
                                          sx: {
                                            width: "50vw",
                                          },
                                        },
                                      },
                                    });
                                  }}
                                >
                                  <Article />
                                </IconButton>
                              </Tooltip>
                              <InfoLabel
                                stackProps={{ flex: 1 }}
                                label="Exame"
                                value={
                                  getOrderExamStatus(order.exam.status).label
                                }
                              />
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ width: "1%" }}>
                            <InfoLabel
                              stackProps={{ flex: 1 }}
                              label="Entrega"
                              value={
                                getChannelDelivery(order.exam.channelDelivery)
                                  .label
                              }
                            />
                          </TableCell>
                        </>
                      )}
                      <TableCell sx={{ width: "1%" }}>
                        <InfoLabel
                          label="Criação"
                          value={
                            format(
                              parseISO(order.createdAt),
                              "dd/MM/yyyy 'às' HH'h'mm"
                            ) ?? "-"
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!loadingOrder && orders.length > 0 && <Divider />}
        <Collapse in={!loadingOrder && orders.length === 0}>
          <Typography
            variant="body2"
            sx={{
              p: 2,
              display: "block",
              textAlign: "center",
              color: "grey.500",
            }}
          >
            Nenhum atendimento encontrado
          </Typography>
        </Collapse>
        <Collapse in={loadingOrder} sx={{ textAlign: "center" }}>
          <CircularProgress size={14} sx={{ my: 2 }} />
        </Collapse>
        <Paper
          elevation={0}
          sx={{
            position: "sticky",
            bottom: 0,
            backdropFilter: "blur(10px)",
            backgroundColor: "transparent",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            zIndex: 1,
          }}
        >
          <Divider />
          <Grid container sx={{ p: 2 }} justifyContent="space-between">
            <Grid item>
              {(paginationMeta?.totalPages ?? 0) > 1 && (
                <MuiPagination
                  color="primary"
                  page={paginationMeta?.currentPage}
                  count={paginationMeta?.totalPages}
                  onChange={(_, page) => {
                    searchParams.set("page", String(page));
                    navigate({
                      search: searchParams.toString(),
                    });
                  }}
                />
              )}
            </Grid>
            <Grid item>
              <Stack spacing={2} direction="row">
                <Badge
                  badgeContent={selectedOrders.length}
                  variant="standard"
                  color="primary"
                >
                  <Button
                    startIcon={<Print />}
                    disabled={selectedOrders.length === 0}
                    variant="text"
                    onClick={() => openPrintDrawer(selectedOrders)}
                  >
                    Imprimir{" "}
                    {`${selectedOrders.length <= 1 ? "Guia" : "Guias"}`}
                  </Button>
                </Badge>
                <ButtonGroup>
                  <Button
                    startIcon={<Add />}
                    variant="contained"
                    onClick={() => navigate("/orders/new")}
                  >
                    Novo Atendimento
                  </Button>
                </ButtonGroup>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
    </PageContent>
  );
};

export default OrderListPage;
