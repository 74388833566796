import { flatten, uniq } from "lodash";
import { Professional } from "../models/professional.model";
import { ProfessionalStatus, status } from "../structs/professional";

export const getProfessionalStatus = (value: string): ProfessionalStatus => {
  return (
    status.find((s) => s.value === value) ?? {
      label: value,
      value,
    }
  );
};

export const generateKeywords = (professional: Professional): string[] => {
  return uniq([
    professional?.person.fullName,
    ...(flatten(professional.person.documents).map(
      (document) => document.document
    ) as any[]),
  ]);
};
