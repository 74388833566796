import React from "react";

const Logo: React.FC = () => {
  return (
    <svg viewBox="0 0 156.68 156.68">
      <circle fill="#53ffff" cx="129.8" cy="26.88" r="26.88" />
      <path
        fill="#fff"
        d="M151.17,113.49a26.89,26.89,0,0,0-33.83-7.5,1,1,0,0,1-1.17-.17L102,91.62a1,1,0,0,1-.18-1.17,26.41,26.41,0,0,0,0-24.22,1,1,0,0,1,.18-1.17l8.52-8.52a1,1,0,0,0-.15-1.53,34.17,34.17,0,0,1-8.67-8.68,1,1,0,0,0-1.54-.15l-8.52,8.53a1,1,0,0,1-1.17.17,26.41,26.41,0,0,0-24.22,0,1,1,0,0,1-1.17-.17l-14.2-14.2a1,1,0,0,1-.17-1.17A26.88,26.88,0,1,0,39.34,50.69a1,1,0,0,1,1.17.18l14.2,14.19a1,1,0,0,1,.17,1.17,26.43,26.43,0,0,0,0,24.23,1,1,0,0,1-.17,1.16l-14.2,14.2a1,1,0,0,1-1.17.17A26.87,26.87,0,1,0,50.7,117.34a1,1,0,0,1,.17-1.17L65.06,102a1,1,0,0,1,1.17-.18,26.43,26.43,0,0,0,24.23,0,1,1,0,0,1,1.16.18l14.2,14.19a1,1,0,0,1,.17,1.17,26.88,26.88,0,1,0,45.18-3.85ZM88.57,88.57a14.47,14.47,0,1,1,0-20.46A14.45,14.45,0,0,1,88.57,88.57Z"
      />
    </svg>
  );
};

export default Logo;
