import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { groupBy, orderBy } from "lodash";
import { useSocket } from "../../../hooks/socket.hook";
import { CallQueueItem } from "../../../models/call-queue.model";

interface IQueueItem {
  id: string;
  name: string;
  status: "awaiting" | "current" | "filed";
  value: string;
  createdAt: string;
  type: "sequential" | "order";
  requester: string | null;
}

interface IQueue {
  awaiting: IQueueItem[];
  current: IQueueItem | null;
  filed: IQueueItem[];
}

const MAX_TIMER = 5;

interface IUseTvQueue {
  audioRef: HTMLAudioElement | null;
}

export const useTvQueue = ({ audioRef }: IUseTvQueue) => {
  const intervalRef = useRef<any>();

  const { socket } = useSocket();

  const [queue, setQueue] = useState<IQueue>({
    awaiting: [],
    filed: [],
    current: null,
  });

  const emitTvRequestNext = useCallback(() => {
    if (!socket) return;
    socket.emit("tv::device::request::next");
    // console.log("emit: tv::device::request::next");
  }, [socket]);

  const removeTimer = useCallback(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = undefined;
  }, []);

  const createTimer = useCallback((finished: () => void) => {
    intervalRef.current = setInterval(() => {
      finished();
    }, MAX_TIMER * 1000);
  }, []);

  const handlerTvQueue = useCallback(
    (q: CallQueueItem[]) => {
      const groupQueue = groupBy(
        q.map(({ callQueue, requester, ...rest }) => ({
          ...rest,
          type: callQueue.type,
          requester: requester?.name || null,
        })),
        ({ status }) => status
      );

      const current = groupQueue?.current?.[0] ?? null;

      setQueue((old) => {
        if (old.current?.id !== current?.id) {
          // audioRef?.currentTime = 0;
          audioRef?.play();

          socket?.emit(
            "tv::device:queue::show",
            q.find((qq) => qq.status === "current")
          );
        }

        return {
          awaiting: orderBy(
            groupQueue?.awaiting ?? [],
            ({ createdAt }) => createdAt,
            ["asc"]
          ),
          filed: orderBy(
            groupQueue?.filed ?? [],
            ["createdAt"],
            ["desc"]
          ).slice(0, 3),
          current,
        };
      });
    },
    [audioRef, socket]
  );

  useEffect(() => {
    if (!intervalRef.current && socket) {
      socket.emit("tv::device::request::queue");
      socket.on("tv::device::queue", handlerTvQueue);
      createTimer(() => {
        emitTvRequestNext();
      });
    }

    return () => {
      removeTimer();
      socket?.off("tv::device::queue", handlerTvQueue);
    };
  }, [createTimer, emitTvRequestNext, handlerTvQueue, removeTimer, socket]);

  return useMemo(
    () => ({
      queue,
    }),
    [queue]
  );
};
