import React, { forwardRef, useCallback, useEffect, useMemo } from "react";
import {
  Form,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikProps,
  FormikValues,
  useFormikContext,
  yupToFormErrors,
} from "formik";

import * as Yup from "yup";
import { pt } from "yup-locale-pt";
import { Button, ButtonProps, CircularProgress } from "@mui/material";

import { validateOrFail } from "validation-br/dist/cpf";

Yup.setLocale(pt);

interface FormWrapperProps {
  validationSchema?: FormikConfig<FormikValues>["validationSchema"];
  initialValues: FormikProps<FormikValues>["initialValues"];
  onSubmit(
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ): Promise<void> | void;
  onChangeValues?(values: FormikValues): void | Promise<void>;
  children: FormikConfig<FormikValues>["children"];
  formProps?: React.FormHTMLAttributes<HTMLFormElement>;
}

declare module "yup" {
  interface StringSchema {
    cpf(format: string): StringSchema;
  }
}
interface WrapperProps {
  onChangeValues: FormWrapperProps["onChangeValues"];
}

export const Validator = Yup;

// Crie seu método personalizado chamado cpf()

Validator.addMethod<Yup.StringSchema>(
  Validator.string,
  "cpf",
  function cpf(message: string) {
    return this.test("cpf", message, (value, context) => {
      const { path, createError } = context;

      try {
        if (!value) throw new Error(message);
        validateOrFail(value);
        return true;
      } catch (error: any) {
        // Cria um erro se cair no catch
        return createError({
          path,
          // Exibe a mensagem do catch
          message: message ?? error.message,
        });
      }
    });
  }
);

export const SubmitButton: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  variant,
  ...props
}) => {
  const form = useFormikContext();

  return (
    <Button
      {...props}
      variant={form.isSubmitting ? "text" : variant}
      disabled={!form.dirty || !form.isValid || !!form.isSubmitting}
      type="submit"
    >
      {form.isSubmitting ? (
        <CircularProgress color="inherit" size={25} />
      ) : (
        children
      )}
    </Button>
  );
};

const Wrapper: React.FC<React.PropsWithChildren<WrapperProps>> = ({
  children,
  onChangeValues,
  ...props
}) => {
  const form = useFormikContext<any>();

  const values = useMemo(() => {
    return form.values;
  }, [form.values]);

  useEffect(() => {
    if (onChangeValues) {
      onChangeValues(values);
    }
  }, [values, onChangeValues]);

  return <Form {...props}>{children}</Form>;
};

const FormWrapper: React.ForwardRefRenderFunction<
  FormikProps<FormikValues> | null,
  FormWrapperProps
> = (
  {
    children,
    onSubmit,
    validationSchema,
    initialValues,
    onChangeValues,
    formProps,
    ...props
  },
  ref
) => {
  const onSubmitHandler = useCallback(
    async (
      values: FormikValues,
      formikHelpers: FormikHelpers<FormikValues>
    ) => {
      const formValues = validationSchema
        ? validationSchema.cast(values, { stripUnknown: true })
        : values;

      formikHelpers.setSubmitting(true);
      await onSubmit(formValues, formikHelpers);
      formikHelpers.setSubmitting(false);
    },
    [onSubmit, validationSchema]
  );

  return (
    <Formik
      enableReinitialize
      validate={async (values: any) => {
        let errors = {};
        try {
          await validationSchema.validate(values, {
            abortEarly: false,
            context: values,
          });
        } catch (err) {
          errors = yupToFormErrors(err);
        }
        return errors;
      }}
      {...props}
      onSubmit={onSubmitHandler}
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={ref}
    >
      {(form: FormikProps<FormikValues>) => (
        <Wrapper onChangeValues={onChangeValues} {...formProps}>
          {typeof children === "function" ? children(form) : children}
        </Wrapper>
      )}
    </Formik>
  );
};

export default forwardRef(FormWrapper);
