import {
  Box,
  Button,
  Collapse,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Add, Close, TvOffOutlined } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  AutocompleteElement,
  FormContainer,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import InfoLabel from "../../info-label";
import { useSocket } from "../../../hooks/socket.hook";
import { http } from "../../../services/http";
import {
  CallQueue,
  CallQueueRequester,
} from "../../../models/call-queue.model";
import { useItemModal } from "../../../hooks/modal.hook";
import { useCallPasswordModal } from "./use-call-password-modal";

interface ICallPasswordModal {
  socket: ReturnType<typeof useSocket>;
  navigate: ReturnType<typeof useNavigate>;
}

const MAX_TIMER = 5;
const STORAGE_SETTINGS = "CALL_PASSWORD_USER_SETTINGS";

export const CallPasswordModal = ({ socket, navigate }: ICallPasswordModal) => {
  const modalItem = useItemModal();
  const { connected } = socket;

  const [callQueues, setCallQueues] = useState<CallQueue[]>([]);
  const [callQueueRequesters, setCallQueueRequesters] = useState<
    CallQueueRequester[]
  >([]);

  const form = useForm<any>({
    defaultValues: localStorage.getItem(STORAGE_SETTINGS)
      ? JSON.parse(localStorage.getItem(STORAGE_SETTINGS) || "{}")
      : null,
  });

  const intervalAwaitingRef = useRef<any>();
  const [awaiting, setAwaiting] = useState(0);

  const values = form.watch();

  const currentCallQueue = useMemo(() => {
    return (
      callQueues.find((callQueue) => callQueue.id === values?.callQueue?.id) ??
      null
    );
  }, [callQueues, values?.callQueue?.id]);

  const { add, queue, device } = useCallPasswordModal({
    callQueue: currentCallQueue,
    socket,
  });

  const fetchDevices = useCallback(async () => {
    const [
      { status: callQueuesStatus, data: callQueuesData },
      { status: callQueuesRequestersStatus, data: callQueuesRequestersData },
    ] = await Promise.all([
      http.spine.get<CallQueue[]>("call-queues"),
      http.spine.get<CallQueueRequester[]>("call-queues/requesters"),
    ]);

    if (callQueuesStatus === 200) {
      setCallQueues(
        callQueuesData.filter((call) => call.type === "sequential")
      );
    }
    if (callQueuesRequestersStatus === 200) {
      setCallQueueRequesters(callQueuesRequestersData);
    }
  }, []);

  const setAwaitingQueue = useCallback(() => {
    setAwaiting(1);

    intervalAwaitingRef.current = setInterval(() => {
      setAwaiting((curr) => {
        if (curr + 1 === MAX_TIMER) {
          clearInterval(intervalAwaitingRef.current);
          return 0;
        }
        return curr + 1;
      });
    }, 1000);
  }, []);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  useEffect(() => {
    if (values) {
      localStorage.setItem(STORAGE_SETTINGS, JSON.stringify(values));
    }
  }, [values]);

  if (!connected) {
    return (
      <Stack spacing={2} alignItems="center">
        <TvOffOutlined fontSize="large" />
        <Typography sx={{ textAlign: "center" }}>TV não disponível</Typography>
        <Button variant="contained">OK</Button>
      </Stack>
    );
  }

  const lastFiled = queue.filed.at(-1);

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <DialogTitle
          sx={{
            textTransform: "uppercase",
            p: 0,
            // mb: 2,

            position: "sticky",
            top: 0,
            backdropFilter: "blur(10px)",
            zIndex: 2,
          }}
        >
          Chamada por senha
        </DialogTitle>
        <Stack direction="row" spacing={1}>
          <Button
            startIcon={<Add />}
            variant="contained"
            onClick={() => {
              navigate("/orders/new");
              modalItem.close();
            }}
          >
            Novo Atendimento
          </Button>
          <Button
            onClick={() => {
              navigate("/orders");
              modalItem.close();
            }}
          >
            Atendimentos
          </Button>
          <IconButton
            onClick={() => {
              modalItem.close();
            }}
          >
            <Close />
          </IconButton>
        </Stack>
      </Stack>
      <FormContainer
        formContext={form}
        onSuccess={({ code }) => {
          add({
            value: code,
            requesterId: values.requester?.id,
          });

          setAwaitingQueue();

          form.setValue("code", null);
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <AutocompleteElement
              label="Fila"
              name="callQueue.id"
              matchId
              options={callQueues.map((callQueue) => ({
                id: callQueue.id,
                label: callQueue.name,
              }))}
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteElement
              label="Destino"
              name="requester.id"
              matchId
              options={callQueueRequesters.map((callQueue) => ({
                id: callQueue.id,
                label: callQueue.name,
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <Collapse in={!!currentCallQueue} unmountOnExit>
              <Paper
                variant="outlined"
                component={Stack}
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{
                  mb: 2,
                  p: 2,
                  borderColor: device?.connected
                    ? "success.main"
                    : "error.light",
                }}
              >
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: device?.connected
                      ? "success.light"
                      : "error.light",
                  }}
                />
                <Typography
                  color={device?.connected ? "success.main" : "error.light"}
                >
                  <Box component="strong" sx={{ textTransform: "uppercase" }}>
                    {device?.description}
                  </Box>{" "}
                  {device?.connected ? "conectada" : "desconectada"}
                </Typography>
              </Paper>
              <Collapse
                in={
                  queue.awaiting.length === 0 &&
                  !queue.schedule &&
                  !queue.current
                }
              >
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!device?.connected}
                  onClick={() => {
                    add({
                      requesterId: values?.requester?.id,
                    });
                  }}
                >
                  Iniciar
                </Button>
              </Collapse>
              <Collapse
                in={
                  !!queue.awaiting.length || !!queue.schedule || !!queue.current
                }
                unmountOnExit
              >
                <Stack direction="row" spacing={2}>
                  <Paper
                    variant="outlined"
                    sx={{ flex: 1, p: 2 }}
                    component={Stack}
                  >
                    <InfoLabel
                      stackProps={{
                        flex: 1,
                        textAlign: "center",
                      }}
                      valueProps={{
                        alignItems: "center",
                        whiteSpace: "pre-wrap",
                        fontSize:
                          !lastFiled?.callQueue?.type ||
                          lastFiled?.callQueue?.type === "sequential"
                            ? 30
                            : 16,
                      }}
                      label="Anterior"
                      value={lastFiled?.value ?? "-"}
                    />
                    <Button
                      variant="contained"
                      disabled={
                        !lastFiled ||
                        awaiting > 0 ||
                        !values.requester?.id ||
                        !device?.connected
                      }
                      onClick={() => {
                        const last = lastFiled?.value;
                        if (last) {
                          add({
                            requesterId: values.requester?.id,
                            value: +last,
                          });
                          setAwaitingQueue();
                        }
                      }}
                    >
                      Chamar novamente
                    </Button>
                  </Paper>
                  {queue?.current?.value && (
                    <Paper
                      elevation={5}
                      sx={{
                        flex: 1,
                        p: 2,
                        bgcolor: "primary.main",
                        color: "primary.contrastText",
                      }}
                      component={Stack}
                    >
                      <InfoLabel
                        stackProps={{ flex: 1, textAlign: "center" }}
                        valueProps={{
                          fontSize:
                            queue?.current?.callQueue?.type !== "sequential"
                              ? 16
                              : 40,
                        }}
                        labelProps={{ color: "primary.contrastText" }}
                        label="Atual"
                        value={queue?.current?.value ?? "-"}
                      />
                      {queue.current?.callQueue?.id ===
                        values.callQueue?.id && (
                        <Button
                          variant="outlined"
                          color="inherit"
                          disabled={
                            !queue.current ||
                            awaiting > 0 ||
                            !values.requester?.id ||
                            !device?.connected
                          }
                          onClick={() => {
                            if (queue.current?.value) {
                              add({
                                requesterId: values.requester?.id,
                                value: +queue.current.value,
                              });
                              setAwaitingQueue();
                            }
                          }}
                        >
                          Chamar novamente
                        </Button>
                      )}
                    </Paper>
                  )}
                  <Paper
                    variant="outlined"
                    sx={{ flex: 1, p: 2 }}
                    component={Stack}
                  >
                    <InfoLabel
                      stackProps={{ flex: 1, textAlign: "center" }}
                      valueProps={{ fontSize: 40 }}
                      label="Próximo"
                      value={
                        queue?.awaiting.at(0)?.value ??
                        queue?.schedule?.value ??
                        "-"
                      }
                    />
                    <Button
                      variant="contained"
                      disabled={
                        awaiting > 0 ||
                        !values.requester?.id ||
                        !device?.connected
                      }
                      onClick={() => {
                        add({
                          requesterId: values.requester?.id,
                        });
                        setAwaitingQueue();
                      }}
                    >
                      Chamar
                    </Button>
                  </Paper>
                </Stack>

                <Typography
                  sx={{ textAlign: "center", my: 2, color: "grey.500" }}
                >
                  ou digite manualmente
                </Typography>
                <Paper
                  component={Stack}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  variant="outlined"
                  sx={{ p: 2 }}
                >
                  <TextFieldElement
                    type="number"
                    inputProps={{
                      min: 1,
                      max: 99,
                    }}
                    label="Senha"
                    name="code"
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={
                      awaiting > 0 ||
                      !values.requester?.id ||
                      !device?.connected ||
                      !values.code
                    }
                  >
                    Chamar
                  </Button>
                </Paper>
              </Collapse>
            </Collapse>
          </Grid>
        </Grid>
      </FormContainer>
    </Stack>
  );
};
