import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Chip,
  Collapse,
  Divider,
  Grid,
  Paper,
  Stack,
} from "@mui/material";

import { DefaultHeader } from "../default-header";

import http from "../../services/http";

import { Order } from "../../models/order.model";

import { ModalLoading } from "../../hooks/modal.hook";
import FormWrapper, { SubmitButton, Validator } from "../fields/form-wrapper";
import TextField from "../fields/text-field";
import InfoLabel from "../info-label";
import { Professional } from "../../models/professional.model";
import { useItemDrawer } from "../../hooks/drawer.hook";
import { useDialog } from "../../hooks/dialog.hook";

interface OrderExamReportProps {
  order: Order;
  reportProfessional: Professional | null;
}

const orderExamReportValidationSchema = Validator.object().shape({
  report: Validator.string().required().label("Relatório"),
});

const OrderExamReport: React.FC<OrderExamReportProps> = ({
  order,
  reportProfessional,
}) => {
  const dialog = useDialog();
  const drawerItem = useItemDrawer();

  const [loadingExam, setLoadingExam] = useState(false);
  const [exam, setExam] = useState<Order["exam"]>();

  const fetchOrderExam = useCallback(async () => {
    setLoadingExam(true);
    const { status, data } = await http.get<Order["exam"]>(
      `exams/${order.exam.id}`
    );
    setLoadingExam(false);
    if (status === 200) {
      setExam(data);
    }
  }, [order.exam.id]);

  const checkReportExam = useCallback(
    async (values: any) => {
      dialog.open({
        title: "CONFIRMAÇÃO",
        message: "Deseja validar relatório?",
        buttons: [
          {
            label: "Não",
          },
          {
            label: "Sim",
            options: {
              variant: "contained",
            },
            async callback() {
              const { status, data } = await http.put(
                `exams/${order.exam.id}`,
                {
                  status: "verified_report",
                  report: values.report,
                  reportProfessional: {
                    id: reportProfessional?.id,
                  },
                }
              );

              if (status === 200) {
                setExam((old) => {
                  return {
                    ...old,
                    ...data,
                  };
                });
                drawerItem.close(exam);
              }
            },
          },
        ],
      });
    },
    [order.exam.id, reportProfessional, drawerItem, exam, dialog]
  );

  useEffect(() => {
    fetchOrderExam();
  }, [fetchOrderExam]);

  return (
    <FormWrapper
      initialValues={{}}
      validationSchema={orderExamReportValidationSchema}
      onSubmit={checkReportExam}
    >
      <Box sx={{ height: "100%" }}>
        <Collapse
          in={!loadingExam && !!exam}
          sx={{ height: "100%" }}
          unmountOnExit
        >
          <Paper sx={{ p: 2 }} square>
            <DefaultHeader
              gutterBottom={false}
              title={
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar alt={order.patient?.person?.fullName} />{" "}
                    <span>{`${
                      order.patient?.person?.fullName ?? "Paciente"
                    } `}</span>
                    <Chip
                      sx={{ verticalAlign: "text-bottom" }}
                      label={`#${order?.patient.code ?? "-"}`}
                    />
                  </Stack>
                  <SubmitButton variant="contained">
                    Validar Relatório
                  </SubmitButton>
                </Stack>
              }
            />
          </Paper>
          <Stack p={3} direction="column" spacing={2}>
            <Box>
              <Grid container>
                <Grid item md={12}>
                  <InfoLabel label="Serviço" value={exam?.order.service.name} />
                </Grid>
                <Grid item md={6}>
                  <InfoLabel
                    label="Responsável Técnico"
                    value={exam?.professionalTechnician?.person?.fullName}
                  />
                </Grid>
                <Grid item md={6}>
                  <InfoLabel
                    label="Responsável pelo Laudo"
                    value={exam?.reportProfessional?.person?.fullName}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <TextField name="report" label="Relatório" multiline rows={10} />
          </Stack>
        </Collapse>
        <Collapse in={loadingExam} unmountOnExit>
          <ModalLoading />
        </Collapse>
      </Box>
    </FormWrapper>
  );
};

export default OrderExamReport;
