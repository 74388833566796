import { useCallback, useEffect, useRef, useState } from "react";
import { groupBy, orderBy } from "lodash";
import { CallQueue, CallQueueItem } from "../../../models/call-queue.model";
import { useSocket } from "../../../hooks/socket.hook";
import { Device } from "../../../models/device.model";

interface IQueue {
  awaiting: CallQueueItem[];
  schedule: CallQueueItem | null;
  current: CallQueueItem | null;
  filed: CallQueueItem[];
}

export interface ISequentialCall {
  callQueueId: string;
  requesterId: string;
  value?: number;
}

interface IUseCallPasswordModal {
  callQueue: CallQueue | null;
  socket: ReturnType<typeof useSocket>;
}

interface IUseCallPasswordModalResponse {
  queue: IQueue;
  add: (item: Omit<ISequentialCall, "callQueueId">) => void;
  device:
    | (Pick<Device, "description" | "id"> & {
        connected: boolean;
      })
    | null;
}

export const useCallPasswordModal = ({
  callQueue,
  socket: { connected, socket },
}: IUseCallPasswordModal): IUseCallPasswordModalResponse => {
  const ready = useRef<boolean>(false);

  const [connectedDevice, setConnectedDevice] = useState(
    () => callQueue?.device.status === "connected"
  );

  const [queue, setQueue] = useState<IQueue>({
    awaiting: [],
    filed: [],
    schedule: null,
    current: null,
  });

  const sequentialAdd = useCallback<IUseCallPasswordModalResponse["add"]>(
    ({ requesterId, ...item }) => {
      if (socket && callQueue) {
        socket.emit("tv::queue::add", {
          callQueueId: callQueue.id,
          callQueueRequestId: requesterId,
          item,
        });
      }
    },
    [callQueue, socket]
  );

  const handlerTvQueue = useCallback(
    (q: CallQueueItem[]) => {
      const groupQueue = groupBy(
        q.map((item) => ({
          ...item,
        })),
        ({ status }) => status
      );

      const current = groupQueue?.current?.[0] ?? null;

      const awaiting = orderBy(
        (groupQueue?.awaiting ?? []).filter(
          (f) => f.callQueue.id === callQueue?.id
        ),
        ({ createdAt }) => createdAt,
        ["asc"]
      );

      const filed = orderBy(
        (groupQueue?.filed ?? []).filter(
          (f) => f.callQueue.id === callQueue?.id
        ),
        ["createdAt"],
        ["asc"]
      );

      const schedule =
        orderBy(
          (groupQueue?.scheduled ?? []).filter(
            (f) => f.callQueue.id === callQueue?.id
          ),
          ["createdAt"],
          ["asc"]
        ).at(0) ?? null;

      setQueue((old) => {
        if (old?.current?.id !== current?.id || !current) {
          return {
            awaiting,
            filed,
            schedule,
            current,
          };
        }
        return old;
      });
    },
    [callQueue?.id]
  );

  useEffect(() => {
    const deviceStatusHandler = (device: Device) => {
      if (device.id === callQueue?.device.id) {
        setConnectedDevice(device.status === "connected");
      }
    };

    if (socket && connected && !ready.current && callQueue) {
      ready.current = true;

      setConnectedDevice(callQueue?.device.status === "connected");

      socket.emit("tv::request::queue", {
        callQueueId: callQueue.id,
      });

      socket.on(`tv::queue`, handlerTvQueue);
      socket?.on("device::status", deviceStatusHandler);
    }

    return () => {
      socket?.off("tv::queue", handlerTvQueue);
      socket?.off("device::status", deviceStatusHandler);
    };
  }, [callQueue, connected, handlerTvQueue, socket]);

  return {
    add: sequentialAdd,
    queue,
    device: callQueue?.device
      ? {
          id: callQueue?.device.id,
          description: callQueue.device.description,
          connected: connectedDevice,
        }
      : null,
  };
};
