import React, { useCallback } from "react";
import { UnitEnvironment } from "../../models/unit-environment.model";
import http from "../../services/http";
import AutocompleteField, {
  AutoCompleteComponentRef,
  AutoCompleteFieldOption,
  AutoCompleteFieldProps,
} from "../fields/autocomplete-field";

type UnitEnvironmentAutoCompleteFieldProps = AutoCompleteFieldProps & {
  unit: string;
};

const UnitEnvironmentAutoCompleteField: React.ForwardRefRenderFunction<
  AutoCompleteComponentRef | undefined,
  UnitEnvironmentAutoCompleteFieldProps
> = ({ unit, ...props }, ref) => {
  const fetchCategories = useCallback(
    async (query?: string): Promise<AutoCompleteFieldOption[]> => {
      const { data, status } = await http.get<UnitEnvironment[]>(
        "unit-environments",
        {
          params: {
            ...(query ? { search: query } : {}),
            unit,
          },
        }
      );

      // const newItem = {
      //   title: "Novo Ambiente",
      //   value: () => {
      //     setOpenNewEnvironmentDialog(true);
      //     return false;
      //   },
      //   ref: "new",
      // };

      if (status === 200) {
        return data.map((environment: UnitEnvironment) => ({
          title: environment.description,
          value: environment.id,
          keywords: [environment.description],
          data: environment,
        }));
        // newItem,
      }
      return [];
    },
    [unit]
  );

  return (
    <AutocompleteField
      label="Ambiente"
      onSearchOptions={fetchCategories}
      {...props}
      ref={ref}
    />
  );
};

export default React.forwardRef(UnitEnvironmentAutoCompleteField);
