import { DeviceStatus, status } from "../structs/device";

export const getDeviceStatus = (value: string): DeviceStatus => {
  return (
    status.find((s) => s.value === value) ?? {
      label: value,
      value,
      color: "default",
    }
  );
};
