import React from "react";
import { Box, Fab, Grid, Paper, Tooltip } from "@mui/material";
import { Outlet, Navigate, useLocation, useNavigate } from "react-router-dom";

import { SmartDisplayOutlined } from "@mui/icons-material";
import { useAuth } from "../hooks/auth.hook";
import Menu from "../components/menu";
import Logo from "../components/logo";
import Header from "../components/header";
import { useSocket } from "../hooks/socket.hook";
import { CallPasswordModal } from "../components/modals/call-password-modal";
import { useModal } from "../hooks/modal.hook";

const AdminLayout: React.FC<React.PropsWithChildren<unknown>> = () => {
  const auth = useAuth();
  const socket = useSocket();
  const modal = useModal();
  const location = useLocation();
  const navigate = useNavigate();

  if (!auth.authenticated && location.pathname !== auth.config.loginUrl) {
    return (
      <Navigate
        to={{
          pathname: auth.sso ? auth.config.ssoLoginUrl : auth.config.loginUrl,
        }}
      />
    );
  }

  if (
    auth.authenticated &&
    auth.user?.status === "pending" &&
    location.pathname !== "/login/welcome"
  ) {
    return <Navigate to={{ pathname: "/login/welcome" }} />;
  }

  return (
    <Grid
      container
      spacing={0}
      sx={{
        bgcolor: "primary.main",
        width: 1,
        minHeight: { md: "100vh" },
        flexWrap: { md: "nowrap" },
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: { xs: "row", md: "column" },
          zIndex: 0,
        }}
        md={1.3}
        xs={12}
      >
        <Paper
          sx={{
            p: { xs: 2, md: 4 },
            maxHeight: { md: "100vh" },
            position: "sticky",
            top: 0,
            overflowY: "auto",
            overflowX: "hidden",
            width: { xs: "100%", md: "auto" },
            display: "flex",
            flexDirection: { xs: "row", md: "column" },
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "transparent",
            color: (theme) => theme.palette.primary.contrastText,
            borderRight: {
              md: (theme) => `5px solid ${theme.palette.secondary.main}`,
            },
          }}
          square
          variant="elevation"
          elevation={0}
          component={(props) => <Box zIndex={10} {...props} />}
        >
          <Box
            sx={{
              width: { xs: 40, md: 50 },
              marginBottom: { xs: -1.1 },
            }}
          >
            <Logo />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              mt: { md: 3 },
              justifyContent: "center",
              display: { xs: "none", md: "flex" },
            }}
          >
            <Menu />
          </Box>
        </Paper>
      </Grid>
      <Grid
        item
        md={10.7}
        xs={12}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Header />
        <Paper
          elevation={10}
          sx={{
            zIndex: 1,
            bgcolor: "grey.200",
            flex: 1,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Outlet />
        </Paper>

        <Tooltip title="Chamada por senha">
          <Fab
            onClick={() => {
              modal.open({
                content: (
                  <CallPasswordModal socket={socket} navigate={navigate} />
                ),
                options: {
                  fullWidth: true,
                  maxWidth: "md",
                },
              });
            }}
            variant="circular"
            color="primary"
            sx={(theme) => ({
              position: "fixed",
              bottom: theme.spacing(5),
              right: theme.spacing(5),
            })}
          >
            <SmartDisplayOutlined />
          </Fab>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default React.memo(AdminLayout);
