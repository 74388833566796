import { Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

interface LoadingTableProps {
  loading: boolean;
  columns?: number;
  rows?: number;
}

const LoadingTable: React.FC<LoadingTableProps> = ({
  loading,
  columns = 1,
  rows = 10,
}) => {
  if (!loading) return null;

  return (
    <>
      {new Array(rows).fill(null).map((_, key) => (
        <TableRow key={key}>
          {new Array(columns).fill(null).map((__, ckey) => (
            <TableCell
              key={ckey}
              colSpan={ckey + 1 >= columns ? 100 : undefined}
            >
              <Skeleton animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default LoadingTable;
