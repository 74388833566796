/* eslint-disable react/jsx-no-bind */
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { Device } from "../models/device.model";
import { http } from "../services/http";
import SocketProvider from "./socket.hook";

interface ITvContext {
  authenticated: boolean;
  device: Device | null;
  set: (device: Device) => Promise<boolean>;
}

const STORAGE_KEY = "tv_device";
const COOKIE_KEY = "tv_auth";

const TvContext = createContext<ITvContext>(undefined as unknown as ITvContext);

export const useTv = () => {
  const context = useContext(TvContext);
  if (!context) throw new Error("useTv must be used within a AuthTvProvider");
  return context;
};

const getStorageDevice = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) || "");
  } catch {
    return null;
  }
};

export const AuthTvProvider = ({ children }: PropsWithChildren) => {
  const [cookies, setCookies, removeCookies] = useCookies([COOKIE_KEY]);

  const token = useRef<string | null>(cookies?.tv_auth ?? null);

  const [authenticated, setAuthenticated] = useState(() => {
    return !!cookies.tv_auth;
  });
  const [tvDevice, setTvDevice] = useState<Device | null>(() => {
    return getStorageDevice(STORAGE_KEY);
  });

  const saveDevice = (device: Device | null) => {
    setTvDevice(device);
    if (device) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(device));
    } else {
      localStorage.removeItem(STORAGE_KEY);
    }
  };

  const setTv = useCallback(
    async (device: Device) => {
      const { status, data } = await http.ribs.post("devices/link", {
        code: device.code,
        type: "tv",
      });

      if (status === 201) {
        saveDevice(data);
        setCookies(COOKIE_KEY, data?.session?.token, {
          expires: new Date(data.session.exp * 1000),
          path: "/",
        });

        setAuthenticated(true);

        token.current = data?.session?.token;

        window.location?.reload();
      } else {
        saveDevice(null);
        token.current = null;
        removeCookies(COOKIE_KEY, {
          path: "/",
        });
      }

      return new Promise<boolean>((resolve) => {
        setTimeout(() => {
          resolve(status === 201);
        }, 400);
      });
    },
    [removeCookies, setCookies]
  );

  const getToken = useCallback(() => {
    return token.current;
  }, [token]);

  const contextProps = useMemo(
    () => ({
      authenticated,
      device: tvDevice,
      set: setTv,
    }),
    [authenticated, tvDevice, setTv]
  );

  return (
    <TvContext.Provider value={contextProps}>
      <SocketProvider namespace="tv" auth={getToken}>
        {children}
      </SocketProvider>
    </TvContext.Provider>
  );
};
