import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import React, { ComponentType } from "react";
import { Link } from "react-router-dom";

interface PageDefaultHeaderProps {
  title: React.ReactNode;
  icon?: ComponentType<any>;
  gutterBottom?: TypographyProps["gutterBottom"];
  typographyProps?: TypographyProps;
  buttonToBack?: boolean;
}

export const DefaultHeader: React.FC<
  React.PropsWithChildren<PageDefaultHeaderProps>
> = ({
  title,
  buttonToBack,
  gutterBottom = true,
  icon: Icon,
  typographyProps,
  children,
}) => {
  return (
    <Typography
      spacing={2}
      direction="row"
      component={Stack}
      variant="h5"
      {...typographyProps}
      sx={{
        mb: gutterBottom ? 2 : 0,
        textTransform: "uppercase",
        color: "grey.800",
        fontWeight: "bold",
        alignItems: "center",
        ...typographyProps?.sx,
      }}
    >
      {!!buttonToBack && (
        <IconButton component={Link} to="..">
          <ArrowBack />
        </IconButton>
      )}
      {!!Icon && <Icon fontSize="large" sx={{ mr: 1 }} />}{" "}
      <div style={{ flexGrow: 1 }}>{title}</div>
      {!!children && <Box justifySelf="flex-end">{children}</Box>}
    </Typography>
  );
};
