import React, { useCallback } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FormWrapper, { SubmitButton } from "../fields/form-wrapper";
import ResponsibleForm, {
  ResponsibleInitialValue,
  ResponsibleSchemaValidation,
} from "../forms/responsible-form";
import http from "../../services/http";
import { useItemDrawer } from "../../hooks/drawer.hook";

const ResponsibleFormDrawer: React.FC = () => {
  const drawerItem = useItemDrawer();
  const saveResponsible = useCallback(
    async (values: any) => {
      const { data, status } = await http.post("people", { ...values });

      if (status === 201) {
        drawerItem.close(data);
      }
    },
    [drawerItem]
  );
  return (
    <FormWrapper
      initialValues={ResponsibleInitialValue}
      validationSchema={ResponsibleSchemaValidation}
      onSubmit={saveResponsible}
    >
      <DialogTitle>Novo Responsável</DialogTitle>
      <DialogContent dividers sx={{ p: 2 }}>
        <ResponsibleForm />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={drawerItem.close}>Cancelar</Button>
        <SubmitButton variant="contained">Salvar</SubmitButton>
      </DialogActions>
    </FormWrapper>
  );
};

export default ResponsibleFormDrawer;
