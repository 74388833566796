import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { Field, FieldProps } from "formik";
import React, { forwardRef } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";

type NumberFieldProps = MuiTextFieldProps &
  Partial<{
    numberProps: Partial<NumberFormatProps>;
  }>;

const Input = React.forwardRef<any, any>(
  ({ onChange, numberProps, ...inputProps }, inputRef) => {
    return (
      <NumberFormat
        thousandSeparator="."
        decimalSeparator=","
        fixedDecimalScale
        allowLeadingZeros={false}
        isNumericString
        {...inputProps}
        {...numberProps}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: inputProps.name,
              value: values.value === "" ? undefined : Number(values.value),
            },
          });
        }}
      />
    );
  }
);

const NumberField: React.ForwardRefRenderFunction<any, NumberFieldProps> = (
  { name, label, fullWidth, ...props },
  ref
) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        return (
          <MuiTextField
            fullWidth={fullWidth}
            variant={props.variant}
            size={props.size}
            label={label}
            name={name}
            InputProps={{
              inputRef: ref,
              inputProps: props as any,
              inputComponent: Input as any,
            }}
            required={!!meta.error ?? !!meta.initialError}
            onBlur={field.onBlur}
            error={!!meta.error ?? !!meta.initialError}
            helperText={meta.error}
            value={field.value}
            onChange={field.onChange}
          />
        );
      }}
    </Field>
  );
};
export default forwardRef(NumberField);
