import React from "react";
import { Add, Delete } from "@mui/icons-material";
import {
  Button,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { Validator } from "../fields/form-wrapper";
import TextField from "../fields/text-field";
import CheckboxField from "../fields/checkbox-field";

export const UnitSchemaValidation = Validator.object().shape({
  name: Validator.string().required().nullable().label("Nome"),
  businessName: Validator.string().nullable().label("Razão Social"),
  fantasyName: Validator.string().nullable().label("Nome Fantasia"),
  document: Validator.string().nullable().label("CNPJ"),
  main: Validator.boolean().default(false).nullable().label("Matriz"),
  environments: Validator.array()
    .default([])
    .of(
      Validator.object().shape({
        description: Validator.string()
          .nullable()
          .required()
          .label("Descrição"),
        section: Validator.string().nullable().required().label("Setor"),
        room: Validator.string().nullable().label("Sala"),
        floor: Validator.string().nullable().label("Pavimento"),
        complex: Validator.string().nullable().label("Complexo"),
        id: Validator.string().nullable().label("ID"),
      })
    )
    .min(1)
    .label("Ambientes"),
});

const UnitForm: React.FC = () => {
  const { values, errors } = useFormikContext<any>();

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <TextField autoFocus name="name" label="Nome" />
        </Grid>
        <Grid item md={4}>
          <TextField label="CNPJ" name="document" />
        </Grid>
        <Grid item md={4}>
          <TextField name="businessName" label="Razão Social" />
        </Grid>
        <Grid item md={4}>
          <TextField name="fantasyName" label="Nome Fantasia" />
        </Grid>
        <Grid item md={12}>
          <CheckboxField
            type="switch"
            name="main"
            label="Definir como Matriz"
          />
        </Grid>
      </Grid>
      <FormLabel
        sx={{ my: 2, display: "block" }}
        error={!!errors?.environments}
      >
        Ambientes
        {!!errors?.environments && !Array.isArray(errors?.environments) && (
          <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
            <>{errors.environments}</>
          </FormHelperText>
        )}
      </FormLabel>
      <FieldArray name="environments">
        {({ push, remove }) => (
          <Paper
            variant="outlined"
            sx={
              errors?.environments
                ? {
                    borderColor: (theme) => theme.palette.error.main,
                  }
                : {}
            }
          >
            <Table>
              <TableFooter>
                {(values.environments ?? []).map((env: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell sx={{ textAlign: "center" }}>
                      <TextField
                        label="Descrição"
                        size="small"
                        name={`environments.${index}.description`}
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <TextField
                        label="Setor"
                        size="small"
                        name={`environments.${index}.section`}
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <TextField
                        label="Sala"
                        size="small"
                        name={`environments.${index}.room`}
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <TextField
                        label="Pavimento"
                        size="small"
                        name={`environments.${index}.floor`}
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <TextField
                        label="Complexo"
                        size="small"
                        name={`environments.${index}.complex`}
                      />
                    </TableCell>
                    <TableCell sx={{ width: "0%", textAlign: "center" }}>
                      <IconButton onClick={() => remove(index)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {(values.environments ?? []).length === 0 && (
                  <TableRow>
                    <TableCell colSpan={10} sx={{ textAlign: "center" }}>
                      <Typography variant="body2" sx={{ display: "block" }}>
                        Nenhum ambiente cadastrado
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={10} sx={{ textAlign: "right" }}>
                    <Button
                      size="small"
                      startIcon={<Add />}
                      onClick={() => {
                        push({
                          description: null,
                          complex: null,
                          floor: null,
                          section: null,
                          room: null,
                        });
                      }}
                    >
                      Adicionar Ambiente
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        )}
      </FieldArray>
    </>
  );
};

export default UnitForm;
