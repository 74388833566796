import {
  AddCircleTwoTone,
  ApartmentTwoTone,
  ArticleTwoTone,
  AssessmentTwoTone,
  AssignmentIndTwoTone,
  BusinessCenterTwoTone,
  CableTwoTone,
  CompareArrowsTwoTone,
  DashboardTwoTone,
  DevicesTwoTone,
  HealthAndSafetyTwoTone,
  ListTwoTone,
  MedicalServicesTwoTone,
  MonetizationOnTwoTone,
  PersonTwoTone,
  // SettingsTwoTone,
} from "@mui/icons-material";

import { MenuNavItem } from "../components/menu";

export const menu: MenuNavItem[] = [
  {
    label: "Início",
    to: "/",
    icon: DashboardTwoTone,
    roles: [
      "administrator",
      "doctor",
      "manager",
      "nurse",
      "receptionist",
      "support",
      "technician",
    ],
  },
  {
    label: "Empresas",
    icon: BusinessCenterTwoTone,
    children: [
      {
        label: "Novo",
        icon: AddCircleTwoTone,
        to: "/companies/new",
        mainRole: "super",
      },
      {
        label: "Explorar",
        icon: ListTwoTone,
        to: "/companies",
        mainRole: "super",
      },
    ],
  },
  // {
  //   label: "Sessão",
  //   icon: PlayCircleFilledWhite,
  //   to: "/doctor/session",
  //   roles: ["doctor"],
  // },
  {
    label: "Atendimentos",
    icon: HealthAndSafetyTwoTone,
    children: [
      {
        label: "Novo",
        icon: AddCircleTwoTone,
        to: "/orders/new",
        roles: [
          "administrator",
          "manager",
          "receptionist",
          "nurse",
          "technician",
        ],
      },
      {
        label: "Explorar",
        icon: ListTwoTone,
        to: "/orders",
        roles: [
          "administrator",
          "manager",
          "receptionist",
          "nurse",
          "technician",
        ],
      },
    ],
  },
  {
    label: "Exames",
    icon: ArticleTwoTone,
    to: "/exams",
    roles: ["administrator", "technician", "doctor", "nurse"],
  },
  {
    label: "Financeiro",
    icon: MonetizationOnTwoTone,
    roles: ["administrator", "financial"],
    children: [
      {
        label: "Relatório",
        icon: AssessmentTwoTone,
        to: "/financial/report",
        roles: ["administrator", "financial"],
      },
      {
        label: "Conciliação",
        icon: CompareArrowsTwoTone,
        to: "/financial/conciliation",
        roles: ["administrator", "financial"],
      },
    ],
  },
  {
    label: "Pacientes",
    icon: PersonTwoTone,
    children: [
      {
        label: "Novo",
        icon: AddCircleTwoTone,
        to: "/patients/new",
        roles: [
          "administrator",
          "doctor",
          "manager",
          "nurse",
          "receptionist",
          "technician",
        ],
      },
      {
        label: "Explorar",
        icon: ListTwoTone,
        to: "/patients",
        roles: [
          "administrator",
          "doctor",
          "manager",
          "nurse",
          "receptionist",
          "technician",
        ],
      },
    ],
  },
  {
    label: "Profissional",
    icon: AssignmentIndTwoTone,
    to: "/professionals",
    children: [
      {
        label: "Novo",
        icon: AddCircleTwoTone,
        to: "/professionals/new",
        roles: ["administrator", "manager"],
      },
      {
        label: "Explorar",
        icon: ListTwoTone,
        to: "/professionals",
        roles: ["administrator", "manager"],
      },
    ],
  },
  {
    label: "Serviços",
    icon: MedicalServicesTwoTone,
    to: "/services",
    children: [
      {
        label: "Novo",
        icon: AddCircleTwoTone,
        to: "/services/new",
        roles: ["administrator", "manager"],
      },
      {
        label: "Explorar",
        icon: ListTwoTone,
        to: "/services",
        roles: ["administrator", "manager"],
      },
    ],
  },
  {
    label: "Unidades",
    icon: ApartmentTwoTone,
    children: [
      {
        label: "Novo",
        icon: AddCircleTwoTone,
        to: "/units/new",
        roles: ["administrator", "manager"],
      },
      {
        label: "Explorar",
        icon: ListTwoTone,
        to: "/units",
        roles: ["administrator", "manager"],
      },
    ],
  },
  {
    label: "Equipamentos",
    icon: DevicesTwoTone,
    children: [
      {
        label: "Novo",
        icon: AddCircleTwoTone,
        to: "/devices/new",
        roles: ["administrator", "manager", "support", "technician"],
      },
      {
        label: "Explorar",
        icon: ListTwoTone,
        to: "/devices",
        roles: ["administrator", "manager", "support", "technician"],
      },
    ],
  },
  {
    label: "Integrações",
    icon: CableTwoTone,
    to: "/integrations",
    roles: ["administrator", "support"],
  },
  // {
  //   label: "Configuração",
  //   to: "/settings",
  //   icon: SettingsTwoTone,
  //   roles: ["administrator"],
  // },
];
