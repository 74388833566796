export interface ServiceType {
  label: string;
  value: string;
}

export const type: ServiceType[] = [
  { value: "exam", label: "Exame" },
  { value: "query", label: "Consulta" },
];

export const getServiceType = (value?: string): ServiceType | undefined => {
  if (!value) return undefined;
  return (
    type.find((s) => s.value === value) ?? {
      label: value,
      value,
    }
  );
};
