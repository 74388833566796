import { Box, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultHeader } from "../../components/default-header";
import FormWrapper, {
  SubmitButton,
} from "../../components/fields/form-wrapper";
import ProfessionalForm, {
  ProfessionalSchemaValidation,
} from "../../components/forms/professional-form";

import PageContent from "../../components/page-content";
import http from "../../services/http";

const ProfessionalCreatePage: React.FC = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const saveProfessional = useCallback(
    async (values: any) => {
      const { data, status } = await http.post("professionals", values);
      if (status === 201) {
        navigate("../", { state: { professional: data } });
      } else {
        const { code } = data;
        switch (code) {
          case "ACCOUNT.REGISTERED":
            snackbar.enqueueSnackbar("Usuário já existente", {
              variant: "error",
            });
            break;
          default:
            snackbar.enqueueSnackbar(
              "Ocorreu um erro ao salvar o profissional",
              { variant: "error" }
            );
            break;
        }
      }
    },
    [navigate, snackbar]
  );
  return (
    <PageContent header={<DefaultHeader title="Novo Profissional" />}>
      <FormWrapper
        validationSchema={ProfessionalSchemaValidation}
        initialValues={{}}
        onSubmit={saveProfessional}
      >
        <Paper sx={{ p: 3 }}>
          <ProfessionalForm />
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <SubmitButton variant="contained">Criar</SubmitButton>
        </Box>
      </FormWrapper>
    </PageContent>
  );
};

export default ProfessionalCreatePage;
