import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Switch,
  SwitchProps,
} from "@mui/material";
import { Field, FieldProps } from "formik";
import React, { forwardRef, useMemo } from "react";

interface CheckboxFieldProps extends Partial<CheckboxProps> {
  type: "checkbox";
}

interface SwitchFieldProps extends Partial<SwitchProps> {
  type: "switch";
}

type Props = (CheckboxFieldProps | SwitchFieldProps) & {
  name: string;
  label: string;
};

const CheckboxField: React.ForwardRefRenderFunction<any, Props> = (
  { name, label, type, ...props },
  ref
) => {
  const Component = useMemo(() => {
    switch (type) {
      case "checkbox":
        return Checkbox;
      case "switch":
        return Switch;
      default:
        throw new Error("type invalid");
    }
  }, [type]);

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        return (
          <FormControlLabel
            control={
              <Component
                value
                {...props}
                ref={ref}
                name={field.name}
                checked={field.value ?? meta.initialValue ?? false}
                onChange={field.onChange}
                onBlur={field.onBlur}
                onFocus={field.onBlur}
              />
            }
            label={label}
          />
        );
      }}
    </Field>
  );
};

export default forwardRef(CheckboxField);
