import {
  Box,
  CircularProgress,
  Drawer,
  DrawerProps,
  useTheme,
} from "@mui/material";
import { isFunction } from "lodash";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

interface DrawerItem {
  element: ReactNode | ((drawer: DrawerItemWithIdentity) => ReactNode);
  onClose?(any: any): void | Promise<void>;
  options?: Omit<DrawerProps, "open">;
}

interface DrawerContextProperties {
  open(drawer: DrawerItem): void;
  closeAll(params?: DrawerItemClose["params"]): void;
}

interface DrawerItemContextProperties {
  id: number;
  close(params?: DrawerItemClose["params"]): void;
}

interface DrawerItemWithIdentity extends DrawerItem {
  id: number;
}

interface DrawerItemClose {
  id: DrawerItemWithIdentity["id"];
  onClose?: DrawerItemWithIdentity["onClose"];
  params?: any;
}

const DrawerContext = createContext<DrawerContextProperties | undefined>(
  undefined
);

const DrawerItemContext = createContext<
  DrawerItemContextProperties | undefined
>(undefined);

export const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (!context) throw new Error("drawer provider required");
  return context;
};

export const useItemDrawer = () => {
  const context = useContext(DrawerItemContext);
  if (!context) throw new Error("item drawer provider required");
  return context;
};

export const DrawerLoading = () => (
  <Box
    display="flex"
    flex={1}
    flexGrow={1}
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress color="primary" />
  </Box>
);

const DrawerProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const theme = useTheme();
  const drawerRef = useRef(0);
  const [drawers, setDrawers] = useState<DrawerItemWithIdentity[]>([]);
  const [current, setCurrent] = useState<number[]>([]);

  const addDrawer = useCallback((item: DrawerItem) => {
    const currentIdentity = drawerRef.current;
    drawerRef.current += 1;
    setDrawers((old) => {
      old.push({
        ...item,
        id: currentIdentity,
      });
      return [...old];
    });

    setTimeout(() => {
      setCurrent((old) => {
        return [...old, currentIdentity];
      });
    }, 0);
  }, []);

  const openDrawer = useCallback(
    (item: DrawerItem) => {
      addDrawer(item);
    },
    [addDrawer]
  );

  const closeDrawer = useCallback(
    (drawerItem: DrawerItemClose) => {
      setCurrent((old) => {
        return [...old.filter((index) => index !== drawerItem.id)];
      });

      setTimeout(() => {
        setDrawers((old) => {
          return [...old.filter((d) => d.id !== drawerItem.id)];
        });
        if (drawerItem.onClose)
          drawerItem.onClose(drawerItem.params ?? undefined);
      }, theme.transitions.duration.leavingScreen);
    },
    [theme]
  );

  const closeAll = useCallback(
    (params?: DrawerItemClose["params"]) => {
      drawers.forEach((drawer) => {
        closeDrawer({
          id: drawer.id,
          onClose: drawer.onClose,
          params,
        });
      });
    },
    [drawers, closeDrawer]
  );

  const props = useMemo(
    () => ({
      open: openDrawer,
      closeAll,
    }),
    [openDrawer, closeAll]
  );

  const propsItem = useCallback(
    (drawerItem: Omit<DrawerItemWithIdentity, "element">) => ({
      id: drawerItem.id,
      close(params?: any) {
        closeDrawer({
          id: drawerItem.id,
          onClose: drawerItem.onClose,
          params,
        });
      },
    }),
    [closeDrawer]
  );

  return (
    <DrawerContext.Provider value={props}>
      {drawers.map((drawer: DrawerItemWithIdentity, index) => (
        <Drawer
          key={drawer.id}
          anchor="right"
          PaperProps={{
            sx: {
              width: `calc(80vw - ${index * 100}px)`,
              minHeight: "100%",
            },
          }}
          {...drawer?.options}
          onClose={() => {
            closeDrawer({
              id: drawer.id,
              onClose: drawer.onClose,
            });
          }}
          open={current.includes(drawer.id)}
        >
          <DrawerItemContext.Provider
            value={propsItem({
              id: drawer.id,
              onClose: drawer.onClose,
            })}
          >
            {isFunction(drawer.element)
              ? drawer.element(drawer)
              : drawer.element}
          </DrawerItemContext.Provider>
        </Drawer>
      ))}
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
