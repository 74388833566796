import React from "react";
import {
  CssBaseline,
  GlobalStyles,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { CookiesProvider } from "react-cookie";
import Moment from "react-moment";
import { SnackbarProvider } from "notistack";
import { RouterProvider } from "react-router-dom";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DialogProvider from "./hooks/dialog.hook";
import theme from "./styles";

import router from "./routes";
import DrawerProvider from "./hooks/drawer.hook";
import ModalProvider from "./hooks/modal.hook";

import "moment/locale/pt-br";

moment.locale("pt-br");

Moment.globalLocale = "pt-br";

const App: React.FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <GlobalStyles
          styles={{
            body: {
              minHeight: "100vh",
            },
            html: {
              WebkitFontSmoothing: "auto",
              minHeight: "100%",
            },
            "#root": {
              minHeight: "100vh",
            },
          }}
        />
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <CookiesProvider>
              <DialogProvider>
                <ModalProvider>
                  <DrawerProvider>
                    <RouterProvider router={router} />
                  </DrawerProvider>
                </ModalProvider>
              </DialogProvider>
            </CookiesProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
};

export default App;
