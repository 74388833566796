import { Box, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultHeader } from "../../components/default-header";
import FormWrapper, {
  SubmitButton,
} from "../../components/fields/form-wrapper";
import CompanyForm, {
  CompanySchemaValidation,
} from "../../components/forms/company-form";
import PageContent from "../../components/page-content";
import http from "../../services/http";

const CompanyCreatePage: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const saveDevice = useCallback(
    async (values: any) => {
      const { data, status } = await http.post("companies", values);
      if (status === 201) {
        enqueueSnackbar("Nova empresa cadastrada com sucesso", {
          variant: "success",
        });
        navigate("../", { state: { company: data } });
      } else {
        enqueueSnackbar("Não foi possível cadastrar a empresa", {
          variant: "error",
        });
      }
    },
    [navigate, enqueueSnackbar]
  );
  return (
    <PageContent header={<DefaultHeader title="Nova empresa" />}>
      <FormWrapper
        validationSchema={CompanySchemaValidation}
        initialValues={{}}
        onSubmit={saveDevice}
      >
        <Paper sx={{ p: 3 }}>
          <CompanyForm />
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <SubmitButton variant="contained">Cadastrar</SubmitButton>
        </Box>
      </FormWrapper>
    </PageContent>
  );
};

export default CompanyCreatePage;
