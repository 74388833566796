import React from "react";
import {
  Avatar,
  AvatarProps,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
} from "@mui/material";

type AvatarAutoCompleteListItemProps = ListItemProps & {
  title: ListItemTextProps["primary"];
  subtitle: ListItemTextProps["secondary"];
  avatar: AvatarProps;
};

const AvatarAutoCompleteListItem: React.ForwardRefRenderFunction<
  any,
  AvatarAutoCompleteListItemProps
> = ({ title, subtitle, avatar, ...props }, ref) => {
  return (
    <ListItem ref={ref} {...props}>
      <ListItemAvatar>
        <Avatar {...avatar} />
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ component: "span" }}
        primary={title}
        secondary={subtitle}
      />
    </ListItem>
  );
};

export default React.forwardRef(AvatarAutoCompleteListItem);
