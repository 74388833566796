import { createTheme } from "@mui/material/styles";

// declare module "@mui/styles/defaultTheme" {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: "#0600FE",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#53FFFF",
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(3px)",
        },
        invisible: {
          backdropFilter: "none",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${defaultTheme.palette.divider}`,
        },
        vertical: {
          borderBottom: "none",
        },
        indicator: {
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:last-child .MuiTableCell-root": { borderBottom: 0 },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 2,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderTop: `3px solid ${"#53FFFF"}`,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        dividers: true,
        root: {
          p: 3,
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 3,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "filled",
        fullWidth: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent!important",
          "&.Mui-focused .Mui-Select": {
            backgroundColor: "transparent!important",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "filled",
        fullWidth: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: "inherit",
          textTransform: "uppercase",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: "filled",
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          color: defaultTheme.palette.grey[500],
        },
        subtitle1: {
          color: defaultTheme.palette.grey[600],
          textTransform: "uppercase",
          fontWeight: "bold",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: "flex-end",
        },
      },
    },
  },
});

export default theme;
