import {
  CircularProgress,
  Collapse,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useSocket } from "../../hooks/socket.hook";
import { useTv } from "../../hooks/auth-tv.hook";
import { Device } from "../../models/device.model";

interface ICardCode {
  code: string;
}

const CardCode = ({ code }: ICardCode) => {
  return (
    <Stack direction="row" spacing={3} justifyContent="center">
      {`${code}`.split("").map((char, index) => (
        <Paper key={index} elevation={20} sx={{ px: 6, py: 3 }}>
          <Typography
            component="span"
            sx={{ fontSize: "6rem", fontWeight: "bold" }}
            align="center"
          >
            {char}
          </Typography>
        </Paper>
      ))}
    </Stack>
  );
};

export const ConnectTvPage = () => {
  const snackbar = useSnackbar();
  const tv = useTv();

  // const connectMetadataReady = useRef(false);

  const [code, setCode] = useState<string>();
  const [loading, setLoading] = useState(!code);

  const { socket, connected, restart } = useSocket();

  useEffect(() => {
    if (connected) {
      setLoading(true);

      const onConnectWaiting = async (data: { code: string }) => {
        setCode(data.code);
        setLoading(false);
      };

      const onConnectMetadata = async (data: Device) => {
        const result = await tv.set(data);

        if (!result) {
          snackbar.enqueueSnackbar("Não foi possível conectar a TV", {
            variant: "error",
          });
          setLoading(false);
          return;
        }

        // connectMetadataReady.current = true;
        snackbar.enqueueSnackbar("TV Conectada com sucesso", {
          variant: "success",
        });

        restart();
      };

      socket?.on(`tv::connection::waiting`, onConnectWaiting);
      socket?.on(`tv::connection::metadata`, onConnectMetadata);

      return () => {
        socket?.off(`tv::connection::waiting`, onConnectWaiting);
        socket?.off(`tv::connection::metadata`, onConnectMetadata);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  return (
    <>
      <Collapse in={loading} unmountOnExit>
        <CircularProgress size={100} sx={{ color: "primary.contrastText" }} />
      </Collapse>
      <Collapse in={!loading && !!code} unmountOnExit>
        <Stack spacing={2}>
          <Typography
            sx={{
              color: "primary.contrastText",
              fontSize: "6em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Emparelhar TV
          </Typography>
          <Typography
            sx={{
              color: "primary.contrastText",
              fontSize: "2rem",
              textAlign: "center",
            }}
          >
            Acesse o menu <strong>Equipamentos</strong> e<br />
            escolha a TV, clique em <strong>Emparelhar</strong> e digite o
            código abaixo.
          </Typography>
          <CardCode code={code!} />
        </Stack>
      </Collapse>
    </>
  );
};
