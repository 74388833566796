import {
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Pagination as MuiPagination,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { DefaultHeader } from "../../components/default-header";
import SearchField from "../../components/fields/search-field";
import InfoLabel from "../../components/info-label";
import PageContent from "../../components/page-content";
import { getDeviceStatus } from "../../helpers/device";
import { Company } from "../../models/company.model";
import { Pagination } from "../../models/pagination.model";
import { Service } from "../../models/service.model";
import http from "../../services/http";
import { getDocumentType } from "../../structs/document";

const CompaniesListPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loadingdevices, setLoadingDevices] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<Pagination<Service>["meta"]>();

  const fetchDevices = useCallback(
    async (searchQueryParams: URLSearchParams) => {
      setLoadingDevices(true);
      const { data, status } = await http.get<Pagination<Company>>(
        "companies",
        {
          params: {
            paginate: true,
            ...Object.fromEntries(searchQueryParams),
          },
        }
      );
      setLoadingDevices(false);

      if (status === 200) {
        setCompanies(data.items);
        setPaginationMeta(data.meta);
      }
    },
    []
  );

  const toggleVisibility = useCallback(async (company: Company) => {
    const { status, data } = await http.put(`companies/${company.id}`, {
      status: company.status === "blocked" ? "active" : "blocked",
    });

    if (status === 200) {
      setCompanies((old) => {
        return [...old].map((s) => ({
          ...s,
          status: s.id === company.id ? data.status : s.status,
        }));
      });
    }
  }, []);

  useEffect(() => {
    fetchDevices(searchParams);
  }, [fetchDevices, searchParams]);

  return (
    <PageContent header={<DefaultHeader title="Empresas" />}>
      <Paper>
        <SearchField
          sx={{ m: 2 }}
          queryParamName="search"
          fullWidth
          placeholder="Buscar por descrição, nome fantasia, razão social ou CNPJ"
        />
        <Divider />
        <Table>
          <TableBody>
            {companies.map((company) => (
              <TableRow key={company.id}>
                <TableCell sx={{ width: "1%" }}>
                  <Tooltip title="Visibilidade">
                    <Switch
                      checked={company.status === "active"}
                      onChange={() => toggleVisibility(company)}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <InfoLabel
                    label="Nome Fantasia"
                    value={company.fantasyName}
                  />
                </TableCell>
                <TableCell>
                  <InfoLabel label="Nome Social" value={company.businessName} />
                </TableCell>
                <TableCell>
                  <InfoLabel
                    label="CNPJ"
                    value={getDocumentType("cnpj").format(company.document)}
                  />
                </TableCell>
                <TableCell>
                  <InfoLabel
                    label="Contas"
                    value={company?.workspaces?.map((w) => w.name).join(",")}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "center", width: "0%" }}>
                  <Chip
                    label={getDeviceStatus(
                      company.status
                    ).label.toLocaleUpperCase()}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Collapse in={!loadingdevices && companies.length === 0}>
          <Typography
            variant="body2"
            sx={{ p: 2, display: "block", textAlign: "center" }}
          >
            Nenhuma empresa encontrado
          </Typography>
        </Collapse>
        <Collapse in={loadingdevices} sx={{ textAlign: "center" }}>
          <CircularProgress size={14} sx={{ my: 2 }} />
        </Collapse>
        <Divider />
        <Grid container sx={{ p: 2 }} justifyContent="space-between">
          <Grid item>
            {(paginationMeta?.totalPages ?? 0) > 1 && (
              <MuiPagination
                color="primary"
                page={paginationMeta?.currentPage}
                count={paginationMeta?.totalPages}
                onChange={(_, page) => {
                  searchParams.set("page", String(page));
                  navigate({
                    search: searchParams.toString(),
                  });
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Button component={Link} variant="contained" to="/companies/new">
              Cadastrar Empresa
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </PageContent>
  );
};

export default CompaniesListPage;
