import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Add, Close, TvOffOutlined } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AutocompleteElement,
  FormContainer,
  useForm,
} from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../../../hooks/socket.hook";
import { http } from "../../../services/http";
import { useItemModal } from "../../../hooks/modal.hook";
import { Patient } from "../../../models/patient.model";
import {
  CallQueue,
  CallQueueRequester,
} from "../../../models/call-queue.model";
import { useCallPatientModal } from "./use-call-patient-modal";

interface ICallOrderModal {
  socket: ReturnType<typeof useSocket>;
  navigate: ReturnType<typeof useNavigate>;
  patient: Patient;
}

const STORAGE_SETTINGS = "CALL_ORDER_USER_SETTINGS";

export const CallPatientModal = ({
  socket,
  patient,
  navigate,
}: ICallOrderModal) => {
  const modalItem = useItemModal();
  const { connected } = socket;
  const form = useForm<any>({
    defaultValues: localStorage.getItem(STORAGE_SETTINGS)
      ? JSON.parse(localStorage.getItem(STORAGE_SETTINGS) || "{}")
      : null,
  });

  const [awaiting, setAwaiting] = useState(false);
  const [startAction, setStartAction] = useState(false);

  const values = form.watch();

  const [callQueues, setCallQueues] = useState<CallQueue[]>([]);
  const [callQueueRequesters, setCallQueueRequesters] = useState<
    CallQueueRequester[]
  >([]);

  const currentCallQueue = useMemo(() => {
    return (
      callQueues.find((callQueue) => callQueue.id === values?.callQueue?.id) ??
      null
    );
  }, [callQueues, values?.callQueue?.id]);

  const { add, device, queue } = useCallPatientModal({
    callQueue: currentCallQueue,
    socket,
  });

  const fetchDevices = useCallback(async () => {
    const [
      { status: callQueuesStatus, data: callQueuesData },
      { status: callQueuesRequestersStatus, data: callQueuesRequestersData },
    ] = await Promise.all([
      http.spine.get<CallQueue[]>("call-queues"),
      http.spine.get<CallQueueRequester[]>("call-queues/requesters"),
    ]);

    if (callQueuesStatus === 200) {
      setCallQueues(callQueuesData.filter((call) => call.type === "order"));
    }
    if (callQueuesRequestersStatus === 200) {
      setCallQueueRequesters(callQueuesRequestersData);
    }
  }, []);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  useEffect(() => {
    if (values) {
      localStorage.setItem(STORAGE_SETTINGS, JSON.stringify(values));
    }
  }, [values]);

  useEffect(() => {
    if (queue.current?.value === patient.person.fullName) {
      setAwaiting(false);
      setStartAction(true);
    }
  }, [patient.person.fullName, queue]);

  if (!connected) {
    return (
      <Stack spacing={2} alignItems="center">
        <TvOffOutlined fontSize="large" />
        <Typography sx={{ textAlign: "center" }}>TV não disponível</Typography>
        <Button variant="contained">OK</Button>
      </Stack>
    );
  }

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <DialogTitle
          sx={{
            textTransform: "uppercase",
            p: 0,
            // mb: 2,

            position: "sticky",
            top: 0,
            backdropFilter: "blur(10px)",
            zIndex: 2,
          }}
        >
          Chamada paciente
        </DialogTitle>
        <Stack direction="row" spacing={1}>
          <IconButton
            onClick={() => {
              modalItem.close();
            }}
          >
            <Close />
          </IconButton>
        </Stack>
      </Stack>
      <FormContainer formContext={form}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <AutocompleteElement
              label="Fila"
              name="callQueue.id"
              matchId
              options={callQueues.map((callQueue) => ({
                id: callQueue.id,
                label: callQueue.name,
              }))}
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteElement
              label="Destino"
              name="requester.id"
              matchId
              options={callQueueRequesters.map((callQueue) => ({
                id: callQueue.id,
                label: callQueue.name,
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <Collapse in={!!currentCallQueue} unmountOnExit>
              <Paper
                variant="outlined"
                component={Stack}
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{
                  mb: 2,
                  p: 2,
                  borderColor: device?.connected
                    ? "success.main"
                    : "error.light",
                }}
              >
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: device?.connected
                      ? "success.light"
                      : "error.light",
                  }}
                />
                <Typography
                  color={device?.connected ? "success.main" : "error.light"}
                >
                  <Box component="strong" sx={{ textTransform: "uppercase" }}>
                    {device?.description}
                  </Box>{" "}
                  {device?.connected ? "conectada" : "desconectada"}
                </Typography>
              </Paper>
              <Stack direction="row" spacing={1} sx={{ mt: 2, width: 1 }}>
                <Button
                  startIcon={
                    awaiting ? <CircularProgress size={15} /> : undefined
                  }
                  fullWidth
                  variant={!startAction ? "contained" : "outlined"}
                  disabled={!device?.connected || awaiting}
                  onClick={() => {
                    add({
                      requesterId: values?.requester?.id,
                      value: patient.person.fullName,
                    });
                    setAwaiting(true);
                  }}
                >
                  {awaiting
                    ? "Aguarde..."
                    : `Chamar ${patient.person.fullName}${
                        startAction ? " novamente" : ""
                      }`}
                </Button>
                {startAction && (
                  <Button
                    fullWidth
                    startIcon={<Add />}
                    variant="contained"
                    onClick={() => {
                      navigate("/orders/new");
                      modalItem.close();
                    }}
                  >
                    Iniciar Atendimento
                  </Button>
                )}
              </Stack>
            </Collapse>
          </Grid>
        </Grid>
      </FormContainer>
    </Stack>
  );
};
