import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { Validator } from "../fields/form-wrapper";
import TextField from "../fields/text-field";
import AutocompleteField, {
  AutoCompleteComponentRef,
} from "../fields/autocomplete-field";
import UnitAutoCompleteField from "../autocompletes/unit-autocomplete-field";
import UnitEnvironmentAutoCompleteField from "../autocompletes/unit-environment-autocomplete-field";

export const DeviceSchemaValidation = Validator.object().shape({
  description: Validator.string().nullable().required().label("Descrição"),
  type: Validator.string().nullable().required().label("Tipo"),
  unit: Validator.object().shape({
    id: Validator.string().uuid().nullable().required().label("Unidade"),
  }),
  environment: Validator.object().shape({
    id: Validator.string().uuid().nullable().label("Ambiente"),
  }),
});

const DeviceForm: React.FC = () => {
  const unitEnvironmentRef = useRef<AutoCompleteComponentRef>();
  const { values } = useFormikContext<any>();

  return (
    <Grid container>
      <Grid item md={9}>
        <TextField autoFocus name="description" label="Descrição" />
      </Grid>
      <Grid item md={3}>
        <AutocompleteField
          name="type"
          label="Tipo"
          onSearchOptions={async () => [
            {
              title: "Totem",
              value: "totem",
            },
            {
              title: "TV",
              value: "tv",
            },
          ]}
        />
      </Grid>
      <Grid item md={6}>
        <UnitAutoCompleteField
          label="Unidade"
          name="unit.id"
          onSelectOption={() => {
            unitEnvironmentRef.current?.resetField();
          }}
        />
      </Grid>
      <Grid item md={6}>
        <UnitEnvironmentAutoCompleteField
          ref={unitEnvironmentRef}
          label="Ambiente"
          name="environment.id"
          unit={values.unit?.id}
          disabled={!values.unit?.id}
        />
      </Grid>
    </Grid>
  );
};

export default DeviceForm;
