import React, { useCallback, useRef } from "react";
import { FormLabel, Grid } from "@mui/material";
import { debounce, isEmpty } from "lodash";
import { useField, useFormikContext } from "formik";
import DateTimeField from "../fields/date-time-field";

import TextField from "../fields/text-field";
import { Validator } from "../fields/form-wrapper";
import { UFOptions } from "../../structs/uf";
import {
  minContactValidation,
  minDocumentValidation,
} from "../../helpers/validation";
import RadioGroupField from "../fields/radio-group-field";
import SelectField from "../fields/select-field";
import http from "../../services/http";

export const ResponsibleSchemaValidation = Validator.object().shape({
  fullName: Validator.string()
    .required()
    .matches(
      /^[\w\u00C0-\u00FF]+\s[a-zA-Z\u00C0-\u00FF\s]+$/g,
      "Nome completo inválido"
    )
    .nullable()
    .label("Nome Completo"),
  birthDate: Validator.date().required().nullable().label("Data de Nascimento"),
  gender: Validator.string().required().nullable().label("Gênero"),
  documents: Validator.mixed()
    .test(minDocumentValidation("Minímo 1 documento"))
    .transform((v) =>
      Object.entries(v).map(([type, document]) => ({ type, document }))
    )
    .label("Documentos"),
  contacts: Validator.mixed()
    .test(minContactValidation("Minímo 1 contato"))
    .transform((v) =>
      Object.entries(v).map(([type, contact]) => ({ type, contact }))
    )
    .label("Contatos"),
  address: Validator.object().shape({
    type: Validator.string().default("default").required().label("Tipo"),
    zipcode: Validator.string().nullable().required().label("CEP"),
    address: Validator.string().nullable().required().label("Endereço"),
    number: Validator.number().nullable().required().label("Número"),
    complement: Validator.string().nullable().nullable().label("Complemento"),
    district: Validator.string().nullable().required().label("Bairro"),
    city: Validator.string().nullable().required().label("Cidade"),
    uf: Validator.string().nullable().required().label("UF"),
  }),
});

export const ResponsibleInitialValue = {};

const ResponsibleForm: React.FC = () => {
  const form = useFormikContext<any>();
  const addressNumberInputRef = useRef<any>();
  const [{ value: addressValue }, , { setValue: setAddressValue }] =
    useField("address");

  const searchAddress = debounce(
    useCallback(
      async (z: string) => {
        const zipcode = String(z ?? "").replace(/[^0-9]+/g, "");
        if (!isEmpty(zipcode) && zipcode.length === 8) {
          const { data, status } = await http.get("commons/addresses", {
            params: { zipcode },
          });
          if (status === 200) {
            setAddressValue({
              ...addressValue,
              ...data,
              uf: String(data.uf).toLocaleLowerCase(),
            });
            if (data.address) {
              addressNumberInputRef.current?.focus();
            }

            setTimeout(() => {
              form.validateField("address");
            }, 300);
          }
        }
      },
      [setAddressValue, addressValue, form]
    ),
    300
  );

  return (
    <>
      <Grid container>
        <Grid item md={8}>
          <TextField autoFocus name="fullName" label="Nome Completo" />
        </Grid>
        <Grid item md={4}>
          <DateTimeField
            type="date"
            name="birthDate"
            label="Data de Nascimento"
          />
        </Grid>
        <Grid item md={12}>
          <RadioGroupField
            name="gender"
            label="Gênero"
            options={[
              {
                label: "Masculino",
                value: "male",
              },
              {
                label: "Feminino",
                value: "female",
              },
              {
                label: "Outro",
                value: "other",
              },
            ]}
          />
        </Grid>
        <Grid item md={4}>
          <TextField name="documents.cpf" mask="000.000.000-00" label="CPF" />
        </Grid>
        <Grid item md={4}>
          <TextField name="documents.rg" mask="00000000000" label="RG" />
        </Grid>
        <Grid item md={4}>
          <TextField name="documents.cnh" mask="00000000000" label="CNH" />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 0.5 }}>
        <Grid item md={4}>
          <TextField
            name="contacts.fixed"
            mask="(00) 0000-0000"
            label="Telefone"
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            name="contacts.mobile"
            mask="(00) 00000-0000"
            label="Celular"
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            name="contacts.commercial"
            mask="(00) 00000-0000"
            label="Comercial"
          />
        </Grid>
      </Grid>
      <FormLabel sx={{ display: "block", my: 2 }}>Endereço</FormLabel>
      <Grid container>
        <Grid item md={3}>
          <TextField
            name="address.zipcode"
            mask="00.000-000"
            onChange={(event) => {
              searchAddress(event.target.value);
            }}
            label="CEP"
          />
        </Grid>
        <Grid item md={7}>
          <TextField name="address.address" label="Endereço" />
        </Grid>
        <Grid item md={2}>
          <TextField
            ref={addressNumberInputRef}
            name="address.number"
            label="Número"
          />
        </Grid>
        <Grid item md={4}>
          <TextField name="address.complement" label="Complemento" />
        </Grid>
        <Grid item md={3}>
          <TextField name="address.district" label="Bairro" />
        </Grid>
        <Grid item md={3}>
          <TextField name="address.city" label="Cidade" />
        </Grid>
        <Grid item md={2}>
          <SelectField label="UF" name="address.uf" options={UFOptions} />
        </Grid>
      </Grid>
    </>
  );
};

export default ResponsibleForm;
