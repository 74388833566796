import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import { FormikHelpers, FormikProps, FormikValues } from "formik";
import { Edit, QrCode } from "@mui/icons-material";
import { merge } from "lodash";
import { DefaultHeader } from "../../components/default-header";

import FormWrapper, {
  SubmitButton,
  Validator,
} from "../../components/fields/form-wrapper";

import { TransactionSchemaValidation } from "../../components/forms/transaction-form";
import InfoLabel from "../../components/info-label";
import LoadingPage from "../../components/loading-page";
import PageContent from "../../components/page-content";
import { formatNumber } from "../../helpers/currency";
import { useDialog } from "../../hooks/dialog.hook";
import { Invoice } from "../../models/invoice.model";
import http from "../../services/http";
import {
  getTransactionStatus,
  getTransactionType,
} from "../../structs/transaction";
import { Transaction } from "../../models/transaction.model";
import { getInvoiceStatus } from "../../structs/invoice";
import { useItemModal, useModal } from "../../hooks/modal.hook";
import TextField from "../../components/fields/text-field";
import { ISocketContextValue, useSocket } from "../../hooks/socket.hook";

interface HealthInsuranceConfirmModalProps {
  transaction: Transaction;
}

const healthInsuranceConfirmValidationSchema = Validator.object().shape({
  voucher: Validator.string().required().label("Código de Autorização"),
});

const HealthInsuranceConfirmModal: React.FC<
  HealthInsuranceConfirmModalProps
> = ({ transaction }) => {
  const modalItem = useItemModal();
  const dialog = useDialog();

  const saveTransaction = useCallback(
    async (values: any) => {
      const { status, data } = await http.put(
        `transactions/${transaction.id}`,
        {
          status: "concluded",
          ...values,
        }
      );

      if (status === 200) {
        dialog.open({
          title: "Sucesso",
          message: "Confirmação efetuada",
          buttons: [
            {
              label: "OK",
              async callback() {
                modalItem.close(data);
              },
            },
          ],
        });
      }
    },
    [dialog, transaction, modalItem]
  );

  return (
    <FormWrapper
      initialValues={{}}
      validationSchema={healthInsuranceConfirmValidationSchema}
      onSubmit={saveTransaction}
    >
      <Stack spacing={2}>
        <TextField name="voucher" label="Código de Autorização" />
        <Stack direction="row" spacing={1}>
          <Button fullWidth onClick={() => modalItem.close()}>
            Cancelar
          </Button>
          <SubmitButton variant="contained" fullWidth>
            Confirmar
          </SubmitButton>
        </Stack>
      </Stack>
    </FormWrapper>
  );
};

const TransactionQRCode: React.FC<{
  transaction: Transaction;
  socket: ISocketContextValue;
}> = ({ transaction, socket }) => {
  const snackbar = useSnackbar();
  const modalItem = useItemModal();

  useEffect(() => {
    const onTransactionChangeStatus = (event: Transaction) => {
      if (event.status === "concluded") {
        snackbar.enqueueSnackbar("Pagamento confirmado", {
          variant: "success",
        });
        modalItem.close();
      }
    };
    socket.subscribe(
      `transaction::${transaction.id}::status`,
      onTransactionChangeStatus
    );

    return () => {
      socket.unsubscribe(
        `transaction::${transaction.id}::status`,
        onTransactionChangeStatus
      );
    };
  }, [modalItem, snackbar, socket, transaction.id]);

  return (
    <Stack spacing={2}>
      <Box component="img" src={transaction.transactionPixOnline?.qrcode} />
      <Stack spacing={1}>
        <Typography sx={{ fontSize: 12, color: "grey.600" }}>
          Aguardando pagamento
        </Typography>
        <LinearProgress />
      </Stack>
      <Button onClick={() => modalItem.close()}>Fechar</Button>
    </Stack>
  );
};

const InvoiceManagerPage: React.FC = () => {
  const socket = useSocket();
  const { id } = useParams<{ id: string }>();
  const [ordersSearchParams] = useSearchParams({});
  const snackbar = useSnackbar();
  const dialog = useDialog();
  const modal = useModal();

  const formRef = useRef<FormikProps<FormikValues>>(null);

  const [invoice, setInvoice] = useState<Invoice>();
  const [loadingInvoice, setLoadingInvoice] = useState(false);

  const ordersIds = useMemo(() => {
    if (!invoice?.orderItems) return ordersSearchParams;

    invoice.orderItems
      .map((o) => o.order.id)
      .forEach((orderId: string) => {
        ordersSearchParams.append("id", orderId);
      });

    return ordersSearchParams;
  }, [invoice?.orderItems, ordersSearchParams]);

  const availableValue = useMemo(() => {
    if (!invoice) return 0;
    return +invoice.transactions
      .reduce((total: number, transaction: Transaction) => {
        if (transaction.status === "concluded") {
          return +total - +transaction.value;
        }
        return total;
      }, +invoice.totalValues)
      .toFixed(2);
  }, [invoice]);

  const fetchInvoice = useCallback(async () => {
    setLoadingInvoice(true);
    const { status, data } = await http.get<Invoice>(`invoices/${id}`);
    setLoadingInvoice(false);

    if (status === 200) {
      setInvoice(data);
    }
  }, [id]);

  const save = useCallback(
    async (values: any, formHelper: FormikHelpers<FormikValues>) => {
      if (!invoice) return;
      dialog.open({
        title: "Confirmação",
        message: "Tem certeza que deseja realizar essa operação?",
        buttons: [
          {
            label: "Não",
          },
          {
            label: "Sim",
            options: {
              variant: "contained",
            },
            async callback() {
              const { status, data } = await http.post("transactions", {
                invoice: {
                  id: invoice.id,
                },
                ...values,
                orders: values.orders
                  .filter((o: any) => o.selected)
                  .map((o: any) => ({
                    id: o.id,
                  })),
              });

              if (status === 201) {
                fetchInvoice();
                formHelper.resetForm();
              } else {
                const { code } = data;
                switch (code) {
                  case "TRANSACTION.INCORRENT_VALUE":
                    snackbar.enqueueSnackbar("Valor de transação incorreto", {
                      variant: "error",
                    });
                    break;
                  case "TRANSACTION.EXISTING_PENDING":
                    snackbar.enqueueSnackbar(
                      `Pagamento "Pendente" já existente para o tipo "${
                        getTransactionType(values.type)?.label
                      }"`,
                      {
                        variant: "error",
                      }
                    );
                    break;

                  default:
                    snackbar.enqueueSnackbar(
                      "Ocorreu um erro ao criar o pagamento",
                      {
                        variant: "error",
                      }
                    );
                    break;
                }
              }
            },
          },
        ],
      });
    },
    [dialog, fetchInvoice, invoice, snackbar]
  );

  useEffect(() => {
    fetchInvoice();
  }, [fetchInvoice]);

  useEffect(() => {
    const onTransactionChangeStatus = (transaction: Transaction) => {
      setInvoice((i) => {
        if (!i) return i;
        return {
          ...i,
          ...(transaction.status === "concluded"
            ? { status: "concluded" }
            : {}),
          transactions: i?.transactions.map((t) => ({
            ...t,
            ...(t.id === transaction.id ? merge(t, transaction) : {}),
          })),
        };
      });
    };
    if (invoice?.transactions) {
      invoice.transactions.forEach((transaction) => {
        socket.subscribe(
          `transaction::${transaction.id}::status`,
          onTransactionChangeStatus
        );
      });

      return () => {
        invoice.transactions.forEach((transaction) => {
          socket.unsubscribe(
            `transaction::${transaction}::status`,
            onTransactionChangeStatus
          );
        });
      };
    }
    return () => {};
  }, [invoice, socket]);

  return (
    <PageContent
      header={<DefaultHeader title={`Fatura #${invoice?.code ?? ""}`} />}
    >
      <FormWrapper
        ref={formRef}
        initialValues={{
          orders: invoice?.orderItems.map((orderItem) => ({
            id: orderItem.order.id,
            name: orderItem.order.service.description,
            value: orderItem.value,
            service: orderItem.order.service,
            createdAt: orderItem.order.createdAt,
          })),
        }}
        validationSchema={TransactionSchemaValidation}
        onSubmit={save}
      >
        {({ values }) => (
          <>
            <LoadingPage
              loading={loadingInvoice}
              paperProps={{ sx: { mb: 2 } }}
            />
            <Collapse in={!!invoice}>
              <Paper sx={{ p: 3 }}>
                <Grid container>
                  <Grid item md={12}>
                    <Stack spacing={2} sx={{ minHeight: "100%" }}>
                      <Stack direction="row" spacing={1}>
                        <Paper
                          elevation={0}
                          sx={{ p: 2, flexGrow: 1, bgcolor: "grey.100" }}
                        >
                          <InfoLabel
                            label="Situação da Fatura"
                            value={getInvoiceStatus(invoice?.status)?.label}
                          />
                        </Paper>
                        <Collapse
                          in={values.type !== "healthInsurance"}
                          unmountOnExit
                          orientation="horizontal"
                        >
                          <Paper
                            elevation={0}
                            sx={{ p: 2, flex: 1, bgcolor: "grey.100" }}
                          >
                            <InfoLabel
                              label="Total Pago"
                              value={formatNumber(
                                (invoice?.totalValues ?? 0) - availableValue
                              )}
                            />
                          </Paper>
                        </Collapse>
                        <Collapse
                          in={values.type !== "healthInsurance"}
                          unmountOnExit
                          orientation="horizontal"
                        >
                          <Paper
                            elevation={0}
                            sx={{ p: 2, flex: 1, bgcolor: "grey.100" }}
                          >
                            <InfoLabel
                              label="Total da Fatura"
                              value={formatNumber(invoice?.totalValues ?? 0)}
                            />
                          </Paper>
                        </Collapse>
                      </Stack>
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        Transações efetuadas
                      </Typography>
                      <Paper variant="outlined">
                        <Stack divider={<Divider />}>
                          {invoice?.transactions.length === 0 && (
                            <Typography sx={{ p: 2 }} variant="caption">
                              Nenhuma transação efetuada
                            </Typography>
                          )}
                          {invoice?.transactions?.map((transaction) => (
                            <Box p={1} key={transaction.id}>
                              <Grid
                                container
                                spacing={2}
                                justifyContent="space-between"
                                alignItems="center"
                                flexWrap="nowrap"
                              >
                                {invoice.status === "pending" &&
                                  transaction.type === "healthInsurance" &&
                                  transaction.status === "pending" && (
                                    <Grid item md={false}>
                                      <Tooltip title="Gerenciar Convênio">
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            modal.open({
                                              title: "Confirmar autorização",
                                              content: (
                                                <HealthInsuranceConfirmModal
                                                  transaction={transaction}
                                                />
                                              ),
                                              onClose(t?: Transaction) {
                                                if (t?.status === "concluded") {
                                                  fetchInvoice();
                                                }
                                              },
                                            });
                                          }}
                                        >
                                          <Edit />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  )}
                                {invoice.status === "pending" &&
                                  transaction.type === "pixOnline" &&
                                  transaction.status === "pending" && (
                                    <Grid item md={false}>
                                      <Tooltip title="QR Code">
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            modal.open({
                                              title: "QR Code de Pagamento",
                                              content: (
                                                <TransactionQRCode
                                                  socket={socket}
                                                  transaction={transaction}
                                                />
                                              ),
                                              onClose(t?: Transaction) {
                                                if (t?.status === "concluded") {
                                                  fetchInvoice();
                                                }
                                              },
                                            });
                                          }}
                                        >
                                          <QrCode />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  )}
                                <Grid item md={3}>
                                  <InfoLabel
                                    label="Tipo"
                                    value={
                                      getTransactionType(transaction.type)
                                        ?.label
                                    }
                                  />
                                </Grid>
                                <Grid item md={3}>
                                  <InfoLabel
                                    label="Valor"
                                    value={formatNumber(transaction.value)}
                                  />
                                </Grid>
                                <Grid item md={3}>
                                  <InfoLabel
                                    label="Data de Pagamento"
                                    value={format(
                                      new Date(transaction.createdAt),
                                      "dd/MM/yyyy HH:mm:ss"
                                    )}
                                  />
                                </Grid>
                                <Grid item md={3} sx={{ textAlign: "right" }}>
                                  <Chip
                                    size="small"
                                    label={
                                      getTransactionStatus(transaction.status)
                                        ?.label
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </Stack>
                      </Paper>
                      <Box flexGrow={1} />
                      {ordersIds?.getAll("id").length > 0 && (
                        <Button
                          sx={{ alignSelf: "flex-end" }}
                          variant="contained"
                          component={Link}
                          to={{
                            pathname: "/orders",
                            search: ordersIds.toString(),
                          }}
                        >
                          {ordersIds?.getAll("id").length > 0
                            ? "Visualizar Atendimentos"
                            : "Visualizar Atendimento"}
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            </Collapse>
          </>
        )}
      </FormWrapper>
    </PageContent>
  );
};

export default InvoiceManagerPage;
