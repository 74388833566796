import React, { useCallback, useEffect } from "react";
import { Tab, Tabs } from "@mui/material";
import { useFormikContext } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";

const ServiceHeaderForm: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { values } = useFormikContext<any>();

  const onTabChange = useCallback(
    (_: any, value: string) => {
      if (value !== null) {
        searchParams.set("t", value);
      } else {
        searchParams.delete("t");
      }
      navigate({ search: searchParams.toString() }, { replace: true });
    },
    [navigate, searchParams]
  );

  useEffect(() => {
    if (values?.type !== "exam" && searchParams.get("t") === "report") {
      searchParams.delete("t");
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [values.type, searchParams, navigate]);

  return (
    <Tabs
      value={searchParams.get("t") ?? null}
      onChange={onTabChange}
      indicatorColor="primary"
      textColor="inherit"
      scrollButtons
      sx={{
        borderTopLeftRadius: (theme) => theme.shape.borderRadius,
        borderTopRightRadius: (theme) => theme.shape.borderRadius,
      }}
    >
      <Tab label="Geral" value={null} />
      <Tab
        label="Configuração de Exame"
        disabled={values?.type !== "exam"}
        value="report"
        sx={{
          display: values?.type !== "exam" ? "none" : "inherit",
        }}
      />
      <Tab
        label="Precificação"
        value="pricing"
        disabled={!(values?.valueBase > 0)}
        sx={{
          display: !(values?.valueBase > 0) ? "none" : "inherit",
        }}
      />
    </Tabs>
  );
};

export default ServiceHeaderForm;
