import { Box, Paper } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultHeader } from "../../components/default-header";
import FormWrapper, {
  SubmitButton,
} from "../../components/fields/form-wrapper";
import DeviceForm, {
  DeviceSchemaValidation,
} from "../../components/forms/device-form";
import PageContent from "../../components/page-content";
import http from "../../services/http";

const DeviceCreatePage: React.FC = () => {
  const navigate = useNavigate();
  const saveDevice = useCallback(
    async (values: any) => {
      const { data, status } = await http.post("devices", values);
      if (status === 201) {
        navigate("../", { state: { device: data } });
      }
    },
    [navigate]
  );
  return (
    <PageContent header={<DefaultHeader title="Registrar Equipamento" />}>
      <FormWrapper
        validationSchema={DeviceSchemaValidation}
        initialValues={{}}
        onSubmit={saveDevice}
      >
        <Paper sx={{ p: 3 }}>
          <DeviceForm />
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <SubmitButton variant="contained">Criar</SubmitButton>
        </Box>
      </FormWrapper>
    </PageContent>
  );
};

export default DeviceCreatePage;
