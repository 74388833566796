import {
  Box,
  Collapse,
  FormLabel,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useField, useFormikContext } from "formik";
import React, { useCallback, useEffect, useRef } from "react";
import http from "../../services/http";
import { PartnerAgreement } from "../../models/partner-agreement.model";
import UnitAutoCompleteField from "../autocompletes/unit-autocomplete-field";
import { AutoCompleteFieldOption } from "../fields/autocomplete-field";
import CheckboxField from "../fields/checkbox-field";
import { Validator } from "../fields/form-wrapper";
import NumberField from "../fields/number-field";
import RadioGroupField from "../fields/radio-group-field";
import TextField from "../fields/text-field";

export const ServiceSchemaValidation = Validator.object().shape({
  name: Validator.string().required().nullable().label("Nome"),
  type: Validator.string()
    .oneOf(["exam", "query"])
    .required()
    .nullable()
    .label("Tipo"),
  displayName: Validator.string()
    .required()
    .nullable()
    .label("Nome de Exibição"),
  description: Validator.string().nullable().label("Descrição"),
  valueBase: Validator.number()
    .required()
    .nullable()
    .label("Precificação Base"),
  vipValue: Validator.number()
    .nullable()
    .default(0)
    .required()
    .label("Precificação VIP"),
  pricing: Validator.object()
    .nullable()
    .default({
      tef: 0,
      pix: 0,
      posDebit: 0,
      posCredit: 0,
      money: 0,
      pixOnline: 0,
    })
    .shape({
      tef: Validator.number()
        .default(null)
        .nullable()
        .required()
        .label("Precificação Tef"),
      pix: Validator.number()
        .default(null)
        .nullable()
        .required()
        .label("Precificação Pix"),
      posDebit: Validator.number()
        .default(null)
        .nullable()
        .required()
        .label("Precificação Debito (POS)"),
      posCredit: Validator.number()
        .default(null)
        .nullable()
        .required()
        .label("Precificação Crédito (POS)"),
      money: Validator.number()
        .default(null)
        .nullable()
        .required()
        .label("Precificação Dinheiro"),
      pixOnline: Validator.number()
        .default(null)
        .nullable()
        .required()
        .label("Precificação PixOnline"),
    }),
  procedures: Validator.string().nullable().label("Procedimentos"),
  category: Validator.object()
    .transform((v) => (isEmpty(v) ? null : v))
    .default(null)
    .nullable()
    .shape({
      id: Validator.string().uuid().nullable().label("Categoria"),
    }),
  partnerAgreementPricings: Validator.array().of(
    Validator.object().shape({
      partnerAgreement: Validator.object().shape({
        id: Validator.string().uuid(),
      }),
      value: Validator.number().nullable(),
      active: Validator.boolean(),
    })
  ),
  units: Validator.mixed()
    .transform((v) => (v ?? []).map((id: string) => ({ id })))
    .label("Unidades"),
  exam: Validator.object().when(["type"], {
    is: (type: string) => type === "exam",
    then: Validator.object()
      .nullable()
      .default(null)
      .transform((v) => (isEmpty(v) ? null : v))
      .shape({
        channelDelivery: Validator.object()
          .default({
            withdrawal: true,
            whatsApp: true,
            email: true,
          })
          .nullable()
          .shape({
            withdrawal: Validator.boolean()
              .default(false)
              .label("Canal de Retirada"),
            whatsApp: Validator.boolean()
              .default(false)
              .label("Canal do WhatsApp"),
            email: Validator.boolean().default(false).label("Canal de e-mail"),
          }),
        activeReport: Validator.boolean().label("Habilitar Laudo"),
        activeDelivery: Validator.boolean().label("Habilitar retirada"),
        pickUpDay: Validator.number().when(["activeDelivery"], {
          is: (activeDelivery: boolean) => activeDelivery,
          then: Validator.number()
            .required()
            .min(0)
            .label("Dias para retirada"),
        }),
        withdrawCalendarDays: Validator.string().when(["activeDelivery"], {
          is: (activeDelivery: boolean) => activeDelivery,
          then: Validator.string()
            .required()
            .oneOf(["workingDays", "calendarDays"])
            .label("Tipo de calculo para retirada"),
        }),
      }),
  }),
});

export const ServiceInitialValue = {};

type SectionProps = {
  visible: boolean;
};
interface ServiceFormProps {
  defaultUnits?: AutoCompleteFieldOption[];
  sections: Record<"general" | "report" | "pricing", SectionProps>;
}

const ServiceForm: React.FC<ServiceFormProps> = ({
  defaultUnits = [],
  sections,
}) => {
  const form = useFormikContext();
  const ready = useRef(false);
  const [{ value: id }] = useField("id");
  const [{ value: activeDelivery }] = useField("exam.activeDelivery");
  const [{ value: partnerAgreements }] = useField("partnerAgreementPricings");

  const fetchPartnerAgreements = useCallback(
    async (query?: string) => {
      const { data, status } = await http.get<PartnerAgreement[]>(
        "partner-agreements",
        {
          params: {
            paginate: false,
            ...(query ? { search: query } : {}),
          },
        }
      );

      if (status === 200) {
        form.setFieldValue(
          "partnerAgreementPricings",
          data.map((partnerAgreement) => {
            const payloadPartnerAgreements = partnerAgreements?.find(
              (pa: any) => pa?.partnerAgreement?.id === partnerAgreement.id
            );
            return {
              partnerAgreement: { id: partnerAgreement.id },
              description: partnerAgreement.description,
              value: payloadPartnerAgreements?.value ?? null,
              active: payloadPartnerAgreements?.active ?? false,
            };
          })
        );
      }
      return [];
    },
    [form, partnerAgreements]
  );

  useEffect(() => {
    if (!ready.current) {
      ready.current = true;
      fetchPartnerAgreements();
    }
  }, [fetchPartnerAgreements]);

  return (
    <>
      <Collapse in={sections.general.visible}>
        <Grid container>
          <Grid item md={6}>
            <TextField autoFocus name="name" label="Nome" />
          </Grid>
          <Grid item md={6}>
            <TextField name="displayName" label="Nome de Exibição" />
          </Grid>
          <Grid item md={3}>
            <NumberField prefix="R$ " name="valueBase" label="Valor Base" />
          </Grid>
          <Grid item md={3}>
            <RadioGroupField
              name="type"
              label="Tipo"
              disabled={id}
              options={[
                {
                  label: "Exame",
                  value: "exam",
                },
                {
                  label: "Consulta",
                  value: "query",
                },
              ]}
            />
          </Grid>
          <Grid item md={12}>
            <UnitAutoCompleteField
              name="units"
              label="Unidades"
              multiple
              defaultValue={defaultUnits}
              defaultOptions={defaultUnits}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              name="description"
              label="Descrição"
              multiline
              rows={5}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              name="procedures"
              label="Procedimentos"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={sections.report.visible}>
        <Box>
          <Grid container>
            <Grid item md={12}>
              <Stack spacing={2}>
                <CheckboxField
                  type="checkbox"
                  name="exam.activeDelivery"
                  label="Habilitar retirada"
                />
                <Collapse in={activeDelivery} unmountOnExit>
                  <Stack>
                    <FormLabel>Canais Habilitados</FormLabel>
                    <Stack direction="row">
                      <CheckboxField
                        type="checkbox"
                        name="exam.channelDelivery.withdrawal"
                        label="Retirada"
                      />
                      <CheckboxField
                        type="checkbox"
                        name="exam.channelDelivery.whatsApp"
                        label="WhatsApp"
                      />
                      <CheckboxField
                        type="checkbox"
                        name="exam.channelDelivery.email"
                        label="E-mail"
                      />
                    </Stack>
                  </Stack>
                </Collapse>
              </Stack>
            </Grid>
            <Grid item md={3}>
              <NumberField
                name="exam.pickUpDay"
                label="Dias para retirada"
                disabled={!activeDelivery}
              />
            </Grid>
            <Grid item md={5}>
              <RadioGroupField
                disabled={!activeDelivery}
                label="Tipo de calculo para retirada"
                name="exam.withdrawCalendarDays"
                options={[
                  { label: "Dias úteis", value: "workingDays" },
                  { label: "Dias corridos", value: "calendarDays" },
                ]}
              />
            </Grid>
            <Grid item md={4} />
            <Grid item md={12}>
              <CheckboxField
                type="checkbox"
                name="exam.activeReport"
                label="Habilitar Laudo"
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
      <Collapse in={sections.pricing.visible}>
        <Stack spacing={2}>
          <Paper
            component={Stack}
            spacing={2}
            variant="outlined"
            sx={{
              p: 2,
            }}
          >
            <Typography>Precificação</Typography>
            <Box>
              <Grid container>
                <Grid item md>
                  <NumberField
                    size="small"
                    variant="outlined"
                    fullWidth={false}
                    prefix="R$ "
                    name="vipValue"
                    label="VIP"
                  />
                </Grid>
                <Grid item md>
                  <NumberField
                    size="small"
                    variant="outlined"
                    fullWidth={false}
                    prefix="R$ "
                    name="pricing.tef"
                    label="Tef"
                  />
                </Grid>
                <Grid item md>
                  <NumberField
                    size="small"
                    variant="outlined"
                    fullWidth={false}
                    prefix="R$ "
                    name="pricing.pix"
                    label="Pix (Manual)"
                  />
                </Grid>
                <Grid item md>
                  <NumberField
                    size="small"
                    variant="outlined"
                    fullWidth={false}
                    prefix="R$ "
                    name="pricing.posDebit"
                    label="Debito (POS)"
                  />
                </Grid>
                <Grid item md>
                  <NumberField
                    size="small"
                    variant="outlined"
                    fullWidth={false}
                    prefix="R$ "
                    name="pricing.posCredit"
                    label="Crédito (POS)"
                  />
                </Grid>
                <Grid item md>
                  <NumberField
                    size="small"
                    variant="outlined"
                    fullWidth={false}
                    prefix="R$ "
                    name="pricing.money"
                    label="Dinheiro"
                  />
                </Grid>
                <Grid item md>
                  <NumberField
                    size="small"
                    variant="outlined"
                    fullWidth={false}
                    prefix="R$ "
                    name="pricing.pixOnline"
                    label="Pix (Online)"
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <FormLabel>Convênios</FormLabel>
          <Paper component={Stack} variant="outlined">
            <Table>
              <TableBody>
                {(partnerAgreements ?? []).map(
                  (partnerAgreement: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>
                        <CheckboxField
                          label={partnerAgreement.description}
                          type="switch"
                          name={`partnerAgreementPricings.[${index}].active`}
                        />
                      </TableCell>
                      <TableCell sx={{ width: "20%" }}>
                        <NumberField
                          variant="outlined"
                          prefix="R$ "
                          size="small"
                          label="Valor"
                          name={`partnerAgreementPricings.[${index}].value`}
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </Paper>
        </Stack>
      </Collapse>
    </>
  );
};

export default ServiceForm;
