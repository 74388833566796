import React from "react";
import { CircularProgress, Collapse, Paper, PaperProps } from "@mui/material";

interface LoadingPageProps {
  loading: boolean;
  paperProps?: PaperProps;
}

const LoadingPage: React.FC<LoadingPageProps> = ({ loading, paperProps }) => {
  return (
    <Collapse in={loading} unmountOnExit>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 3,
          ...paperProps?.sx,
        }}
      >
        <CircularProgress />
      </Paper>
    </Collapse>
  );
};

export default LoadingPage;
