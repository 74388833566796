import {
  Box,
  Button,
  Collapse,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { Add, Delete } from "@mui/icons-material";
import { Service } from "../../models/service.model";
import PatientAutoCompleteField from "../autocompletes/patient-autocomplete-field";
import ServiceAutoCompleteField from "../autocompletes/service-autocomplete-field";
import { AutoCompleteFieldOption } from "../fields/autocomplete-field";
import FormWrapper, { SubmitButton, Validator } from "../fields/form-wrapper";
import TextField from "../fields/text-field";
import PartnerAgreementAutoCompleteField from "../autocompletes/partner-agreement-autocomplete-field";
import http from "../../services/http";
import InfoLabel from "../info-label";
import { formatNumber } from "../../helpers/currency";
import CheckboxField from "../fields/checkbox-field";
import { useItemModal, useModal } from "../../hooks/modal.hook";
import RadioGroupField from "../fields/radio-group-field";
import { getChannelDelivery } from "../../structs/order";

const OrderAddServiceSchemaValidation = Validator.object().shape({
  service: Validator.object().shape({
    id: Validator.string().uuid().required().nullable().label("Serviço"),
  }),
  requester: Validator.string().label("Solicitante"),
  channelDelivery: Validator.string()
    .nullable()
    .default(null)
    .label("Canal de entrega"),
});

export const OrderSchemaValidation = Validator.object().shape({
  patient: Validator.object().shape({
    id: Validator.string().uuid().required().nullable().label("Paciente"),
  }),
  services: Validator.array()
    .default([])
    .min(1)
    .of(
      Validator.object().shape({
        id: Validator.string().uuid().required().nullable().label("Serviço"),
        requester: Validator.string().label("Solicitante"),
        channelDelivery: Validator.string().label("Canal de entrega"),
      })
    )
    .label("Serviços"),
  invoice: Validator.object().shape({
    type: Validator.string()
      .required()
      .oneOf([
        "healthInsurance",
        "posDebit",
        "posCredit",
        "pix",
        "pixOnline",
        "money",
      ])
      .label("Tipo"),
    useVipPrice: Validator.boolean()
      .when(["type"], {
        is: (type: string) => type !== "healthInsurance",
        then: (schema) => schema.required().default(false),
      })
      .label("Usar Preço VIP"),
    healthInsurance: Validator.object().when(["type"], {
      is: (type: string) => type === "healthInsurance",
      then: (schema) =>
        schema.shape({
          code: Validator.string().required().label("N. Carteirinha / Voucher"),
          voucher: Validator.string().label("N. Carteirinha / Voucher"),
          partnerAgreement: Validator.object()
            .nullable()
            .shape({
              id: Validator.string()
                .uuid()
                .nullable()
                .required()
                .label("Convênio"),
            }),
        }),
    }),
    pixOnline: Validator.object().when(["type"], {
      is: (type: string) => type === "pixOnline",
      then: (schema) =>
        schema.shape({
          document: Validator.string()
            .cpf("CPF inválido")
            .required()
            .label("Documento"),
        }),
    }),
  }),
});

interface IOrderForm {
  defaultPatients?: AutoCompleteFieldOption<any>[];
}

interface IAddOrderService {
  excludeServices: string[];
  onSubmit(values: {
    service: Service;
    requester: string;
    channelDelivery: string;
  }): Promise<void>;
}

const AddOrderService: React.FC<IAddOrderService> = ({
  onSubmit,
  excludeServices,
}) => {
  const modalItem = useItemModal();
  const [service, setService] = useState<Service>();
  const onSubmitHandler = useCallback(
    async (values: any) => {
      if (values.service?.id) {
        const { status, data } = await http.get(
          `services/${values.service.id}`
        );

        if (status === 200) {
          await onSubmit({
            service: data,
            requester: values.requester,
            channelDelivery: values.channelDelivery,
          });
          modalItem.close();
        }
      }
    },
    [modalItem, onSubmit]
  );

  const fetchService = useCallback(async (id: Service["id"]) => {
    const { status, data } = await http.get(`services/${id}`);

    if (status === 200) {
      setService(data);
    } else {
      setService(undefined);
    }
  }, []);

  return (
    <FormWrapper
      initialValues={{ channelDelivery: "withdrawal" }}
      validationSchema={OrderAddServiceSchemaValidation}
      onSubmit={onSubmitHandler}
    >
      <Grid container>
        <Grid item md={6}>
          <ServiceAutoCompleteField
            label="Serviço"
            excludes={excludeServices}
            name="service.id"
            onChange={(_, value) => {
              fetchService(value.value);
            }}
          />
        </Grid>
        <Grid item md={6}>
          <TextField label="Solicitante" name="requester" />
        </Grid>
        <Grid item md={12} />
      </Grid>
      <Collapse in={!!service?.exam} unmountOnExit>
        <RadioGroupField
          name="channelDelivery"
          label="Tipo de entrega"
          options={[
            {
              label: "Retirada",
              value: "withdrawal",
            },
            ...(service?.exam?.channelDelivery?.email
              ? [
                  {
                    label: "E-mail",
                    value: "email",
                  },
                ]
              : []),
            ...(service?.exam?.channelDelivery?.whatsApp
              ? [
                  {
                    label: "WhatsApp",
                    value: "whatsApp",
                  },
                ]
              : []),
          ]}
        />
      </Collapse>
      <Stack alignItems="end" mt={2}>
        <SubmitButton variant="contained">Adicionar</SubmitButton>
      </Stack>
    </FormWrapper>
  );
};

const OrderForm: React.FC<IOrderForm> = ({ defaultPatients = [] }) => {
  const modal = useModal();
  const { values, setFieldValue, errors, validateForm } =
    useFormikContext<any>();

  const getServiceValue = useCallback(
    (service: Service) => {
      const invoiceType = values.invoice?.type as keyof Service["pricing"];
      if (invoiceType === "healthInsurance") {
        return (
          (service?.partnerAgreementPricings ?? [])
            ?.filter((p) => !!p.active)
            .find(
              (p) =>
                p.partnerAgreement.id ===
                values?.invoice?.healthInsurance?.partnerAgreement?.id
            )?.value ?? service?.valueBase
        );
      }
      if (!!values.invoice?.useVipPrice && (service?.vipValue ?? 0) > 0) {
        return service?.vipValue;
      }

      const pricing = service?.pricing?.[invoiceType];

      if (pricing && pricing > 0) {
        return pricing;
      }

      return service?.valueBase;
    },
    [
      values.invoice?.healthInsurance?.partnerAgreement?.id,
      values.invoice?.type,
      values.invoice?.useVipPrice,
    ]
  );

  const totalValues = useMemo(() => {
    return (values.services ?? []).reduce((total: number, service: Service) => {
      return +total + +getServiceValue(service);
    }, 0);
  }, [getServiceValue, values]);

  return (
    <Stack spacing={2}>
      <Box>
        <Grid container>
          <Grid item md={12}>
            <PatientAutoCompleteField
              defaultOptions={defaultPatients}
              autoFocus
              name="patient.id"
            />
          </Grid>
          <Grid item md={12}>
            <FieldArray
              name="services"
              render={({ push, remove }) => (
                <Stack spacing={1}>
                  <Stack
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                  >
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      Serviços
                    </Typography>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<Add />}
                      onClick={() => {
                        modal.open({
                          title: "Adicionar serviço",
                          content: (
                            <AddOrderService
                              excludeServices={(values.services ?? []).map(
                                ({ id }: { id: string }) => id
                              )}
                              onSubmit={async (value) => {
                                push({
                                  ...value.service,
                                  requester: value.requester,
                                  channelDelivery: value.channelDelivery,
                                });
                              }}
                            />
                          ),
                          options: {
                            fullWidth: true,
                            maxWidth: "md",
                          },
                        });
                      }}
                    >
                      Adicionar Serviço
                    </Button>
                  </Stack>
                  <Paper
                    component={Stack}
                    divider={<Divider flexItem />}
                    variant="outlined"
                    sx={
                      (errors as any)?.services
                        ? (theme) => ({
                            borderColor: theme.palette.error.main,
                            borderWidth: 1,
                            borderStyle: "solid",
                          })
                        : undefined
                    }
                  >
                    {(values.services ?? []).map(
                      ({ requester, ...service }: any, key: number) => (
                        <Grid
                          key={key}
                          sx={{ p: 2 }}
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Grid item md>
                            <InfoLabel label="Serviço" value={service.name} />
                          </Grid>
                          {!!service.channelDelivery && (
                            <Grid item md={3}>
                              <InfoLabel
                                label="Tipo de entrega"
                                value={
                                  getChannelDelivery(service.channelDelivery)
                                    .label
                                }
                              />
                            </Grid>
                          )}
                          <Grid item md={3}>
                            <InfoLabel label="Solicitante" value={requester} />
                          </Grid>
                          <Grid item md={2}>
                            <InfoLabel
                              label="Valor"
                              value={formatNumber(getServiceValue(service))}
                            />
                          </Grid>
                          <Grid item md={false}>
                            <Tooltip title="Deletar">
                              <IconButton
                                onClick={() => {
                                  remove(key);
                                  validateForm();
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      )
                    )}
                    {(values.services ?? []).length === 0 && (
                      <Typography
                        sx={{ p: 2, fontSize: 14, color: "grey.500" }}
                      >
                        Nenhum serviço selecionado
                      </Typography>
                    )}
                  </Paper>
                  {/* {(errors as any).services && (
                    <FormHelperText error>
                      {(errors as any).services}
                    </FormHelperText>
                  )} */}
                </Stack>
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Stack spacing={2}>
        <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
          Faturamento
        </Typography>
        <ToggleButtonGroup
          fullWidth
          exclusive
          color="primary"
          value={values.invoice?.type}
          sx={
            (errors as any)?.invoice?.type
              ? (theme) => ({
                  borderColor: theme.palette.error.main,
                  borderWidth: 1,
                  borderStyle: "solid",
                })
              : undefined
          }
          onChange={(_, value) => {
            setFieldValue("invoice.type", value);
            if (value === "healthInsurance") {
              setFieldValue("invoice.useVipPrice", false);
              setFieldValue(
                "invoice.healthInsurance.partnerAgreement.id",
                null
              );
            }
          }}
        >
          {[
            { label: "Convênio", value: "healthInsurance" },
            { label: "Débito (POS)", value: "posDebit" },
            { label: "Crédito (POS)", value: "posCredit" },
            { label: "Pix", value: "pix" },
            { label: "Pix Online", value: "pixOnline" },
            { label: "Dinheiro", value: "money" },
          ].map((item) => (
            <ToggleButton key={item.value} value={item.value}>
              {item.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {(errors as any).invoice?.type && (
          <FormHelperText error>{(errors as any).invoice?.type}</FormHelperText>
        )}

        <Collapse
          in={values?.invoice?.type === "healthInsurance"}
          unmountOnExit
        >
          <Box>
            <Grid container spacing={3}>
              <Grid item md={8}>
                <PartnerAgreementAutoCompleteField
                  label="Convênio"
                  name="invoice.healthInsurance.partnerAgreement.id"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  name="invoice.healthInsurance.code"
                  label="N. Carteirinha / Voucher"
                />
              </Grid>
            </Grid>
          </Box>
        </Collapse>
        <Collapse in={values?.invoice?.type === "pixOnline"} unmountOnExit>
          <Box>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <TextField
                  label="CPF"
                  mask="000.000.000-00"
                  name="invoice.pixOnline.document"
                />
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Stack>
      <Collapse
        in={
          values.invoice?.type !== undefined &&
          values.invoice?.type !== "healthInsurance"
        }
        unmountOnExit
      >
        <CheckboxField
          name="invoice.useVipPrice"
          label="Usar preço VIP"
          type="checkbox"
        />
      </Collapse>
      <Stack direction="row">
        <Paper elevation={0} sx={{ p: 2, flex: 1, bgcolor: "grey.100" }}>
          <InfoLabel
            label="Valor Final"
            value={formatNumber(totalValues ?? 0)}
          />
        </Paper>
      </Stack>
    </Stack>
  );
};

export default OrderForm;
