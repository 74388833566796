import React from "react";
import { FormLabel, Grid } from "@mui/material";
import { validateCNPJ } from "validations-br";
import { Validator } from "../fields/form-wrapper";
import TextField from "../fields/text-field";

export const CompanySchemaValidation = Validator.object().shape({
  document: Validator.string().test("is-cnpj", "CNPJ inválido", (value) => {
    return validateCNPJ(value ?? "");
  }),
  businessName: Validator.string().required().label("Razão social"),
  fantasyName: Validator.string().required().label("Nome fantasia"),
  mainActivity: Validator.string().label("Atividade Principal"),
  account: Validator.object().shape({
    username: Validator.string().required().label("Usuário"),
    password: Validator.string().required().label("Senha"),
  }),
});

const CompanyForm: React.FC = () => {
  return (
    <>
      <Grid container>
        <Grid item md={4}>
          <TextField
            mask="00.000.000/0000-00"
            autoFocus
            name="document"
            label="CNPJ"
          />
        </Grid>
        <Grid item md={4}>
          <TextField name="businessName" label="Razão Social" />
        </Grid>
        <Grid item md={4}>
          <TextField name="fantasyName" label="Nome Fantasia" />
        </Grid>
        <Grid item md={12}>
          <TextField name="mainActivity" label="Atividade Principal" />
        </Grid>
      </Grid>
      <FormLabel sx={{ my: 2, display: "block" }}>Conta Principal</FormLabel>
      <Grid container>
        <Grid item md={6}>
          <TextField name="account.username" label="Usuário" />
        </Grid>
        <Grid item md={6}>
          <TextField name="account.password" label="Senha" />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyForm;
