import { Add } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import React, { useCallback, useRef } from "react";
import { generateKeywords } from "../../helpers/person";
import { Document as PersonDocument } from "../../models/person.model";
import http from "../../services/http";
import AvatarAutocompleteListItem from "../autocomplete-list-items/avatar-autocomplete-list-item";
import PatientFormDialog from "../drawers/patient-form-drawer";
import AutocompleteField, {
  AutoCompleteComponentRef,
  AutoCompleteFieldOption,
  AutoCompleteFieldProps,
} from "../fields/autocomplete-field";

import { Patient } from "../../models/patient.model";
import { useDrawer } from "../../hooks/drawer.hook";

export const patientsToOptions = (
  patients: Patient[]
): AutoCompleteFieldOption<Patient>[] => {
  return patients.map((patient: Patient) => ({
    title: patient.person.fullName,
    value: patient.id,
    keywords: [
      patient.person.fullName,
      ...patient.person.documents.map((d: any) => d.document),
    ],
    data: patient,
  }));
};

const PatientAutoCompleteField: React.FC<AutoCompleteFieldProps> = (props) => {
  const patientInputRef = useRef<AutoCompleteComponentRef>();
  const drawer = useDrawer();

  const fetchPatients = useCallback(
    async (query?: string): Promise<AutoCompleteFieldOption<Patient>[]> => {
      const { data, status } = await http.get("patients", {
        params: {
          ...(query ? { search: query } : {}),
          paginate: true,
        },
      });

      const newItem = {
        title: "Novo Paciente",
        value: () => {
          drawer.open({
            element: <PatientFormDialog />,
            onClose(patient) {
              if (patient && patientInputRef.current) {
                patientInputRef.current.addOption(
                  {
                    title: patient.person.fullName,
                    value: patient.person.id,
                    keywords: generateKeywords(patient.person),
                    data: patient,
                  },
                  true
                );
              }
            },
          });
          return false;
        },
        ref: "new",
      };

      if (status === 200) {
        return [...patientsToOptions(data.items), newItem];
      }
      return [newItem];
    },
    [drawer]
  );
  return (
    <AutocompleteField
      label="Paciente"
      onSearchOptions={fetchPatients}
      renderOption={(
        itemProps,
        option: AutoCompleteFieldOption<Patient>,
        state
      ) => {
        return (
          <AvatarAutocompleteListItem
            {...itemProps}
            selected={state.selected}
            title={option.title as any}
            subtitle={
              (option.data?.person?.documents ?? []).length > 0 ? (
                <Box component="span" sx={{ display: "flex", mt: 1 }}>
                  {option.data?.person.documents.map(
                    (d: PersonDocument, key: number) => (
                      <React.Fragment key={d.type + d.document}>
                        {key > 0 && (
                          <Divider
                            component="span"
                            flexItem
                            sx={{ mx: 1 }}
                            orientation="vertical"
                          />
                        )}
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                          key={d.document + d.type}
                        >
                          <strong>{d.type.toLocaleUpperCase()} </strong>
                          {d.document}
                        </Typography>
                      </React.Fragment>
                    )
                  )}
                </Box>
              ) : undefined
            }
            avatar={{
              alt: option.title.toString(),
              children: option?.ref === "new" && <Add />,
              src: "",
              sx:
                option?.ref === "new"
                  ? {
                      bgcolor: (theme) => theme.palette.success.main,
                    }
                  : undefined,
            }}
          />
        );
      }}
      {...props}
      ref={patientInputRef}
    />
  );
};

export default PatientAutoCompleteField;
