import React, { useCallback, useRef } from "react";
import { generateKeywords } from "../../helpers/professional";
import { Professional } from "../../models/professional.model";
import http from "../../services/http";
import AutocompleteField, {
  AutoCompleteComponentRef,
  AutoCompleteFieldOption,
  AutoCompleteFieldProps,
} from "../fields/autocomplete-field";

interface ProfessionalAutoCompleteFieldProps extends AutoCompleteFieldProps {
  roles?: string[];
}

const ProfessionalAutoCompleteField: React.FC<
  ProfessionalAutoCompleteFieldProps
> = ({ roles, ...props }) => {
  const serviceInputRef = useRef<AutoCompleteComponentRef>();
  const fetchCategories = useCallback(
    async (
      query?: string
    ): Promise<AutoCompleteFieldOption<Professional>[]> => {
      const { data, status } = await http.get<Professional[]>("professionals", {
        params: {
          ...(query ? { search: query } : {}),
          roles,
        },
      });

      if (status === 200) {
        return data.map((professional: Professional) => ({
          title: professional.person.fullName,
          value: professional.id,
          keywords: generateKeywords(professional),
          data: professional,
        }));
      }
      return [];
    },
    [roles]
  );

  return (
    <AutocompleteField
      label="Profissional"
      onSearchOptions={fetchCategories}
      {...props}
      ref={serviceInputRef}
    />
  );
};

export default ProfessionalAutoCompleteField;
