import { uniq } from "lodash";
import { Service } from "../models/service.model";
import { flatten } from "./category";

const status: Record<string, string> = {
  active: "ativo",
  invisible: "invisível",
  blocked: "bloqueado",
};

export const getServiceStatus = (value: string): string => {
  return status[value] ?? value;
};

export const generateKeywords = (service: Service): string[] => {
  return uniq([
    ...(service?.name ?? "").split(/\s/),
    service?.displayName,
    ...(flatten(service.categories).map(
      (category) => category.description
    ) as any[]),
  ]);
};
