import { Container, Paper, Stack } from "@mui/material";
import React, { Suspense } from "react";

interface PageContentProps {
  header?: React.ReactNode;
  overlayBar?: boolean;
}

const PageContent: React.FC<React.PropsWithChildren<PageContentProps>> = ({
  children,
  header,
  overlayBar = true,
}) => {
  return (
    <>
      {overlayBar && (
        <Paper
          elevation={0}
          sx={{
            borderBottom: `1px solid`,
            borderBottomColor: "grey.300",
            bgcolor: "grey.50",
            height: 120,
            marginBottom: -15,
            borderEndEndRadius: 0,
            borderEndStartRadius: 0,
          }}
        />
      )}
      <Container sx={{ my: 5 }}>
        <Stack spacing={1}>
          {header}
          <Suspense fallback="carregando">{children}</Suspense>
        </Stack>
      </Container>
    </>
  );
};

export default PageContent;
