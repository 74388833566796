import React from "react";
import { Stack } from "@mui/material";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { useTv } from "../hooks/auth-tv.hook";

const TV_CONNECT_ROUTE = "/tv/connect";

const TvLayout: React.FC<React.PropsWithChildren<unknown>> = () => {
  const tv = useTv();
  const location = useLocation();

  if (!tv.authenticated && location.pathname !== TV_CONNECT_ROUTE) {
    return (
      <Navigate
        to={{
          pathname: `${TV_CONNECT_ROUTE}`,
        }}
      />
    );
  }
  if (tv.authenticated && location.pathname === TV_CONNECT_ROUTE) {
    return (
      <Navigate
        to={{
          pathname: `/tv`,
        }}
      />
    );
  }

  return (
    <Stack
      spacing={6}
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: (theme) => theme.palette.primary.main,
      }}
    >
      <Outlet />
    </Stack>
  );
};

export default React.memo(TvLayout);
