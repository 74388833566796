import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Fade,
  Grid,
  IconButton,
  Pagination as MuiPagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ConnectedTv, Delete, SmartDisplay, Upload } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { DefaultHeader } from "../../components/default-header";
import SearchField from "../../components/fields/search-field";
import InfoLabel from "../../components/info-label";
import PageContent from "../../components/page-content";
import { getDeviceStatus } from "../../helpers/device";
import { Device } from "../../models/device.model";
import { Pagination } from "../../models/pagination.model";
import { Service } from "../../models/service.model";
import http from "../../services/http";
import { useItemModal, useModal } from "../../hooks/modal.hook";
import FormWrapper, {
  SubmitButton,
} from "../../components/fields/form-wrapper";
import TextField from "../../components/fields/text-field";
import { useSocket } from "../../hooks/socket.hook";

interface IConnectTvModal {
  device: Device;
}
interface IPlaylistVideo {
  device: Device;
  onError?(message: string): void;
  onDeleteSucces?(): void;
  onUploadSucces?(): void;
}

const ConnectTvModal = ({ device }: IConnectTvModal) => {
  const snackbar = useSnackbar();
  const modalItem = useItemModal();

  const onSubmit = useCallback(
    async (values: any) => {
      const { status } = await http.put<Pagination<Device>>(
        `devices/${device.id}/link`,
        {
          code: values.code,
        }
      );

      if (status === 200) {
        modalItem.close();
        snackbar.enqueueSnackbar("Equipamento emparelhado com sucesso", {
          variant: "success",
        });
      } else {
        snackbar.enqueueSnackbar("Não foi possível emparelhar o equipamento", {
          variant: "error",
        });
      }
    },
    [device.id, modalItem, snackbar]
  );

  return (
    <FormWrapper initialValues={{ code: null }} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <Typography>
          Digite o código que aparece na TV e confirme para conectar
        </Typography>
        <TextField
          label="Código de Validação"
          name="code"
          inputProps={{
            sx: { textTransform: "uppercase" },
          }}
        />
        <SubmitButton variant="contained">Conectar</SubmitButton>
      </Stack>
    </FormWrapper>
  );
};
const PlaylistVideo = ({
  device,
  onError,
  onUploadSucces,
  onDeleteSucces,
}: IPlaylistVideo) => {
  const modalItem = useItemModal();

  const [videos, setVideos] = useState<any[]>([]);

  const [uploadProgress, setUploadProgress] = useState(0);

  const fetchVideos = useCallback(async () => {
    const response = await http.get(`devices/ads`, {
      params: {
        device: device.id,
      },
    });

    if (response.status === 200) {
      setVideos(response.data);
    }
  }, [device.id]);

  const deleteVideo = useCallback(
    async (id: string) => {
      const response = await http.delete(`devices/${device.id}/ads/${id}`, {
        params: {
          device: device.id,
        },
      });

      if (response.status === 202) {
        onDeleteSucces?.();
        fetchVideos();
      } else {
        onError?.("Não foi possível deletar o video");
      }
    },
    [device.id, fetchVideos, onDeleteSucces, onError]
  );

  const upload = useCallback(
    async (files: FileList) => {
      const formData = new FormData();

      if (files?.length === 0) return;

      formData.append("file", files[0]);

      const { status } = await http.post(`devices/${device.id}/ads`, formData, {
        responseType: "stream",
        onUploadProgress: (progressEvent) => {
          setUploadProgress((progressEvent.loaded * 100) / progressEvent.total);
        },
      });
      if (status === 201) {
        onUploadSucces?.();
        fetchVideos();
        setUploadProgress(0);
      } else {
        onError?.("Não foi possível fazer upload do video");
      }
    },
    [device.id, fetchVideos, onError, onUploadSucces]
  );

  useEffect(() => {
    fetchVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormWrapper
      initialValues={{ file: null }}
      onSubmit={() => {
        // console.log
      }}
    >
      <Stack spacing={2}>
        <Box>
          <Grid container spacing={1}>
            {videos.map((video, key) => (
              <Grid key={key} item md={4}>
                <Paper sx={{ p: 1 }} variant="outlined">
                  <Paper
                    component="video"
                    src={video.url}
                    width="100%"
                    autoPlay
                    muted
                    sx={{ objectFit: "cover" }}
                  />
                  <Tooltip title="Deletar">
                    <IconButton onClick={() => deleteVideo(video.id)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
        {videos.length === 0 && (
          <Paper
            variant="outlined"
            component={Stack}
            direction="row"
            spacing={2}
            sx={{ p: 2 }}
          >
            <Typography>Nenhum video disponível</Typography>
          </Paper>
        )}
        <Stack direction="row" spacing={1} justifyContent="end">
          <Button variant="text" onClick={modalItem.close}>
            Fechar
          </Button>
          <Button
            component="label"
            disabled={uploadProgress > 0}
            variant="contained"
            size="large"
            startIcon={
              uploadProgress > 0 ? (
                <CircularProgress
                  variant="determinate"
                  value={uploadProgress}
                  size={15}
                />
              ) : (
                <Upload />
              )
            }
          >
            {uploadProgress > 0
              ? `Carregando (${Math.round(uploadProgress)}%)`
              : "Carregar video"}
            <input
              hidden
              type="file"
              accept="video/mp4"
              onChange={(e: any) => {
                const { files } = e.target;
                if (files)
                  upload(files).then(() => {
                    e.target.value = "";
                  });
              }}
            />
          </Button>
        </Stack>
      </Stack>
    </FormWrapper>
  );
};

const DeviceListPage: React.FC = () => {
  const snackbar = useSnackbar();
  const socket = useSocket();
  const modal = useModal();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loadingdevices, setLoadingDevices] = useState(false);
  const [devices, setDevices] = useState<Device[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<Pagination<Service>["meta"]>();

  const fetchDevices = useCallback(
    async (searchQueryParams: URLSearchParams) => {
      setLoadingDevices(true);
      const { data, status } = await http.get<Pagination<Device>>("devices", {
        params: {
          paginate: true,
          ...Object.fromEntries(searchQueryParams),
        },
      });
      setLoadingDevices(false);

      if (status === 200) {
        setDevices(data.items);
        setPaginationMeta(data.meta);
      }
    },
    []
  );

  // const toggleVisibility = useCallback(async (device: Device) => {
  //   const { status, data } = await http.put(`devices/${device.id}`, {
  //     status: device.status === "blocked" ? "active" : "blocked",
  //   });

  //   if (status === 200) {
  //     setDevices((old) => {
  //       return [...old].map((s) => ({
  //         ...s,
  //         status: s.id === device.id ? data.status : s.status,
  //       }));
  //     });
  //   }
  // }, []);

  useEffect(() => {
    fetchDevices(searchParams);
  }, [fetchDevices, searchParams]);

  useEffect(() => {
    if (socket.connected) {
      socket?.socket?.on("device::status", () => fetchDevices(searchParams));
    }

    return () => {
      socket?.socket?.off("device::status", () => fetchDevices(searchParams));
    };
  }, [fetchDevices, searchParams, socket.connected, socket?.socket]);

  return (
    <PageContent header={<DefaultHeader title="Equipamentos" />}>
      <Paper>
        <SearchField
          sx={{ m: 2 }}
          queryParamName="search"
          fullWidth
          placeholder="Buscar por descrição, tipo, unidade ou ambiente"
        />
        <Divider />
        <Table>
          <TableBody>
            {devices.map((device) => (
              <TableRow key={device.id}>
                {/* <TableCell sx={{ width: "1%" }}>
                  <Tooltip title="Visibilidade">
                    <Switch
                      disabled={device.status === "validating"}
                      checked={device.status === "active"}
                      onChange={() => toggleVisibility(device)}
                    />
                  </Tooltip>
                </TableCell> */}
                <TableCell>
                  <InfoLabel label="Descrição" value={device.description} />
                </TableCell>
                <TableCell>
                  <InfoLabel label="Unidade" value={device.unit.name} />
                </TableCell>
                <TableCell>
                  <InfoLabel
                    label="Ambiente"
                    value={device.environment?.description}
                  />
                </TableCell>
                <TableCell sx={{ width: "1%" }}>
                  <Fade
                    in={
                      device.status === "validating" && device.type === "totem"
                    }
                    unmountOnExit
                  >
                    <div>
                      <InfoLabel
                        label="Código de Validação"
                        value={device.code}
                      />
                    </div>
                  </Fade>
                  <Fade in={device.type === "tv"} unmountOnExit>
                    <Button
                      color="primary"
                      startIcon={<SmartDisplay />}
                      sx={{ whiteSpace: "nowrap" }}
                      onClick={() => {
                        modal.open({
                          title: `Playlist de Video`,
                          content: (
                            <PlaylistVideo
                              device={device}
                              onUploadSucces={() => {
                                snackbar.enqueueSnackbar(
                                  "Video adicionado com sucesso",
                                  {
                                    variant: "success",
                                  }
                                );
                              }}
                              onDeleteSucces={() => {
                                snackbar.enqueueSnackbar(
                                  "Video deletado com sucesso",
                                  {
                                    variant: "success",
                                  }
                                );
                              }}
                              onError={(message) => {
                                snackbar.enqueueSnackbar(message, {
                                  variant: "error",
                                });
                              }}
                            />
                          ),
                          options: {
                            fullWidth: true,
                            maxWidth: "md",
                          },
                          onClose() {
                            setTimeout(() => {
                              fetchDevices(searchParams);
                            }, 5 * 1000);
                          },
                        });
                      }}
                    >
                      Playlist de Videos
                    </Button>
                  </Fade>
                  <Fade
                    in={
                      ["created", "disconnected"].includes(device.status) &&
                      device.type === "tv"
                    }
                    unmountOnExit
                  >
                    <Button
                      color="primary"
                      startIcon={<ConnectedTv />}
                      onClick={() => {
                        modal.open({
                          title: `Emparelhar TV`,
                          content: <ConnectTvModal device={device} />,
                          onClose() {
                            setTimeout(() => {
                              fetchDevices(searchParams);
                            }, 5 * 1000);
                          },
                        });
                      }}
                    >
                      Emparelhar
                    </Button>
                  </Fade>
                </TableCell>
                <TableCell sx={{ textAlign: "center", width: "0%" }}>
                  <Chip
                    color={getDeviceStatus(device.status).color}
                    label={getDeviceStatus(
                      device.status
                    ).label.toLocaleUpperCase()}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Collapse in={!loadingdevices && devices.length === 0}>
          <Typography
            variant="body2"
            sx={{ p: 2, display: "block", textAlign: "center" }}
          >
            Nenhum equipamento encontrado
          </Typography>
        </Collapse>
        <Collapse in={loadingdevices} sx={{ textAlign: "center" }}>
          <CircularProgress size={14} sx={{ my: 2 }} />
        </Collapse>
        <Divider />
        <Grid container sx={{ p: 2 }} justifyContent="space-between">
          <Grid item>
            {(paginationMeta?.totalPages ?? 0) > 1 && (
              <MuiPagination
                color="primary"
                page={paginationMeta?.currentPage}
                count={paginationMeta?.totalPages}
                onChange={(_, page) => {
                  searchParams.set("page", String(page));
                  navigate({
                    search: searchParams.toString(),
                  });
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Button component={Link} variant="contained" to="/devices/new">
              Registrar Equipamento
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </PageContent>
  );
};

export default DeviceListPage;
