import { Add } from "@mui/icons-material";
import {
  Avatar,
  Collapse,
  Divider,
  FormLabel,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import { debounce, isEmpty } from "lodash";
import React, { useCallback, useRef } from "react";
import { generateKeywords } from "../../helpers/person";
import {
  minContactValidation,
  minDocumentValidation,
} from "../../helpers/validation";
import { useDrawer } from "../../hooks/drawer.hook";
import { Person } from "../../models/person.model";
import http from "../../services/http";
import { UFOptions } from "../../structs/uf";
import ResponsibleFormDialog from "../drawers/responsible-form-drawer";
import AutocompleteField, {
  AutoCompleteComponentRef,
  AutoCompleteFieldOption,
} from "../fields/autocomplete-field";
import DateTimeField from "../fields/date-time-field";
import { Validator } from "../fields/form-wrapper";
import RadioGroupField from "../fields/radio-group-field";
import SelectField from "../fields/select-field";

import TextField from "../fields/text-field";

export const PatientSchemaValidation = Validator.object().shape({
  person: Validator.object()
    .nullable()
    .default({})
    .shape({
      fullName: Validator.string()
        .required()
        .matches(
          /^[\w\u00C0-\u00FF]+\s[a-zA-Z\u00C0-\u00FF\s]+$/g,
          "Nome completo inválido"
        )
        .nullable()
        .label("Nome Completo"),
      birthDate: Validator.string()
        .transform((v) => (v ? new Date(v).toISOString() : null))
        .required()
        .nullable()
        .label("Data de Nascimento"),
      gender: Validator.string().required().nullable().label("Gênero"),
      documents: Validator.mixed()
        .when(["$responsible"], {
          is: (responsible: any) => {
            return (responsible?.id ?? null) === null;
          },
          then: (schema) =>
            schema
              .test(minDocumentValidation("Obrigatório pelo menos 1 documento"))
              .transform((v) =>
                Object.entries(v)
                  .map(([type, document]) =>
                    document ? { type, document } : null
                  )
                  .filter(Boolean)
              ),
        })
        .label("Documentos"),
      contacts: Validator.mixed()
        .default([])
        .transform((v) =>
          Object.entries(v)
            .map(([type, contact]) => (contact ? { type, contact } : null))
            .filter(Boolean)
        )
        .when(["$responsible"], {
          is: (responsible: any) => {
            return typeof responsible?.id !== "string";
          },
          then: (schema) =>
            schema.test(
              minContactValidation("Obrigatório pelo menos 1 contato")
            ),
          otherwise: (schema) => {
            return schema.default([]);
          },
        })
        .label("Contatos"),
      address: Validator.object()
        .nullable()
        .default(null)
        .when(["$responsible"], {
          is: (responsible: any) => {
            return (responsible?.id ?? null) === null;
          },
          then: (schema) =>
            schema.default(null).shape({
              zipcode: Validator.string().nullable().label("CEP"),
              address: Validator.string().nullable().label("Endereço"),
              number: Validator.number()
                .transform((v) => (!Number.isNaN(+v) ? v : null))
                .nullable()
                .required()
                .label("Número"),
              complement: Validator.string().nullable().label("Complemento"),
              district: Validator.string().nullable().label("Bairro"),
              city: Validator.string().nullable().label("Cidade"),
              uf: Validator.string().nullable().label("UF"),
            }),
        }),
    }),
  responsible: Validator.object()
    .default(null)
    .transform((v) => (v?.id === null ? null : v))
    .nullable()
    .shape({
      id: Validator.string().uuid().nullable().label("Responsável"),
    }),
});

export const PatientInitialValue = {};

const PatientForm: React.FC = () => {
  const drawer = useDrawer();
  const form = useFormikContext<any>();
  const responsibleInputRef = useRef<AutoCompleteComponentRef>();
  const addressNumberInputRef = useRef<any>();

  const [{ value: addressValue }, , { setValue: setAddressValue }] =
    useField("person.address");

  const fetchResponsibles = useCallback(
    async (query?: string): Promise<AutoCompleteFieldOption[]> => {
      const { data, status } = await http.get("people", {
        params: {
          ...(query ? { search: query } : {}),
          paginate: true,
          scope: "default",
        },
      });

      const newItem = {
        title: "Novo Responsável",
        value: () => {
          drawer.open({
            element: <ResponsibleFormDialog />,
            onClose(person) {
              if (person) {
                if (responsibleInputRef.current) {
                  responsibleInputRef.current.addOption(
                    {
                      title: person.fullName,
                      value: person.id,
                      keywords: generateKeywords(person),
                      data: person,
                    },
                    true
                  );
                }
              }
            },
          });
          return false;
        },
        ref: "new",
      };
      const emptyItem = {
        title: "Sem responsável",
        value: null,
      };

      if (status === 200) {
        return [
          emptyItem,
          ...data.items.map((person: any) => ({
            title: person.fullName,
            value: person.id,
            keywords: generateKeywords(person),
            data: person,
          })),
          newItem,
        ];
      }
      return [newItem];
    },
    [drawer]
  );

  const searchAddress = debounce(
    useCallback(
      async (z: string) => {
        const zipcode = String(z ?? "").replace(/[^0-9]+/g, "");
        if (!isEmpty(zipcode) && zipcode.length === 8) {
          const { data, status } = await http.get("commons/addresses", {
            params: { zipcode },
          });
          if (status === 200) {
            setAddressValue({
              ...addressValue,
              ...data,
              uf: String(data.uf).toLocaleLowerCase(),
            });
            if (data.address) {
              addressNumberInputRef.current?.focus();
            }

            setTimeout(() => {
              form.validateField("person.address");
            }, 300);
          }
        }
      },
      [setAddressValue, addressValue, form]
    ),
    300
  );

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <AutocompleteField
            sx={{ flexGrow: 1 }}
            ref={responsibleInputRef}
            name="responsible.id"
            label="Responsável"
            onSearchOptions={fetchResponsibles}
            defaultOptions={(form.values.responsible
              ? [form.values.responsible]
              : []
            ).map((person) => ({
              title: person.fullName,
              value: person.id,
              keywords: generateKeywords(person),
              data: person,
            }))}
            renderOption={(
              props,
              option: AutoCompleteFieldOption<Person>,
              state
            ) => {
              return (
                <ListItem {...props} component="span" selected={state.selected}>
                  <ListItemAvatar>
                    {typeof option.value !== "function" && (
                      <Avatar src="" alt={option.title?.toString()} />
                    )}
                    {option?.ref === "new" && (
                      <Avatar
                        sx={{
                          bgcolor: (theme) => theme.palette.success.main,
                        }}
                      >
                        <Add />
                      </Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{ component: "span" }}
                    primary={option.title}
                    secondary={
                      <>
                        {(option.data?.documents ?? []).length > 0 && (
                          <Stack
                            sx={{ my: 1 }}
                            direction="row"
                            component="span"
                            divider={
                              <Divider
                                component="span"
                                orientation="vertical"
                                flexItem
                              />
                            }
                            spacing={2}
                          >
                            {option.data?.documents.map(
                              (d: Person["documents"][0]) => (
                                <React.Fragment key={d.type + d.document}>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                    key={d.document + d.type}
                                  >
                                    <strong>
                                      {d.type.toLocaleUpperCase()}{" "}
                                    </strong>
                                    {d.document}
                                  </Typography>
                                </React.Fragment>
                              )
                            )}
                          </Stack>
                        )}
                      </>
                    }
                  />
                </ListItem>
              );
            }}
          />
        </Grid>
        <Grid item md={8}>
          <TextField autoFocus name="person.fullName" label="Nome Completo" />
        </Grid>
        <Grid item md={4}>
          <DateTimeField
            type="date"
            name="person.birthDate"
            label="Data de Nascimento"
          />
        </Grid>
        <Grid item md={12}>
          <RadioGroupField
            name="person.gender"
            label="Gênero"
            options={[
              {
                label: "Masculino",
                value: "male",
              },
              {
                label: "Feminino",
                value: "female",
              },
              {
                label: "Outro",
                value: "other",
              },
            ]}
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            name="person.documents.cpf"
            mask="000.000.000-00"
            label="CPF"
          />
        </Grid>
        <Grid item md={4}>
          <TextField name="person.documents.rg" mask="00000000000" label="RG" />
        </Grid>
        <Grid item md={4}>
          <TextField
            name="person.documents.cnh"
            mask="00000000000"
            label="CNH"
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 0.5 }}>
        <Grid item md={4}>
          <TextField
            name="person.contacts.fixed"
            mask="(00) 0000-0000"
            label="Telefone"
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            name="person.contacts.mobile"
            mask="(00) 00000-0000"
            label="Celular"
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            name="person.contacts.commercial"
            mask="(00) 00000-0000"
            label="Comercial"
          />
        </Grid>
      </Grid>
      <Collapse in={!form.values.responsible}>
        <FormLabel sx={{ display: "block", my: 2 }}>Endereço</FormLabel>
        <Grid container>
          <Grid item md={3}>
            <TextField
              name="person.address.zipcode"
              label="CEP"
              mask="00.000-000"
              onChange={(event) => {
                searchAddress(event.target.value);
              }}
            />
          </Grid>
          <Grid item md={7}>
            <TextField name="person.address.address" label="Endereço" />
          </Grid>
          <Grid item md={2}>
            <TextField
              ref={addressNumberInputRef}
              name="person.address.number"
              label="Número"
            />
          </Grid>
          <Grid item md={4}>
            <TextField name="person.address.complement" label="Complemento" />
          </Grid>
          <Grid item md={3}>
            <TextField name="person.address.district" label="Bairro" />
          </Grid>
          <Grid item md={3}>
            <TextField name="person.address.city" label="Cidade" />
          </Grid>
          <Grid item md={2}>
            <SelectField
              label="UF"
              name="person.address.uf"
              options={UFOptions}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default PatientForm;
