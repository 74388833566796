import { AxiosResponse } from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { AdminRole } from "../models/account.model";
import { Company } from "../models/company.model";
import { Professional } from "../models/professional.model";
import http from "../services/http";
import { useDrawer } from "./drawer.hook";
import SocketProvider from "./socket.hook";

interface AuthLoginResponse {
  authenticated: boolean;
  code: "USER_OK" | "USER_BLOCKED" | "USER_PASS_INVALID";
}

interface AuthWorkspaceResponse {
  valid: boolean;
  code: "WORKSPACE_OK" | "WORKSPACE_INVALID";
}

interface AuthProviderProps {
  loginUrl?: string;
  ssoLoginUrl?: string;
  baseUrl?: string;
}

interface AuthUserInfo {
  id: string;
  name: string;
  status: string;
  mainRole: "default" | "super";
  professional: Professional | null;
  roles: {
    role: AdminRole;
  }[];
}
interface AuthWorkspaceInfo {
  id: string;
  name: string;
  company: Company;
}

interface AuthContextProperties {
  authenticated: boolean;
  user: AuthUserInfo | null;
  workspace: AuthWorkspaceInfo | null;
  sso: boolean;
  config: AuthProviderProps;
  login(
    username: string,
    password: string,
    sso?: boolean
  ): Promise<AuthLoginResponse>;
  loginWorkspace(name: string): Promise<AuthWorkspaceResponse>;
  hasRole(roles: AdminRole[], partial?: boolean): boolean;
  activate(password: string): Promise<any>;
  logout(clearWorkspace?: boolean): Promise<any>;
  getInitialRoute(): string;
}

export type AuthContextProvider = AuthContextProperties;

const STORAGE_USER_INFO = "user_info";
const STORAGE_USER_SSO = "user_sso";
const STORAGE_WORKSPACE_INFO = "workspace_info";

const AuthContext = createContext<AuthContextProperties>(
  {} as AuthContextProperties
);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("auth context required");
  return context;
};

const AuthProvider: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<AuthProviderProps>>
> = ({
  children,
  loginUrl = "/login",
  baseUrl = "/",
  ssoLoginUrl = "/sso/login",
}) => {
  const drawer = useDrawer();
  const [cookies, setCookie, removeCookie] = useCookies([
    "user_session",
    "workspace_session",
  ]);

  const [authenticatedSso, setAuthenticatedSso] = useState(() => {
    return localStorage.getItem(STORAGE_USER_SSO) === "1";
  });

  const [authenticated, setAuthenticated] = useState<boolean>(
    () => !!cookies.user_session
  );

  const [workspace, setWorkspace] = useState<AuthWorkspaceInfo | null>(() => {
    try {
      const workspaceInfo = localStorage.getItem(STORAGE_WORKSPACE_INFO);
      if (!workspaceInfo || !cookies.workspace_session) return null;
      return JSON.parse(workspaceInfo);
    } catch {
      return null;
    }
  });

  const [user, setUser] = useState<AuthUserInfo | null>(() => {
    try {
      const userInfo = localStorage.getItem(STORAGE_USER_INFO);
      if (!userInfo || !cookies.user_session) return null;
      return JSON.parse(userInfo);
    } catch {
      return null;
    }
  });

  const STORAGE_URL_RETURN = `${user?.id}.returnUrl`;

  const setWorkspaceInfo = useCallback(
    (workspaceInfoSession: any) => {
      const { session, ...workspaceInfo } = workspaceInfoSession;
      setCookie("workspace_session", session.exp * 1000, {
        expires: new Date(session.exp * 1000),
        secure: true,
      });
      localStorage.setItem(
        STORAGE_WORKSPACE_INFO,
        JSON.stringify(workspaceInfo)
      );
      setWorkspace(workspaceInfo);
    },
    [setCookie]
  );

  const setUserInfo = useCallback(
    (userInfoSession: any, sso: boolean) => {
      const { session, ...userInfo } = userInfoSession;
      setCookie("user_session", session.exp * 1000, {
        expires: new Date(session.exp * 1000),
        secure: true,
      });
      localStorage.setItem(STORAGE_USER_INFO, JSON.stringify(userInfo));
      localStorage.setItem(STORAGE_USER_SSO, `${sso ? 1 : 0}`);
      setAuthenticatedSso(sso);
      setUser(userInfo);
      setAuthenticated(true);
    },
    [setCookie]
  );

  const activateHandler = useCallback(
    async (password: string) => {
      const { status, data } = await http.post(
        `accounts/${user?.id}/activate`,
        {
          password,
        }
      );
      if (status === 200) {
        setUserInfo(data, authenticatedSso);
        return true;
      }
      setAuthenticated(false);
      return false;
    },
    [setUserInfo, authenticatedSso, user?.id]
  );

  const loginHandler = useCallback(
    async (
      username: string,
      password: string,
      sso?: boolean
    ): Promise<AuthLoginResponse> => {
      const { status, data } = await http.post("accounts/auth", null, {
        auth: {
          username,
          password,
        },
        headers: {
          ...(sso ? { "sso-mode": true } : {}),
        },
      });
      if (status === 201) {
        setUserInfo(data, sso ?? false);
        return {
          authenticated: true,
          code: "USER_OK",
        };
      }
      setAuthenticated(false);
      return {
        authenticated: false,
        code: status === 403 ? "USER_BLOCKED" : "USER_PASS_INVALID",
      };
    },
    [setUserInfo]
  );

  const loginWorkspaceHandler = useCallback(
    async (workspaceName: string): Promise<AuthWorkspaceResponse> => {
      const { status, data } = await http.post("workspaces/auth", null, {
        auth: {
          username: workspaceName,
          password: process.env.REACT_APP_PUBLIC_KEY ?? "",
        },
      });
      if (status === 201) {
        setWorkspaceInfo(data);
        return {
          valid: true,
          code: "WORKSPACE_OK",
        };
      }
      return {
        valid: false,
        code: "WORKSPACE_INVALID",
      };
    },
    [setWorkspaceInfo]
  );

  const logoutHandler = useCallback(
    async (cleanWorkspace: boolean = true) => {
      setUser(null);
      setAuthenticated(false);
      removeCookie("user_session");
      localStorage.removeItem(STORAGE_USER_INFO);

      if (cleanWorkspace) {
        removeCookie("workspace_session");
        setWorkspace(null);
        localStorage.removeItem(STORAGE_WORKSPACE_INFO);
      }

      drawer.closeAll();
    },
    [removeCookie, setAuthenticated, drawer]
  );

  const hasRoleHandler = useCallback(
    (requestRoles: AdminRole[], partial = true) => {
      if (!user?.roles) return false;

      const roles: string[] = user?.roles.map((r) => r.role);

      const checkRoles: string[] = requestRoles.reduce(
        (checks: string[], role: string) => {
          if (roles.includes(role)) {
            checks.push(role);
          }
          return checks;
        },
        []
      );

      if (partial) {
        return checkRoles.length > 0;
      }

      return checkRoles.length === requestRoles.length;
    },
    [user?.roles]
  );

  const getInitialRouteHandler = useCallback(() => {
    if (user) {
      const url = window.sessionStorage.getItem(STORAGE_URL_RETURN);
      if (url) {
        window.sessionStorage.removeItem(STORAGE_URL_RETURN);
        return url;
      }
      return baseUrl;
    }
    return baseUrl;
  }, [STORAGE_URL_RETURN, baseUrl, user]);

  const props = useMemo<AuthContextProperties>(
    () => ({
      authenticated,
      user,
      workspace,
      sso: authenticatedSso,
      login: loginHandler,
      loginWorkspace: loginWorkspaceHandler,
      logout: logoutHandler,
      activate: activateHandler,
      getInitialRoute: getInitialRouteHandler,
      hasRole: hasRoleHandler,
      config: { loginUrl, baseUrl, ssoLoginUrl },
    }),
    [
      authenticatedSso,
      ssoLoginUrl,
      authenticated,
      user,
      workspace,
      loginHandler,
      loginWorkspaceHandler,
      logoutHandler,
      activateHandler,
      getInitialRouteHandler,
      hasRoleHandler,
      loginUrl,
      baseUrl,
    ]
  );

  useEffect(() => {
    http.interceptors.response.use((response: AxiosResponse) => {
      if (response.status === 401) {
        try {
          if (user) {
            const url = new URL(window.location.toString());
            window.sessionStorage.setItem(
              STORAGE_URL_RETURN,
              `${url.pathname}${url.search}${url.hash}`
            );
          }
        } finally {
          logoutHandler(false);
        }
      }
      return response;
    });
  }, [STORAGE_URL_RETURN, logoutHandler, user]);

  return (
    <AuthContext.Provider value={props}>
      <SocketProvider namespace="admin">{children}</SocketProvider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
