import { differenceInYears } from "date-fns";
import { isEmpty } from "lodash";
import { TestConfig, ValidationError } from "yup";

export const minDocumentValidation = (
  message: string
): TestConfig<any, any> => ({
  name: "minObject",
  message,
  test: (value: any[], { createError, path, parent }) => {
    if (parent.birthDate) {
      const age = differenceInYears(new Date(), new Date(parent.birthDate));
      if (age < 18) return true;
    }

    const values = (value ?? []).filter((v) => !isEmpty(v.document));

    const keys: string[] = ["cpf", "rg", "cnh"];

    const errors: any[] = keys.map((key) => {
      const field = values.find((v) => v.type === key);

      if ((field && isEmpty(field?.document)) || values.length === 0) {
        return new ValidationError(message, `${path}.${key}`, `${path}.${key}`);
      }

      return false;
    });

    if (errors.filter((e) => e === false).length > 0) {
      return true;
    }

    return createError({
      message: () => errors,
    });
  },
});

export const minContactValidation = (
  message: string
): TestConfig<any, any> => ({
  name: "minObject",
  message,
  test: (value: any[], { createError, path, parent }) => {
    if (parent.birthDate) {
      const age = differenceInYears(new Date(), new Date(parent.birthDate));
      if (age < 18) return true;
    }
    const values = (value ?? []).filter((v) => !isEmpty(v.contact));

    const keys: string[] = ["fixed", "mobile", "commercial"];

    const errors: any[] = keys.map((key) => {
      const field = values.find((v) => v.type === key);

      if ((field && isEmpty(field?.contact)) || values.length === 0) {
        return new ValidationError(message, `${path}.${key}`, `${path}.${key}`);
      }

      return false;
    });

    if (errors.filter((e) => e === false).length > 0) {
      return true;
    }

    return createError({
      message: () => errors,
    });
  },
});
