import {
  TransactionType,
  TransactionStatus,
} from "../models/transaction.model";

export interface TransactionTypeItem {
  label: string;
  value: typeof TransactionType[number] | "other";
}
export interface TransactionStatusItem {
  label: string;
  value: typeof TransactionStatus[number] | "other";
}

export const type: TransactionTypeItem[] = [
  { value: "healthInsurance", label: "Convênio" },
  { value: "money", label: "Dinheiro" },
  { value: "pix", label: "Pix" },
  { value: "pixOnline", label: "Pix Online" },
  { value: "posCredit", label: "Crédito" },
  { value: "posDebit", label: "Débito" },
  { value: "tef", label: "TEF" },
  { value: "other", label: "Outros" },
];

export const status: TransactionStatusItem[] = [
  { value: "pending", label: "Pendente" },
  { value: "concluded", label: "Pago" },
  { value: "partial_refund", label: "Reembolso Parcial" },
  { value: "canceled", label: "Cancelado" },
  { value: "other", label: "Outros" },
];

export const getTransactionType = (
  value?: TransactionTypeItem["label"]
): TransactionTypeItem | undefined => {
  if (!value) return undefined;
  return (
    type.find((s) => s.value === value) ?? {
      label: "Outro",
      value: "other",
    }
  );
};

export const getTransactionStatus = (
  value?: TransactionStatusItem["label"]
): TransactionStatusItem | undefined => {
  if (!value) return undefined;
  return (
    status.find((s) => s.value === value) ?? {
      label: "Outro",
      value: "other",
    }
  );
};
