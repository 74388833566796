import React from "react";
import {
  Avatar,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  alpha,
  darken,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useAuth } from "../hooks/auth.hook";
import { getRole } from "../structs/roles";

const Userbar: React.FC = () => {
  const auth = useAuth();
  return (
    <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
      <Grid item sx={{ textAlign: "right" }}>
        <Typography
          variant="caption"
          sx={{
            display: "block",
            lineHeight: "normal",
            color: "white",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
          }}
        >
          {auth.user?.name}
        </Typography>
        <Typography
          sx={(theme) => ({
            display: "block",
            lineHeight: "normal",
            textTransform: "uppercase",
            fontSize: 10,
            color: alpha(theme.palette.primary.contrastText, 0.8),
          })}
          variant="caption"
        >
          {auth.user?.mainRole === "super"
            ? "Super Admin"
            : auth.user?.roles
                .map((role) => `${getRole(role.role).label.male}(a)`)
                .join(", ")}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton sx={{ p: 0 }}>
          <Avatar
            sx={(theme) => ({
              bgcolor: darken(theme.palette.primary.main, 0.1),
              color: theme.palette.primary.contrastText,
            })}
          >
            {auth.user?.name?.[0] ?? ""}
          </Avatar>
        </IconButton>
      </Grid>
      <Grid item>
        <Tooltip title="Sair">
          <IconButton
            sx={{ color: "white" }}
            onClick={() => auth.logout(false)}
          >
            <Logout />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default Userbar;
