import { SmartDisplayOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useModal } from "../hooks/modal.hook";
import { CallPatientModal } from "./modals/call-patient-modal";
import { useSocket } from "../hooks/socket.hook";
import { Patient } from "../models/patient.model";

interface ICallPatientQueueButton {
  patient: Patient;
}

export const CallPatientQueueButton = ({
  patient,
}: ICallPatientQueueButton) => {
  const socket = useSocket();
  const navigate = useNavigate();
  const modal = useModal();

  return (
    <Tooltip title="Chamar">
      <IconButton
        color="primary"
        onClick={() => {
          modal.open({
            content: (
              <CallPatientModal
                socket={socket}
                patient={patient}
                navigate={navigate}
              />
            ),
            options: {
              fullWidth: true,
              maxWidth: "md",
            },
          });
        }}
      >
        <SmartDisplayOutlined />
      </IconButton>
    </Tooltip>
  );
};
