import React from "react";
import {
  Box,
  Button,
  FormHelperText,
  FormLabel,
  Grid,
  Stack,
} from "@mui/material";
import { Upload, VerifiedUserTwoTone } from "@mui/icons-material";
import { useFormikContext } from "formik";
import TextField from "../fields/text-field";
import { Validator } from "../fields/form-wrapper";

export const EfiConfigurationSchemaValidation = Validator.object().shape({
  clientId: Validator.string().nullable().required().label("Chave Client ID"),
  clientSecret: Validator.string()
    .nullable()
    .required()
    .label("Chave Client Secret"),
  key: Validator.string().nullable().required().label("Chave Pix"),
  certificatePath: Validator.mixed().nullable().required().label("Certificado"),
});

const EfiConfigurationForm = () => {
  const form = useFormikContext<any>();

  return (
    <Box sx={{ p: 3 }}>
      <Grid container>
        <Grid item md={4}>
          <TextField label="Chave Client ID" type="password" name="clientId" />
        </Grid>
        <Grid item md={4}>
          <TextField
            label="Chave Client Secret"
            type="password"
            name="clientSecret"
          />
        </Grid>
        <Grid item md={4}>
          <TextField label="Chave Pix" name="key" />
        </Grid>
        <Grid item md={6}>
          <Stack direction="row" spacing={3} alignItems="center">
            <VerifiedUserTwoTone
              fontSize="large"
              color={form?.values?.certificatePath ? "success" : "disabled"}
            />
            <Stack spacing={1}>
              <FormLabel>Certificado de segurança</FormLabel>
              <Button
                component="label"
                startIcon={<Upload />}
                variant="outlined"
              >
                {form?.values?.certificatePath instanceof File
                  ? (form?.values?.certificatePath as File).name
                  : typeof form?.values?.certificatePath === "string"
                  ? "Carregar novo (.p12)"
                  : "Carregar (.p12)"}
                <input
                  hidden
                  accept=".p12"
                  type="file"
                  onChange={(event) => {
                    if (event.target?.files?.[0]) {
                      form.setFieldValue(
                        "certificatePath",
                        event.target.files?.[0]
                      );
                    }
                  }}
                />
              </Button>
              {!!form.errors.certificatePath && (
                <FormHelperText error>
                  {form.errors.certificatePath as string}
                </FormHelperText>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EfiConfigurationForm;
