import { Article, HealthAndSafety, MonitorHeart } from "@mui/icons-material";
import { Chip, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import {
  addDays,
  differenceInDays,
  endOfMonth,
  format,
  lastDayOfMonth,
  startOfMonth,
} from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
} from "recharts";

import { DefaultHeader } from "../../components/default-header";
import InfoCard from "../../components/info-card";
import PageContent from "../../components/page-content";
import { useAuth } from "../../hooks/auth.hook";
import { OrderReports } from "../../models/report.model";
import http from "../../services/http";

const ReportFinishedOrderDay: React.FC<{
  days: OrderReports["orders"]["finished"]["days"];
}> = ({ days }) => {
  const theme = useTheme();

  const data = useMemo(() => {
    const startDate = startOfMonth(new Date());
    const endDate = endOfMonth(new Date());

    const countDays = new Array(differenceInDays(endDate, startDate) + 1)
      .fill({ count: 0, date: null })
      .map((_, index) => {
        const currentDate = addDays(startDate, index);

        const targetDay = days.find((d: any) => {
          return (
            format(new Date(d.date), "yyyy-MM-dd") ===
            format(currentDate, "yyyy-MM-dd")
          );
        });

        return {
          count: +(targetDay?.count ?? 0),
          date: format(currentDate, "yyyy-MM-dd"),
          text: format(currentDate, "'Dia' dd"),
        };
      });

    return countDays;
  }, [days]);

  return (
    <ResponsiveContainer height={300}>
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 30,
          bottom: 60,
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0.8}
            />
            <stop
              offset="90%"
              stopColor="rgba(255,255,255,0)"
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <CartesianGrid horizontal={false} strokeDasharray={3} />
        <XAxis fontSize={9} stroke="0" dataKey="text" />
        <Area
          label={{ position: "top", offset: 10, fontSize: 10 }}
          connectNulls
          baseLine={2}
          type="monotone"
          fill="url(#colorUv)"
          dataKey="count"
          strokeWidth={2}
          stroke={theme.palette.primary.main}
          dot={{ fill: theme.palette.primary.main }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const IndexPage = () => {
  const auth = useAuth();

  const [loadingOrderReports, setLoadingOrderReports] = useState(false);
  const [orderReports, setOrderReports] = useState<OrderReports>({
    orders: {
      finished: {
        count: 0,
        days: [],
      },
    },
    exams: {
      finished: {
        count: 0,
      },
    },
    query: {
      finished: {
        count: 0,
      },
    },
  });

  const fetchOrderReports = useCallback(async () => {
    setLoadingOrderReports(true);
    const { data, status } = await http.get("reports/orders", {
      params: {
        startAt: format(startOfMonth(new Date()), "yyyy-MM-dd"),
        endAt: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
      },
    });
    setLoadingOrderReports(false);
    if (status === 200) {
      setOrderReports(data);
    }
  }, []);

  useEffect(() => {
    fetchOrderReports();
  }, [fetchOrderReports]);

  return (
    <PageContent
      overlayBar
      header={
        <DefaultHeader
          typographyProps={{ color: (theme) => theme.palette.primary.main }}
          title={`OLÁ ${auth.user?.name}`}
        />
      }
    >
      <Stack direction="column" spacing={3}>
        <Grid container>
          <Grid item md={4}>
            <InfoCard
              loading={loadingOrderReports}
              title={
                <>
                  Atendimentos Realizados{" "}
                  <Chip
                    size="small"
                    label="No mês"
                    sx={{ fontSize: "inherit" }}
                  />
                </>
              }
              icon={HealthAndSafety}
              value={orderReports.orders.finished.count}
            />
          </Grid>
          <Grid item md={4}>
            <InfoCard
              loading={loadingOrderReports}
              title={
                <>
                  Exames Realizados{" "}
                  <Chip
                    size="small"
                    label="No mês"
                    sx={{ fontSize: "inherit" }}
                  />
                </>
              }
              icon={Article}
              value={orderReports.exams.finished.count}
            />
          </Grid>
          <Grid item md={4}>
            <InfoCard
              loading={loadingOrderReports}
              title={
                <>
                  Consultas Realizada{" "}
                  <Chip
                    size="small"
                    label="No mês"
                    sx={{ fontSize: "inherit" }}
                  />
                </>
              }
              icon={MonitorHeart}
              value={orderReports.query.finished.count}
            />
          </Grid>
        </Grid>
        <Paper sx={{ height: 300, overflow: "hidden" }}>
          <Stack direction="row" sx={{ p: 2 }} alignItems="center" spacing={1}>
            <Typography
              component="span"
              sx={{
                fontSize: 13,
                textTransform: "uppercase",
                color: (theme) => theme.palette.grey[600],
              }}
            >
              Atendimentos Efetuados{" "}
              <Chip component="span" size="small" label="No mês" />
            </Typography>
          </Stack>
          <ReportFinishedOrderDay days={orderReports.orders.finished.days} />
        </Paper>
      </Stack>
    </PageContent>
  );
};

export default IndexPage;
