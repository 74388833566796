import { differenceInYears, format, parseISO } from "date-fns";

export const age = (date: Date) => {
  return differenceInYears(new Date(), date);
};

export const formatDate = (
  date: string,
  dateFormat = "dd/MM/yyyy 'às' HH'h'mm"
) => {
  return format(parseISO(date), dateFormat) ?? null;
};
