import { colors } from "@mui/material";
import { InvoiceStatus } from "../models/invoice.model";

export interface InvoiceStatusItem {
  label: string;
  value: typeof InvoiceStatus[number] | "other";
  color: string | undefined;
}

export interface TransactionStatusItem {
  label: string;
  value: typeof InvoiceStatus[number] | "other";
}

export const status: InvoiceStatusItem[] = [
  { value: "analysis", label: "Em Analise", color: colors.blue[300] },
  { value: "approved", label: "Aprovado", color: colors.green[500] },
  { value: "canceled", label: "Cancelado", color: colors.red[500] },
  { value: "concluded", label: "Concluído", color: colors.blue[500] },
  {
    value: "partial_refund",
    label: "Reembolso Parcial",
    color: colors.blue[600],
  },
  { value: "pending", label: "Pendente", color: colors.grey[500] },
  { value: "total_refund", label: "Reembolso Total", color: colors.blue[600] },
  { value: "other", label: "Outros", color: undefined },
];

export const getInvoiceStatus = (
  value?: InvoiceStatusItem["label"]
): InvoiceStatusItem | undefined => {
  if (!value) return undefined;
  return (
    status.find((s) => s.value === value) ?? {
      label: "Outro",
      value: "other",
      color: undefined,
    }
  );
};

export const getTransactionStatus = (
  value?: TransactionStatusItem["label"]
): TransactionStatusItem | undefined => {
  if (!value) return undefined;
  return (
    status.find((s) => s.value === value) ?? {
      label: "Outro",
      value: "other",
    }
  );
};
