import React, { useMemo, useState } from "react";
import { FormLabel, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { Role, roles } from "../../structs/roles";
import AutocompleteField, {
  AutoCompleteFieldOption,
} from "../fields/autocomplete-field";
import { Validator } from "../fields/form-wrapper";
import RadioGroupField from "../fields/radio-group-field";
import TextField from "../fields/text-field";

const validationRoles = (r: string[]): boolean => {
  return r.reduce((hasDocument: boolean, item: string) => {
    if (!hasDocument) {
      return ["nurse", "doctor"].includes(item);
    }
    return hasDocument;
  }, false);
};

export const ProfessionalSchemaValidation = Validator.object().shape({
  name: Validator.string().required().nullable().label("Nome"),
  gender: Validator.string().required().label("Genero"),
  document: Validator.string().when("account.role", {
    is: (r: string) => {
      return validationRoles([r]);
    },
    then: Validator.string()
      .nullable()
      .required()
      .label("Documento Profissional"),
  }),
  account: Validator.object().shape({
    username: Validator.string().required().label("Usuário"),
    password: Validator.string().required().label("Senha"),
    role: Validator.string().nullable().required().label("Papel"),
  }),
});

export const ProfessionalInitialValue = {};

const ProfessionalForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<any>();

  const requestDocument = useMemo(() => {
    if (values.account?.role) {
      return validationRoles([values.account.role] ?? []);
    }
    return false;
  }, [values.account?.role]);

  const [username, setUsername] = useState("");

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <TextField autoFocus name="name" label="Nome" />
        </Grid>
        <Grid item md={12}>
          <RadioGroupField
            name="gender"
            label="Gênero"
            options={[
              {
                label: "Masculino",
                value: "male",
              },
              {
                label: "Feminino",
                value: "female",
              },
              {
                label: "Prefiro não dizer",
                value: "other",
              },
            ]}
          />
        </Grid>
        <Grid item md>
          <AutocompleteField
            name="account.role"
            label="Papel"
            onSearchOptions={(): AutoCompleteFieldOption<Role>[] =>
              roles.map((role) => ({
                title:
                  role.label.male !== role.label.female
                    ? `${role.label.male} / ${role.label.female}`
                    : role.label.male,
                value: role.value,
                data: role,
              }))
            }
          />
        </Grid>
        {requestDocument && (
          <Grid item md={3}>
            <TextField
              name="document"
              mask="00000000000000000000000"
              label="Documento Profissional"
            />
          </Grid>
        )}
      </Grid>
      <FormLabel sx={{ my: 2, display: "block" }}>Autenticação</FormLabel>
      <Grid container>
        <Grid item md={6}>
          <TextField
            label="Usuário"
            name="account.username"
            value={username}
            onChange={(event) => {
              const value = String(event.currentTarget.value ?? "")
                .replace(/\s/g, ".")
                .replace(/[^[a-z0-9.]+$/, "")
                .replace(/[.]{2,}/g, ".");
              setUsername(value);
              setFieldValue("account.username", value);
            }}
          />
        </Grid>
        <Grid item md={6}>
          <TextField label="Senha" name="account.password" type="password" />
        </Grid>
      </Grid>
    </>
  );
};

export default ProfessionalForm;
