import { Box, Paper } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DefaultHeader } from "../../components/default-header";
import FormWrapper, {
  SubmitButton,
} from "../../components/fields/form-wrapper";
import ServiceForm, {
  ServiceSchemaValidation,
} from "../../components/forms/service-form";
import PageContent from "../../components/page-content";
import http from "../../services/http";
import ServiceHeaderForm from "./form/header";

const ServiceCreatePage: React.FC = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const saveService = useCallback(
    async (values: any) => {
      const { data, status } = await http.post("services", values);
      if (status === 201) {
        snackbar.enqueueSnackbar("Serviço salvo com sucesso", {
          variant: "success",
        });
        navigate("../", { state: { service: data } });
      } else {
        snackbar.enqueueSnackbar("Ocorreu um erro ao salvar o serviço", {
          variant: "error",
        });
      }
    },
    [navigate, snackbar]
  );
  return (
    <PageContent header={<DefaultHeader buttonToBack title="Novo Serviço" />}>
      <FormWrapper
        validationSchema={ServiceSchemaValidation}
        initialValues={{}}
        onSubmit={saveService}
      >
        <Paper>
          <ServiceHeaderForm />
          <Box p={3}>
            <ServiceForm
              sections={{
                general: {
                  visible: !searchParams.get("t"),
                },
                report: {
                  visible: searchParams.get("t") === "report",
                },
                pricing: {
                  visible: searchParams.get("t") === "pricing",
                },
              }}
            />
          </Box>
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <SubmitButton variant="contained">Criar</SubmitButton>
        </Box>
      </FormWrapper>
    </PageContent>
  );
};

export default ServiceCreatePage;
