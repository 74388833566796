import { Box, Paper } from "@mui/material";
import React, { useCallback } from "react";
import {
  createSearchParams,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import { patientsToOptions } from "../../components/autocompletes/patient-autocomplete-field";
import { DefaultHeader } from "../../components/default-header";
import FormWrapper, {
  SubmitButton,
} from "../../components/fields/form-wrapper";
import OrderForm, {
  OrderSchemaValidation,
} from "../../components/forms/order-form";
import PageContent from "../../components/page-content";
import { Patient } from "../../models/patient.model";
import http from "../../services/http";

const OrderCreatePage: React.FC = () => {
  const patient = useLoaderData() as Patient | null;
  const snackbar = useSnackbar();

  const navigate = useNavigate();

  const save = useCallback(
    async (values: any) => {
      const { status, data } = await http.post("orders", values);

      if (status === 201) {
        navigate(
          {
            pathname: "..",
            search: createSearchParams({
              id: data.map(({ id }: { id: string }) => id),
            }).toString(),
          },
          { state: { order: data } }
        );
        snackbar.enqueueSnackbar("Novo atendimento criado", {
          variant: "success",
        });
      } else if (data.code) {
        switch (data.code) {
          case "INTEGRATION.ERROR": {
            if (data.params?.error === "configuration") {
              snackbar.enqueueSnackbar("Integração não configurada", {
                variant: "warning",
              });
            }
            break;
          }
          default: {
            snackbar.enqueueSnackbar("Ocorreu um problema", {
              variant: "error",
            });
            break;
          }
        }
      }
    },
    [navigate, snackbar]
  );
  return (
    <PageContent header={<DefaultHeader title="Novo Atendimento" />}>
      <FormWrapper
        initialValues={{
          ...(patient ? { id: patient.id } : {}),
        }}
        validationSchema={OrderSchemaValidation}
        onSubmit={save}
      >
        <Paper sx={{ p: 3 }}>
          <OrderForm
            defaultPatients={patient ? patientsToOptions([patient]) : []}
          />
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <SubmitButton variant="contained">Criar</SubmitButton>
        </Box>
      </FormWrapper>
    </PageContent>
  );
};

export default OrderCreatePage;
