import { Role, roles } from "../structs/roles";

export const getRole = (value: string): Role => {
  return (
    roles.find((s) => s.value === value) ?? {
      label: { male: value, female: value, other: value },
      value,
    }
  );
};
