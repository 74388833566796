import { AddModerator, Article, Forum, Print } from "@mui/icons-material";
import {
  Badge,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Pagination as MuiPagination,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, isValid, parse } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import OrderExamManager from "../../components/contexts/order-exam-manager";
import OrderExamReport from "../../components/contexts/order-exam-report";
import OrderLogsViewer from "../../components/contexts/order-logs-viewer";
import OrderPrintViewer from "../../components/contexts/order-print";
import { DefaultHeader } from "../../components/default-header";
import SearchField from "../../components/fields/search-field";
import InfoLabel from "../../components/info-label";
import PageContent from "../../components/page-content";
import { useAuth } from "../../hooks/auth.hook";
import { useDrawer } from "../../hooks/drawer.hook";
import { Order, OrderExam } from "../../models/order.model";
import { Pagination } from "../../models/pagination.model";
import http from "../../services/http";
import { examStatus, getOrderExamStatus } from "../../structs/order";
import { CallPatientQueueButton } from "../../components/call-queue-button";

const ExamListPage: React.FC = () => {
  const auth = useAuth();
  const drawer = useDrawer();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loadingExams, setLoadingExams] = useState(false);
  const [exams, setExams] = useState<OrderExam[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<Pagination<OrderExam>["meta"]>();

  const fetchExams = useCallback(async (searchQueryParams: URLSearchParams) => {
    setLoadingExams(true);
    const { data, status } = await http.get<Pagination<OrderExam>>("exams", {
      params: {
        paginate: true,
        ...Object.fromEntries(searchQueryParams),
      },
    });
    setLoadingExams(false);

    if (status === 200) {
      setExams(data.items);
      setPaginationMeta(data.meta);
    }
  }, []);

  const onTabChange = useCallback(
    (_: any, value: string) => {
      if (value !== null) {
        searchParams.set("status", value);
      } else {
        searchParams.delete("status");
      }
      searchParams.delete("page");
      navigate({ search: searchParams.toString() }, { replace: true });
    },
    [navigate, searchParams]
  );

  const openPrintDrawer = useCallback(
    (order: Order) => {
      drawer.open({
        element: <OrderPrintViewer orders={[order]} />,
      });
    },
    [drawer]
  );

  useEffect(() => {
    fetchExams(searchParams);
  }, [fetchExams, searchParams]);

  return (
    <PageContent header={<DefaultHeader title="Exames" />}>
      <Paper>
        <Paper
          sx={{
            position: "sticky",
            top: 0,
            backdropFilter: "blur(10px)",
            backgroundColor: "transparent",
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            zIndex: 2,
          }}
          elevation={0}
        >
          <Tabs
            value={searchParams.get("status")}
            onChange={onTabChange}
            indicatorColor="primary"
            scrollButtons
            sx={{
              borderTopLeftRadius: (theme) => theme.shape.borderRadius,
              borderTopRightRadius: (theme) => theme.shape.borderRadius,
            }}
          >
            <Tab label="Todos" value={null} />
            {examStatus.map((status) => (
              <Tab
                label={status.label}
                value={status.value}
                key={status.value}
              />
            ))}
          </Tabs>
          <SearchField
            sx={{ m: 2 }}
            queryParamName="search"
            middlewareValue={(value) => {
              if (isValid(parse(value.value, "dd/MM/yyyy", new Date()))) {
                return {
                  ...value,
                  value: format(
                    parse(value.value, "dd/MM/yyyy", new Date()),
                    "yyyy-MM-dd"
                  ),
                  formatValue: "OK",
                };
              }
              if (isValid(parse(value.value, "yyyy-MM-dd", new Date()))) {
                return {
                  ...value,
                  value: format(
                    parse(value.value, "yyyy-MM-dd", new Date()),
                    "yyyy-MM-dd"
                  ),
                  formatValue: format(
                    parse(value.value, "yyyy-MM-dd", new Date()),
                    "dd/MM/yyyy"
                  ),
                };
              }
              return { ...value, formatValue: value.value };
            }}
            fullWidth
            placeholder="Buscar número do pedido, código do paciente"
          />
          <Divider />
        </Paper>
        <TableContainer>
          <Table>
            <TableBody>
              {exams.map((exam) => (
                <TableRow key={exam.id}>
                  <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
                    <Stack spacing={1} direction="row">
                      <CallPatientQueueButton patient={exam.order.patient} />

                      <Tooltip title="Imprimir Guia">
                        <IconButton
                          onClick={() => {
                            openPrintDrawer(exam.order);
                          }}
                        >
                          <Print />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Gerenciar Exame">
                        <IconButton
                          onClick={() => {
                            drawer.open({
                              element: (
                                <OrderExamManager
                                  order={{
                                    ...{ ...exam.order, exam },
                                  }}
                                  authProvider={auth}
                                />
                              ),
                              options: {
                                PaperProps: {
                                  sx: {
                                    width: "60vw",
                                  },
                                },
                              },
                              onClose() {
                                fetchExams(searchParams);
                              },
                            });
                          }}
                        >
                          <Article />
                        </IconButton>
                      </Tooltip>
                      {exam.status === "done" && (
                        <Tooltip
                          title={
                            !auth.hasRole(["doctor"])
                              ? "Apenas médicos podem gerênciar laudos"
                              : "Gerenciar Laudo"
                          }
                        >
                          <span>
                            <IconButton
                              disabled={!auth.hasRole(["doctor"])}
                              onClick={() => {
                                drawer.open({
                                  element: (
                                    <OrderExamReport
                                      reportProfessional={
                                        auth?.user?.professional ?? null
                                      }
                                      order={{
                                        ...{ ...exam.order, exam },
                                      }}
                                    />
                                  ),
                                  options: {
                                    PaperProps: {
                                      sx: {
                                        width: "60vw",
                                      },
                                    },
                                  },
                                  onClose() {
                                    fetchExams(searchParams);
                                  },
                                });
                              }}
                            >
                              <AddModerator />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                      <Tooltip title="Observações">
                        <Badge
                          badgeContent={
                            (exam.order.logs ?? []).filter(
                              (l) => l.type === "attendance"
                            ).length
                          }
                          color="warning"
                        >
                          <IconButton
                            onClick={() => {
                              drawer.open({
                                element: <OrderLogsViewer order={exam.order} />,
                                onClose(orderLog) {
                                  if (orderLog) {
                                    fetchExams(searchParams);
                                  }
                                },
                                options: {
                                  PaperProps: {
                                    sx: {
                                      width: "50vw",
                                    },
                                  },
                                },
                              });
                            }}
                          >
                            <Forum
                              sx={{
                                color:
                                  (exam.order.logs ?? []).filter(
                                    (l) => l.type === "attendance"
                                  ).length > 0
                                    ? "warning.main"
                                    : undefined,
                              }}
                            />
                          </IconButton>
                        </Badge>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <InfoLabel
                      label="Origem"
                      value={
                        exam.order.origin === "totem"
                          ? "Totem"
                          : exam.order.origin === "crm" &&
                            exam.order.professional?.person?.fullName
                          ? `Criado por ${exam.order.professional?.person?.fullName}`
                          : "CRM"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <InfoLabel
                      label="Serviço"
                      value={exam.order.service.name}
                      valueProps={{ sx: { whiteSpace: "normal" } }}
                    />
                  </TableCell>
                  <TableCell>
                    <InfoLabel
                      label="Prontuário"
                      value={exam.order.patient.code}
                    />
                  </TableCell>
                  <TableCell>
                    <InfoLabel
                      label="Paciente"
                      value={exam.order.patient.person.fullName}
                      valueProps={{ sx: { whiteSpace: "normal" } }}
                    />
                  </TableCell>
                  <TableCell>
                    <InfoLabel
                      label={
                        exam.status === "finished"
                          ? "Finalizado em"
                          : "Previsão"
                      }
                      value={format(new Date(exam.completionAt), "dd/MM/yyyy")}
                    />
                  </TableCell>
                  <TableCell>
                    <InfoLabel
                      label="Criação do atendimento"
                      value={format(
                        new Date(exam.order.createdAt),
                        "dd/MM/yyyy 'às' HH'h'mm"
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ width: "1%", textAlign: "right" }}>
                    <Chip label={getOrderExamStatus(exam.status).label} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Collapse in={!loadingExams && exams.length === 0}>
          <Typography
            variant="body2"
            sx={{ p: 2, display: "block", textAlign: "center" }}
          >
            Nenhum exame disponível
          </Typography>
        </Collapse>
        <Collapse in={loadingExams} sx={{ textAlign: "center" }}>
          <CircularProgress size={14} sx={{ my: 2 }} />
        </Collapse>

        {(paginationMeta?.totalPages ?? 0) > 1 && (
          <Paper
            elevation={0}
            sx={{
              position: "sticky",
              bottom: 0,
              backdropFilter: "blur(10px)",
              backgroundColor: "transparent",
              zIndex: 1,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
            }}
          >
            <Divider />
            <MuiPagination
              color="primary"
              sx={{ p: 2 }}
              page={paginationMeta?.currentPage}
              count={paginationMeta?.totalPages}
              onChange={(_, page) => {
                searchParams.set("page", String(page));
                navigate({
                  search: searchParams.toString(),
                });
              }}
            />
          </Paper>
        )}
      </Paper>
    </PageContent>
  );
};

export default ExamListPage;
