import { Box, Paper } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DefaultHeader } from "../../components/default-header";
import FormWrapper, {
  SubmitButton,
} from "../../components/fields/form-wrapper";
import PatientForm, {
  PatientSchemaValidation,
} from "../../components/forms/patient-form";

import PageContent from "../../components/page-content";
import { Address } from "../../models/address.model";
import { Patient } from "../../models/patient.model";
import { Person } from "../../models/person.model";
import http from "../../services/http";

interface IPerson extends Omit<Person, "documents" | "contacts" | "addresses"> {
  documents: {
    cpf: string | null;
    rg: string | null;
    cnh: string | null;
  };
  contacts: {
    fixed: string | null;
    mobile: string | null;
    commercial: string | null;
  };
  address: Address;
}
interface IPatient extends Omit<Patient, "person"> {
  person: IPerson;
}

const PatientUpdatePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();

  const [patient, setPatient] = useState<IPatient>();

  const fetchPatient = useCallback(async () => {
    const { status, data } = await http.get<Patient>(`patients/${id}`);

    if (status === 200) {
      setPatient({
        ...data,
        person: {
          ...data.person,
          documents: {
            rg:
              data.person.documents.find((d) => d.type === "rg")?.document ??
              null,
            cpf:
              data.person.documents.find((d) => d.type === "cpf")?.document ??
              null,
            cnh:
              data.person.documents.find((d) => d.type === "cnh")?.document ??
              null,
          },
          contacts: {
            fixed:
              data.person.contacts.find((d) => d.type === "fixed")?.contact ??
              null,
            mobile:
              data.person.contacts.find((d) => d.type === "mobile")?.contact ??
              null,
            commercial:
              data.person.contacts.find((d) => d.type === "commercial")
                ?.contact ?? null,
          },
          address: data.person.addresses?.[0],
        },
      });
    }
  }, [id]);

  const savePatient = useCallback(
    async (values: any) => {
      const { data, status } = await http.put(`patients/${id}`, values);
      if (status === 200) {
        if (searchParams.has("ar")) {
          navigate(decodeURIComponent(searchParams.get("ar")!));
        } else {
          navigate({
            pathname: "..",
            search: `search=${data.code}`,
          });
        }
      }
    },
    [navigate, id, searchParams]
  );

  useEffect(() => {
    fetchPatient();
  }, [fetchPatient]);

  if (!patient) return null;

  return (
    <PageContent header={<DefaultHeader title={patient.person.fullName} />}>
      <FormWrapper
        validationSchema={PatientSchemaValidation}
        initialValues={patient}
        onSubmit={savePatient}
      >
        <Paper sx={{ p: 3 }}>
          <PatientForm />
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <SubmitButton variant="contained">Atualizar</SubmitButton>
        </Box>
      </FormWrapper>
    </PageContent>
  );
};

export default PatientUpdatePage;
