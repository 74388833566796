import { Edit } from "@mui/icons-material";
import {
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Pagination as MuiPagination,
  Paper,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { DefaultHeader } from "../../components/default-header";
import SearchField from "../../components/fields/search-field";
import InfoLabel from "../../components/info-label";
import PageContent from "../../components/page-content";
import { getServiceStatus } from "../../helpers/service";
import { Pagination } from "../../models/pagination.model";
import { Service } from "../../models/service.model";
import http from "../../services/http";
import { getServiceType } from "../../structs/service";

const ServiceListPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loadingService, setLoadingService] = useState(false);
  const [services, setServices] = useState<Service[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<Pagination<Service>["meta"]>();

  const fetchService = useCallback(
    async (searchQueryParams: URLSearchParams) => {
      setLoadingService(true);
      const { data, status } = await http.get<Pagination<Service>>("services", {
        params: {
          paginate: true,
          ...Object.fromEntries(searchQueryParams),
        },
      });
      setLoadingService(false);
      if (status === 200) {
        setServices(data.items);
        setPaginationMeta(data.meta);
      }
    },
    []
  );

  const onTabChange = useCallback(
    (_: any, value: string) => {
      searchParams.set("type", value);
      searchParams.delete("page");
      navigate({ search: searchParams.toString() }, { replace: true });
    },
    [navigate, searchParams]
  );

  const toggleVisibility = useCallback(async (service: Service) => {
    const { status, data } = await http.put(`services/${service.id}`, {
      status: service.status === "invisible" ? "active" : "invisible",
    });

    if (status === 200) {
      setServices((old) => {
        return [...old].map((s) => ({
          ...s,
          status: s.id === service.id ? data.status : s.status,
        }));
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (!searchParams.has("type")) {
      searchParams.set("type", "exam");
      navigate(
        {
          search: searchParams.toString(),
        },
        { replace: true }
      );
    }
  }, [searchParams, navigate]);

  useEffect(() => {
    if (searchParams.has("type")) {
      fetchService(searchParams);
    }
  }, [fetchService, searchParams]);

  if (!searchParams.has("type")) return null;

  return (
    <PageContent header={<DefaultHeader title="Serviços" />}>
      <Paper>
        <Tabs
          value={searchParams.get("type")}
          onChange={onTabChange}
          scrollButtons
          sx={{
            borderTopLeftRadius: (theme) => theme.shape.borderRadius,
            borderTopRightRadius: (theme) => theme.shape.borderRadius,
          }}
        >
          <Tab label="Exames" value="exam" />
          <Tab label="Consultas" value="query" />
        </Tabs>
        <SearchField
          sx={{ m: 2 }}
          queryParamName="search"
          fullWidth
          placeholder="Buscar por Nome ou Nome de exibição"
        />
        <Divider />
        <Table>
          <TableBody>
            {services.map((service) => (
              <TableRow key={service.id}>
                <TableCell sx={{ width: "1%" }}>
                  <Tooltip title="Visibilidade">
                    <Switch
                      disabled={service.status === "blocked"}
                      checked={service.status === "active"}
                      onChange={() => toggleVisibility(service)}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <InfoLabel label="Nome" value={service.name} />
                </TableCell>
                <TableCell>
                  <InfoLabel
                    label="Nome de Exibição"
                    value={service.displayName}
                  />
                </TableCell>
                <TableCell>
                  <InfoLabel
                    label="Tipo"
                    value={getServiceType(service.type)?.label}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "center", width: "1%" }}>
                  <Chip
                    label={getServiceStatus(service.status).toLocaleUpperCase()}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "center", width: "1%" }}>
                  <IconButton component={Link} to={`./${service.id}`}>
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Collapse in={!loadingService && services.length === 0}>
          <Typography
            variant="body2"
            sx={{ p: 2, display: "block", textAlign: "center" }}
          >
            Nenhum serviço encontrado
          </Typography>
        </Collapse>
        <Collapse in={loadingService} sx={{ textAlign: "center" }}>
          <CircularProgress size={14} sx={{ my: 2 }} />
        </Collapse>
        <Divider />
        <Grid container sx={{ p: 2 }} justifyContent="space-between">
          <Grid item>
            {(paginationMeta?.totalPages ?? 0) > 1 && (
              <MuiPagination
                color="primary"
                page={paginationMeta?.currentPage}
                count={paginationMeta?.totalPages}
                onChange={(_, page) => {
                  searchParams.set("page", String(page));
                  navigate({
                    search: searchParams.toString(),
                  });
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Button component={Link} variant="contained" to="/services/new">
              Novo Serviço
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </PageContent>
  );
};

export default ServiceListPage;
