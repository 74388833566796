export interface UnitStatus {
  label: string;
  value: string;
}

export const status: UnitStatus[] = [
  { value: "validating", label: "em validação" },
  { value: "active", label: "ativo" },
  { value: "blocked", label: "bloqueado" },
];
