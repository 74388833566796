import React, { useCallback } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FormWrapper, { SubmitButton } from "../fields/form-wrapper";
import PatientForm, {
  PatientInitialValue,
  PatientSchemaValidation,
} from "../forms/patient-form";
import http from "../../services/http";
import { useItemDrawer } from "../../hooks/drawer.hook";

const PatientFormDrawer: React.FC = () => {
  const drawerItem = useItemDrawer();
  const savePatient = useCallback(
    async (values: any) => {
      const { data, status } = await http.post("patients", values);
      if (status === 201) {
        drawerItem.close(data);
      }
    },
    [drawerItem]
  );

  return (
    <FormWrapper
      initialValues={PatientInitialValue}
      validationSchema={PatientSchemaValidation}
      onSubmit={savePatient}
    >
      <DialogTitle>Novo Paciente</DialogTitle>
      <DialogContent dividers sx={{ p: 3 }}>
        <PatientForm />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={drawerItem.close}>Cancelar</Button>
        <SubmitButton variant="contained">Salvar</SubmitButton>
      </DialogActions>
    </FormWrapper>
  );
};

export default PatientFormDrawer;
