import { Box, Paper } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DefaultHeader } from "../../components/default-header";
import FormWrapper, {
  SubmitButton,
} from "../../components/fields/form-wrapper";
import PatientForm, {
  PatientSchemaValidation,
} from "../../components/forms/patient-form";

import PageContent from "../../components/page-content";
import http from "../../services/http";

const PatientCreatePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const savePatient = useCallback(
    async (values: any) => {
      const { data, status } = await http.post("patients", values);
      if (status === 201) {
        if (searchParams.has("ar")) {
          navigate(searchParams.get("ar")!);
        } else {
          navigate("../", { state: { service: data } });
        }
      }
    },
    [navigate, searchParams]
  );
  return (
    <PageContent header={<DefaultHeader title="Novo Paciente" />}>
      <FormWrapper
        validationSchema={PatientSchemaValidation}
        initialValues={{}}
        onSubmit={savePatient}
      >
        <Paper sx={{ p: 3 }}>
          <PatientForm />
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <SubmitButton variant="contained">Criar</SubmitButton>
        </Box>
      </FormWrapper>
    </PageContent>
  );
};

export default PatientCreatePage;
