import { Box, CircularProgress, Paper } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DefaultHeader } from "../../components/default-header";
import FormWrapper, {
  SubmitButton,
} from "../../components/fields/form-wrapper";
import ServiceForm, {
  ServiceSchemaValidation,
} from "../../components/forms/service-form";
import PageContent from "../../components/page-content";
import { Service } from "../../models/service.model";
import http from "../../services/http";
import ServiceHeaderForm from "./form/header";

const ServiceUpdatePage: React.FC = () => {
  const snackbar = useSnackbar();
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [service, setService] = useState<Service>();
  const [loadingService, setLoadingService] = useState<boolean>(false);

  const fetchService = useCallback(async () => {
    setLoadingService(true);
    const { data, status } = await http.get<Service>(`services/${id}`);
    if (status === 200) {
      setService(data);
      setLoadingService(false);
    } else {
      navigate("../");
    }
  }, [id, navigate]);

  const saveService = useCallback(
    async (values: any) => {
      const { data, status } = await http.put(`services/${id}`, values);
      if (status === 200) {
        navigate("../", { state: { service: data } });
        snackbar.enqueueSnackbar("Serviço salvo com sucesso", {
          variant: "success",
        });
      } else {
        snackbar.enqueueSnackbar("Ocorreu um erro ao salvar o serviço", {
          variant: "error",
        });
      }
    },
    [id, navigate, snackbar]
  );

  useEffect(() => {
    fetchService();
  }, [fetchService]);

  if (!service) return null;

  return (
    <PageContent header={<DefaultHeader buttonToBack title={service.name} />}>
      {loadingService ? (
        <CircularProgress />
      ) : (
        <FormWrapper
          validationSchema={ServiceSchemaValidation}
          initialValues={{
            ...service,
            category: service.categories[0],
            units: service.units.map((unit) => unit.id),
          }}
          onSubmit={saveService}
        >
          {() => (
            <>
              <Paper>
                <ServiceHeaderForm />
                <Box p={3}>
                  <ServiceForm
                    sections={{
                      general: {
                        visible: !searchParams.get("t"),
                      },
                      report: {
                        visible: searchParams.get("t") === "report",
                      },
                      pricing: {
                        visible: searchParams.get("t") === "pricing",
                      },
                    }}
                    defaultUnits={
                      service?.units.map((unit) => ({
                        title: unit.name,
                        value: unit.id,
                        keywords: [
                          unit.name,
                          unit.document,
                          unit.fantasyName,
                          unit.businessName,
                        ].filter(Boolean),
                        data: unit,
                      })) ?? []
                    }
                  />
                </Box>
              </Paper>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
                <SubmitButton variant="contained">Atualizar</SubmitButton>
              </Box>
            </>
          )}
        </FormWrapper>
      )}
    </PageContent>
  );
};

export default ServiceUpdatePage;
