import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";

import { ExpandMore } from "@mui/icons-material";
import meditechLogo from "../../assets/images/logo-light.svg";

const sections = [
  {
    title: "Política de Privacidade",
    content:
      'Esta política de privacidade aplica-se ao aplicativo Meditech TV (doravante referido como "Aplicativo") para dispositivos móveis, criado pela Stackflow (doravante referido como "Provedor de Serviço") como um serviço gratuito. Este serviço é destinado ao uso "COMO ESTÁ".',
  },
  {
    title: "Coleta e Uso de Informações",
    content:
      "O Aplicativo coleta informações quando você faz o download e o utiliza. Essas informações podem incluir dados como o endereço IP do seu dispositivo, as páginas do Aplicativo que você visita, o horário e a data da visita, o tempo gasto nessas páginas, entre outras informações descritas na política.",
  },
  {
    title: "Acesso de Terceiros",
    content:
      "Somente dados agregados e anonimizados são periodicamente transmitidos para serviços externos para auxiliar o Provedor de Serviço a melhorar o Aplicativo e seu serviço. O Provedor de Serviço pode compartilhar suas informações com terceiros conforme descrito nesta declaração de privacidade.",
  },
  {
    title: "Direitos de Exclusão",
    content:
      "Você pode interromper toda a coleta de informações pelo Aplicativo desinstalando-o. Você pode usar os processos de desinstalação padrão, disponíveis no seu dispositivo móvel ou por meio da loja de aplicativos ou rede móvel.",
  },
  {
    title: "Política de Retenção de Dados",
    content:
      "O Provedor de Serviço manterá os dados fornecidos pelo usuário enquanto você utilizar o Aplicativo e por um tempo razoável após isso. Se desejar que excluam os dados fornecidos pelo usuário através do Aplicativo, entre em contato pelo e-mail contato@stackflow.com.br.",
  },
  {
    title: "Crianças",
    content:
      "O Provedor de Serviço não utiliza o Aplicativo para solicitar dados ou fazer marketing para crianças menores de 13 anos. O Aplicativo não é direcionado a pessoas com menos de 13 anos.",
  },
  {
    title: "Segurança",
    content:
      "O Provedor de Serviço se preocupa com a proteção da confidencialidade de suas informações. Adotam-se medidas de segurança físicas, eletrônicas e processuais para proteger as informações processadas e mantidas.",
  },
  {
    title: "Alterações",
    content:
      "Esta Política de Privacidade pode ser atualizada periodicamente por qualquer motivo. O Provedor de Serviço notificará você sobre quaisquer alterações, atualizando esta página com a nova Política de Privacidade.",
  },
  {
    title: "Seu Consentimento",
    content:
      "Ao usar o Aplicativo, você consente com o processamento de suas informações conforme estabelecido nesta Política de Privacidade, agora e conforme alterado por nós.",
  },
  {
    title: "Fale Conosco",
    content:
      "Se você tiver alguma dúvida sobre a privacidade ao usar o Aplicativo, entre em contato com o Provedor de Serviço via e-mail em contato@stackflow.com.br.",
  },
];

export function Privacy() {
  return (
    <Box sx={{ bgcolor: "grey.50", minHeight: "100vh" }}>
      <Container maxWidth="md" sx={{ p: 4 }}>
        <Box component="img" src={meditechLogo} sx={{ mb: 4 }} height={30} />

        {sections.map(({ title, content }, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textSecondary">
                {content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Box>
  );
}
