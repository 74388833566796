export interface DocumentType {
  label: string;
  value: string;
  format(value: string): string;
}

export const types: DocumentType[] = [
  {
    value: "cpf",
    label: "CPF",
    format(value: string) {
      return String(value)
        .replace(/[^0-9]+/g, "")
        .replace(/^(\d{3})(\d{3})(\d{3})(\d{1,2})$/g, "$1.$2.$3-$4");
    },
  },
  {
    value: "cnpj",
    label: "CNPJ",
    format(value: string) {
      return String(value)
        .replace(/[^0-9]+/g, "")
        .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})$/g, "$1.$2.$3/$4-$5");
    },
  },
  {
    value: "rg",
    label: "RG",
    format(value: string) {
      return String(value)
        .replace(/[^0-9]+/g, "")
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\d{1}|X|x)$/g, "$1.$2.$3-$4");
    },
  },
  {
    value: "cnh",
    label: "CNH",
    format(value: string) {
      return String(value).replace(/[^0-9]+/g, "");
    },
  },
  {
    value: "crm",
    label: "CRM",
    format(value: string) {
      return String(value).replace(/[^0-9]+/g, "");
    },
  },
];

export const getDocumentType = (type: string): DocumentType => {
  const documentType = types.find((t) => t.value === type) ?? {
    label: "Outros",
    value: "other",
    format(value: string) {
      return value;
    },
  };
  return documentType;
};
