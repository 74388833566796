import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@mui/material";
import { Field, FieldProps } from "formik";
import React, { forwardRef, ReactNode } from "react";

export type RadioGroupOption = {
  label: ReactNode;
  value: any;
};

interface RadioGroupFieldProps extends RadioGroupProps {
  name: string;
  label?: ReactNode;
  options: RadioGroupOption[];
  disabled?: boolean;
}

const RadioGroupField: React.ForwardRefRenderFunction<
  any,
  RadioGroupFieldProps
> = ({ name, label, options, disabled, ...props }, ref) => (
  <Field name={name}>
    {({ field, meta }: FieldProps) => (
      <FormControl error={!!meta.error}>
        {label && <FormLabel>{label}</FormLabel>}
        <RadioGroup
          row
          {...props}
          ref={ref}
          name={name}
          value={field.value ?? meta.initialValue ?? ""}
          onChange={(...args) => {
            field.onChange(args[0]);
            if (props.onChange) {
              props.onChange(...args);
            }
          }}
        >
          {options.map((option, key) => (
            <FormControlLabel
              key={key}
              value={option.value}
              control={
                <Radio
                  disabled={Boolean(disabled)}
                  color={meta.error ? "error" : undefined}
                />
              }
              label={option.label}
            />
          ))}
        </RadioGroup>
        <FormHelperText sx={{ mt: -1 }}>{meta.error}</FormHelperText>
      </FormControl>
    )}
  </Field>
);

export default forwardRef(RadioGroupField);
