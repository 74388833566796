import axios from "axios";

const spineHttp = axios.create({
  validateStatus: () => true,
  baseURL: `${process.env.REACT_APP_API_ENDPOINT ?? ""}/spine`,
  withCredentials: true,
});

const ribHttp = axios.create({
  validateStatus: () => true,
  baseURL: `${process.env.REACT_APP_API_ENDPOINT ?? ""}/ribs`,
  // withCredentials: true,
});

export const http = { ribs: ribHttp, spine: spineHttp };

export default spineHttp;
