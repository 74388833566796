import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Pagination as MuiPagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Edit, MoreVert } from "@mui/icons-material";

import SearchField from "../../components/fields/search-field";
import InfoLabel from "../../components/info-label";
import PageContent from "../../components/page-content";
import { Pagination } from "../../models/pagination.model";
import { Service } from "../../models/service.model";
import http from "../../services/http";

import { Patient } from "../../models/patient.model";
import { DefaultHeader } from "../../components/default-header";
import MenuPopover from "../../components/menu-context";
import { useDialog } from "../../hooks/dialog.hook";

const PatientListPage: React.FC = () => {
  const navigate = useNavigate();

  const dialog = useDialog();

  const [searchParams] = useSearchParams();
  const [loadingpatient, setLoadingPatients] = useState(false);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<Pagination<Service>["meta"]>();

  const fetchPatients = useCallback(
    async (searchQueryParams: URLSearchParams) => {
      setLoadingPatients(true);
      const { data, status } = await http.get<Pagination<Patient>>("patients", {
        params: {
          paginate: true,
          limit: 5,
          ...Object.fromEntries(searchQueryParams),
        },
      });
      setLoadingPatients(false);

      if (status === 200) {
        setPatients(data.items);
        setPaginationMeta(data.meta);
      }
    },
    []
  );

  useEffect(() => {
    fetchPatients(searchParams);
  }, [fetchPatients, searchParams]);

  return (
    <PageContent header={<DefaultHeader title="Pacientes" />}>
      <Paper>
        <SearchField
          sx={{ m: 2 }}
          queryParamName="search"
          fullWidth
          placeholder="Buscar por nome, documento ou responsável"
        />
        <Divider />
        <Table>
          <TableBody>
            {patients.map((patient) => (
              <TableRow key={patient.id}>
                <TableCell sx={{ width: "1%" }}>
                  <Avatar alt={patient.person.fullName}>
                    {patient.person.fullName
                      ?.split(/\s/)
                      .filter((_, i) => i < 2)
                      .map((word) => word?.[0])
                      .join("")}
                  </Avatar>
                </TableCell>
                <TableCell sx={{ width: "1%" }}>
                  <InfoLabel label="Prontuário" value={patient.code} />
                </TableCell>
                <TableCell>
                  <InfoLabel label="Nome" value={patient.person.fullName} />
                </TableCell>
                <TableCell>
                  <InfoLabel
                    label="Responsável"
                    value={patient?.responsible?.fullName}
                  />
                </TableCell>
                <TableCell>
                  <Grid container spacing={3}>
                    {(patient.person.documents ?? []).map((d) => (
                      <Grid item key={d.type + d.document}>
                        <InfoLabel
                          label={d.type.toLocaleUpperCase()}
                          value={d.document}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
                <TableCell sx={{ width: "1%" }}>
                  <Stack direction="row" spacing={1}>
                    <Tooltip title="Editar" placement="left">
                      <IconButton component={Link} to={`./${patient.id}`}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Opções" placement="left">
                      <Box>
                        <MenuPopover
                          button={
                            <IconButton>
                              <MoreVert />
                            </IconButton>
                          }
                          menu={[
                            {
                              children: "Novo Atendimento",
                              onClick() {
                                dialog.open({
                                  title: "Atualização do paciente",
                                  message:
                                    "Deseja atualizar os dados do paciente antes de criar um novo atendimento?",
                                  buttons: [
                                    {
                                      label: "Continuar sem atualizar",
                                      async callback() {
                                        navigate(
                                          `/orders/new?patient=${patient.id}`
                                        );
                                      },
                                    },
                                    {
                                      label: "Atualizar paciente",
                                      async callback() {
                                        navigate(
                                          `./${
                                            patient.id
                                          }?ar=${encodeURIComponent(
                                            `/orders/new?patient=${patient.id}`
                                          )}`
                                        );
                                      },
                                      options: {
                                        variant: "contained",
                                      },
                                    },
                                  ],
                                });
                              },
                            },
                          ]}
                        />
                      </Box>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Collapse in={!loadingpatient && patients.length === 0}>
          <Typography
            variant="body2"
            sx={{ p: 2, display: "block", textAlign: "center" }}
          >
            Nenhum paciente encontrado
          </Typography>
        </Collapse>
        <Collapse in={loadingpatient} sx={{ textAlign: "center" }}>
          <CircularProgress size={14} sx={{ my: 2 }} />
        </Collapse>
        <Divider />
        <Grid container sx={{ p: 2 }} justifyContent="space-between">
          <Grid item>
            {(paginationMeta?.totalPages ?? 0) > 1 && (
              <MuiPagination
                color="primary"
                page={paginationMeta?.currentPage}
                count={paginationMeta?.totalPages}
                onChange={(_, page) => {
                  searchParams.set("page", String(page));
                  navigate({
                    search: searchParams.toString(),
                  });
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Button component={Link} variant="contained" to="/patients/new">
              Novo Paciente
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </PageContent>
  );
};

export default PatientListPage;
