import {
  Box,
  Button,
  ButtonProps,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import React, { ComponentType, useMemo, useState } from "react";
import { Link, To, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/auth.hook";
import { AdminRole } from "../models/account.model";
import { menu } from "../structs/menu";

interface NavItemProps {
  label: string;
  icon?: ComponentType<any>;
  // eslint-disable-next-line react/no-unused-prop-types
  roles?: AdminRole[];
  // eslint-disable-next-line react/no-unused-prop-types
  mainRole?: "super" | "default";
}

type NavItemWithChildren = NavItemProps & {
  children?: MenuNavItem[];
};

type NavItemWithLink = NavItemProps & {
  to?: To;
};

export type MenuNavItem = NavItemWithChildren & NavItemWithLink;

export const ButtonNav: React.FC<NavItemProps & ButtonProps> = ({
  icon: Icon,
  label,
  ...props
}) => {
  return (
    <Button
      {...props}
      sx={{
        color: "secondary.main",
        flexDirection: "column",
        width: 80,
        height: 80,
        ...props.sx,
      }}
    >
      {!!Icon && <Icon fontSize="small" />}
      <Typography
        sx={{
          mt: 1,
          color: "white",
          fontSize: 9,
          textTransform: "uppercase",
          textAlign: "center",
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

const NavItem: React.FC<MenuNavItem> = ({
  label,
  icon: Icon,
  children,
  to,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    if (children && children.length > 0) {
      setAnchorEl(event.currentTarget);
    } else {
      navigate(to as To, {
        replace: true,
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "menu-popover" : undefined;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: { xs: "row", md: "column" },
        }}
      >
        <ButtonNav
          onClick={(event) => handleClick(event)}
          icon={Icon}
          label={label}
        />
      </Box>
      {children && children?.length > 0 && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: 112 - 5,
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <List
            sx={{
              minWidth: 250,
              borderLeft: (theme) =>
                `5px solid ${theme.palette.secondary.main}`,
            }}
          >
            {children.map(
              (
                { icon: IconChildren, label: labelChildren, to: toChildren },
                key
              ) => (
                <ListItemButton
                  key={key}
                  component={Link as any}
                  to={toChildren}
                >
                  {!!IconChildren && (
                    <ListItemIcon>
                      <IconChildren fontSize="small" />
                    </ListItemIcon>
                  )}
                  <ListItemText>{labelChildren}</ListItemText>
                </ListItemButton>
              )
            )}
          </List>
        </Popover>
      )}
    </>
  );
};

const Menu: React.FC = () => {
  const auth = useAuth();
  const menuList = useMemo(() => {
    const authRoles = auth.user?.roles.map((role) => role.role) ?? [];

    const filterRoles = (m: MenuNavItem[]): MenuNavItem[] => {
      return m
        .map((item): any => {
          if (item.children && item.children.length > 0) {
            const children = filterRoles(item.children);
            if (children.length > 0) {
              return {
                ...item,
                children,
              };
            }
          }

          if (
            item.mainRole === auth.user?.mainRole ||
            (item.roles && item.roles.length > 0)
          ) {
            let itemAllow = auth.user?.mainRole === "super";
            if (item.roles && item.roles.length > 0) {
              itemAllow = item.roles.reduce(
                (allow: boolean, rls: AdminRole) => {
                  if (!allow) {
                    return !!authRoles?.includes(rls);
                  }
                  return allow;
                },
                false
              );
            }

            if (itemAllow) return item;
          }

          return false;
        })
        .filter(Boolean);
    };
    return filterRoles(menu).filter(Boolean);
  }, [auth.user?.roles, auth.user?.mainRole]);

  return (
    <Box>
      {menuList.map((item, key) => (
        <NavItem key={key} {...item} />
      ))}
    </Box>
  );
};

export default React.memo(Menu);
