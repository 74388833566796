import React from "react";
import {
  Box,
  Button,
  Collapse,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import PageContent from "../../components/page-content";
import { DefaultHeader } from "../../components/default-header";
import { useAuth } from "../../hooks/auth.hook";
import InfoLabel from "../../components/info-label";

const SettingPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useAuth();
  return (
    <PageContent header={<DefaultHeader title="Configurações" />}>
      <Paper sx={{ overflow: "hidden" }}>
        <Tabs
          value={!searchParams.has("t") ? "business" : searchParams.get("t")}
          onChange={(_, value) => {
            if (value === "business") {
              searchParams.delete("t");
            } else {
              searchParams.set("t", value);
            }
            setSearchParams(searchParams);
          }}
          sx={{ ".MuiTab-root": { alignItems: "end" } }}
        >
          <Tab label="Dados da Empresa" value="business" />
          <Tab label="Assinatura" value="subscription" />
        </Tabs>
        <Box sx={{ p: 3, bgcolor: "grey.100" }}>
          <Collapse
            in={!searchParams.has("t") || searchParams.get("t") === "business"}
          >
            <Grid container>
              <Grid item md={6}>
                <TextField
                  label="Razão Social"
                  value={auth.workspace?.company.businessName}
                  disabled
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Nome Fantasia"
                  value={auth.workspace?.company.fantasyName}
                  disabled
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item md={5}>
                <TextField
                  label="CNPJ"
                  value={auth.workspace?.company.document}
                  disabled
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item md={7}>
                <TextField
                  label="Atividade Principal"
                  value={auth.workspace?.company.mainActivity ?? "-"}
                  disabled
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={searchParams.get("t") === "subscription"}>
            <Stack spacing={2}>
              <Paper
                variant="outlined"
                sx={(theme) => ({
                  p: 1,
                  borderLeft: `5px solid ${theme.palette.success.main}`,
                })}
              >
                <Grid container spacing={0}>
                  <Grid item md={6}>
                    <InfoLabel label="Plano" value="Prata" />
                  </Grid>
                  <Grid item md={3}>
                    <InfoLabel label="Renovação" value="10/05/2023" />
                  </Grid>
                  <Grid item md={3}>
                    <InfoLabel label="Assinatura" value="Ativa" />
                  </Grid>
                </Grid>
              </Paper>
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                DADOS DE PAGAMENTO
              </Typography>
              <Paper sx={{ p: 1 }} variant="outlined">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <InfoLabel label="Cartão" value="**** **** **** 1234" />
                  <Button>Alterar</Button>
                </Stack>
              </Paper>
            </Stack>
          </Collapse>
        </Box>
      </Paper>
    </PageContent>
  );
};

export default SettingPage;
