import { Box, Chip, Paper } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageContent from "../../components/page-content";
import http from "../../services/http";

import { CompanyUnit } from "../../models/company-unit.model";
import UnitForm, {
  UnitSchemaValidation,
} from "../../components/forms/unit-form";
import FormWrapper, {
  SubmitButton,
} from "../../components/fields/form-wrapper";
import { DefaultHeader } from "../../components/default-header";

const UnitUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [unit, setUnit] = useState<CompanyUnit>();

  const fetchUnit = useCallback(async () => {
    const { data, status } = await http.get<CompanyUnit>(`units/${id}`);

    if (status === 200) {
      setUnit(data);
    }
  }, [id]);

  const updateService = useCallback(
    async (values: any) => {
      const { status } = await http.put(`units/${id}`, values);

      if (status === 200) {
        navigate("../");
      }
    },
    [id, navigate]
  );

  useEffect(() => {
    fetchUnit();
  }, [fetchUnit, searchParams]);

  if (!unit) return null;

  return (
    <PageContent
      header={
        <DefaultHeader
          title={
            <>
              {unit.name}{" "}
              {unit.main && <Chip label="Matriz" variant="outlined" />}
            </>
          }
        />
      }
    >
      <FormWrapper
        validationSchema={UnitSchemaValidation}
        initialValues={unit}
        onSubmit={updateService}
      >
        <Paper sx={{ p: 3 }}>
          <UnitForm />
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <SubmitButton variant="contained">Atualizar</SubmitButton>
        </Box>
      </FormWrapper>
    </PageContent>
  );
};

export default UnitUpdatePage;
