import React, { useCallback } from "react";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { orderBy } from "lodash";
import { DefaultHeader } from "../default-header";
import { useItemDrawer } from "../../hooks/drawer.hook";
import { Order } from "../../models/order.model";
import FormWrapper, { SubmitButton, Validator } from "../fields/form-wrapper";
import TextField from "../fields/text-field";
import http from "../../services/http";

interface OrderLogsViewerProps {
  order: Order;
}

const createLogValidationSchema = Validator.object().shape({
  message: Validator.string().required().label("Observação"),
});

const OrderLogsViewer: React.FC<OrderLogsViewerProps> = ({ order }) => {
  const itemDrawer = useItemDrawer();

  const createLog = useCallback(
    async (values: any) => {
      const { status, data } = await http.post(`orders/${order.id}/logs`, {
        message: values.message,
        type: "attendance",
      });

      if (status === 201) {
        itemDrawer.close(data);
      }
    },
    [order.id, itemDrawer]
  );

  return (
    <Stack
      sx={{
        bgcolor: (theme) => theme.palette.grey[200],
        position: "relative",
        height: 1,
      }}
    >
      <Paper sx={{ p: 2 }} square>
        <DefaultHeader
          title={`${order.patient.person.fullName}`}
          gutterBottom={false}
        >
          <Stack spacing={1} direction="row">
            <Button onClick={() => itemDrawer.close()}>Fechar</Button>
          </Stack>
        </DefaultHeader>
      </Paper>
      <Stack
        p={3}
        spacing={2}
        sx={{ flexGrow: 1, overflow: "auto", height: 1 }}
      >
        {orderBy(order.logs ?? [], "createdAt", "asc").map((log, key) => (
          <Paper
            component={Stack}
            key={key}
            sx={(theme) => ({
              p: 2,
              borderLeft: `3px solid ${theme.palette.primary.main}`,
            })}
            spacing={2}
            elevation={2}
          >
            <Typography>{log.message}</Typography>
            <Typography variant="caption">
              {format(
                new Date(log.createdAt),
                "'criado em' dd/MM/yyyy 'ás' HH:mm"
              )}
            </Typography>
          </Paper>
        ))}
      </Stack>
      <FormWrapper
        onSubmit={createLog}
        initialValues={{ message: null }}
        validationSchema={createLogValidationSchema}
      >
        <Paper square component={Stack} direction="row" sx={{ p: 2 }}>
          <TextField
            name="message"
            label="Observações"
            InputProps={{ disableUnderline: true }}
          />
          <SubmitButton variant="contained">Adicionar</SubmitButton>
        </Paper>
      </FormWrapper>
    </Stack>
  );
};

export default OrderLogsViewer;
