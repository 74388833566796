import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { Field, FieldProps } from "formik";
import React, { forwardRef } from "react";
import { IMaskInput } from "react-imask";

type TextFieldProps = MuiTextFieldProps & {
  name: string;
  mask?: string;
};

interface TextMaskFieldProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  mask: string;
  name: string;
}

const TextMaskField = React.forwardRef<HTMLElement, TextMaskFieldProps>(
  (props, ref) => {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        unmask
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref as any}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

const TextField: React.ForwardRefRenderFunction<any, TextFieldProps> = (
  { name, label, mask, ...props },
  ref
) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        return (
          <MuiTextField
            {...props}
            name={name}
            value={field.value ?? ""}
            onBlur={field.onBlur}
            onChange={(event) => {
              field.onChange(event as any);
              if (props.onChange) {
                props.onChange(event);
              }
            }}
            required={!!meta.error ?? !!meta.initialError}
            InputProps={{
              ...props.InputProps,
              inputRef: ref,
              inputProps: { mask, ...props.inputProps },
              ...(mask ? { inputComponent: TextMaskField as any } : {}),
            }}
            label={label}
            error={!!meta.error ?? !!meta.initialError}
            helperText={meta.error}
          />
        );
      }}
    </Field>
  );
  // return (
  //   <Field name={name}>
  //     {({ field, meta }: FieldProps) => (
  //       <MuiTextField
  //         {...props}
  //         label={label}
  //         ref={ref}
  //         name={field.name}
  // value={field.value || ""}
  // onChange={(event) => {
  //   field.onChange(event as any);
  //   if (props.onChange) {
  //     props.onChange(event);
  //   }
  // }}
  //         onBlur={field.onBlur}
  //         onFocus={field.onBlur}
  // error={!!meta.error ?? !!meta.initialError}
  // helperText={meta.error}
  //       />
  //     )}
  //   </Field>
  // );
};
export default forwardRef(TextField);
