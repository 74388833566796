import React, { useCallback, useEffect, useRef, useState } from "react";
import { generateKeywords } from "../../helpers/service";
import { Service } from "../../models/service.model";
import http from "../../services/http";
import AutocompleteField, {
  AutoCompleteComponentRef,
  AutoCompleteFieldOption,
  AutoCompleteFieldProps,
} from "../fields/autocomplete-field";

type ServiceAutoCompleteFieldProps = AutoCompleteFieldProps & {
  excludes?: string[];
};

const ServiceAutoCompleteField: React.FC<ServiceAutoCompleteFieldProps> = ({
  excludes = [],
  ...props
}) => {
  const serviceInputRef = useRef<AutoCompleteComponentRef>();

  const [serviceOptions, setServiceOptions] = useState<
    AutoCompleteFieldOption<Service>[]
  >([]);

  const fetchServices = useCallback(async () => {
    const { data, status } = await http.get<Service[]>("services", {
      params: {
        paginate: false,
        status: "active",
      },
    });

    if (status === 200) {
      setServiceOptions(
        data.map((service: Service) => ({
          title: service.name.toString(),
          value: service.id,
          keywords: generateKeywords(service),
          data: service,
        }))
      );
    }
  }, []);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  return (
    <AutocompleteField
      label="Serviço"
      defaultOptions={serviceOptions.filter((s) => !excludes.includes(s.value))}
      {...props}
      ref={serviceInputRef}
    />
  );
};

export default ServiceAutoCompleteField;
