import { status, UnitStatus } from "../structs/unit";

export const getUnitStatus = (value: string): UnitStatus => {
  return (
    status.find((s) => s.value === value) ?? {
      label: value,
      value,
    }
  );
};
