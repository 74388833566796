import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { Search } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import PageContent from "../../components/page-content";
import { DefaultHeader } from "../../components/default-header";
import FormWrapper, {
  SubmitButton,
} from "../../components/fields/form-wrapper";
import DateTimeField from "../../components/fields/date-time-field";
import { Invoice } from "../../models/invoice.model";
import http from "../../services/http";
import SearchField from "../../components/fields/search-field";
import InfoLabel from "../../components/info-label";
import { formatNumber } from "../../helpers/currency";
import { Transaction } from "../../models/transaction.model";

type CheckType = {
  name: boolean;
  code: boolean;
  partnerAgreement: boolean;
  value: boolean;
  services: boolean;
};

const ConciliationPage: React.FC = () => {
  const snackbar = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const [checkedTransactions, setCheckedTransactions] = useState<{
    [key: string]: CheckType;
  }>({});

  const [loadingsAuthorize, setLoadingAuthorize] = useState<string[]>([]);

  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const checkTransaction = useCallback(
    (transaction: Transaction, type: keyof CheckType, value: boolean) => {
      setCheckedTransactions((o) => {
        return {
          ...o,
          [transaction.id]: {
            ...o[transaction.id],
            [type]: value,
          },
        };
      });
    },
    []
  );

  const fetchInvoices = useCallback(async () => {
    const { status, data } = await http.get<Invoice[]>("invoices", {
      params: {
        startPeriod: searchParams.get("startPeriod"),
        endPeriod: searchParams.get("endPeriod"),
        modality: searchParams.get("type"),
        status: "pending",
        ...(searchParams.has("search")
          ? {
              search: searchParams.get("search"),
            }
          : {}),
      },
    });

    if (status === 200) {
      setTransactions(
        data.reduce<any[]>((t, { transactions: ttt, ...invoice }) => {
          return [
            ...t,
            ...ttt.map((tt) => ({
              ...tt,
              invoice,
            })),
          ];
        }, [])
      );
    }
  }, [searchParams]);

  const authorizeTransaction = useCallback(
    async (transaction: Transaction) => {
      setLoadingAuthorize((o) => [...o, transaction.id]);

      const { status, data } = await http.put(
        `transactions/${transaction.id}`,
        {
          status: "concluded",
        }
      );

      if (status === 200) {
        setLoadingAuthorize((o) => [
          ...o.filter((oo) => oo !== transaction.id),
        ]);

        snackbar.enqueueSnackbar(`Conciliação efetuada com sucesso`, {
          variant: "success",
        });
      } else {
        snackbar.enqueueSnackbar(`Ocorreu um erro ao realizar a conciliação`, {
          variant: "error",
        });
      }
      setTransactions((t) =>
        [...t].map((tt) => ({
          ...tt,
          ...(tt.id === transaction.id ? { ...data } : {}),
        }))
      );
    },
    [snackbar]
  );

  const recuseTransaction = useCallback(
    async (transaction: Transaction) => {
      setLoadingAuthorize((o) => [...o, transaction.id]);

      const { status, data } = await http.put(
        `transactions/${transaction.id}`,
        {
          status: "canceled",
        }
      );

      if (status === 200) {
        setLoadingAuthorize((o) => [
          ...o.filter((oo) => oo !== transaction.id),
        ]);

        snackbar.enqueueSnackbar(`Conciliação recusada com sucesso`, {
          variant: "success",
        });
      } else {
        snackbar.enqueueSnackbar(`Ocorreu um erro ao realizar a conciliação`, {
          variant: "warning",
        });
      }
      setTransactions((t) =>
        [...t].map((tt) => ({
          ...tt,
          ...(tt.id === transaction.id ? { ...data } : {}),
        }))
      );
    },
    [snackbar]
  );

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  return (
    <PageContent overlayBar header={<DefaultHeader title="Conciliação" />}>
      <Paper>
        <Tabs value={searchParams.get("type")}>
          <Tab label="Convênios" value="healthInsurance" />
          {/* <Tab label="Dinheiro" value="money" /> */}
        </Tabs>
        <Collapse
          in={
            searchParams.get("type") === "healthInsurance" ||
            !searchParams.has("type")
          }
        >
          <Box
            sx={{
              position: "sticky",
              top: 0,
              backdropFilter: "blur(10px)",
              zIndex: 2,
            }}
          >
            <Stack sx={{ p: 2 }}>
              <FormWrapper
                initialValues={{
                  startPeriod: searchParams.get("startPeriod"),
                  endPeriod: searchParams.get("endPeriod"),
                }}
                onSubmit={(values) => {
                  searchParams.set(
                    "startPeriod",
                    moment(values.startPeriod).format("YYYY-MM-DD")
                  );
                  searchParams.set(
                    "endPeriod",
                    moment(values.endPeriod).format("YYYY-MM-DD")
                  );
                  setSearchParams(searchParams);
                }}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <DateTimeField
                    label="Início do período"
                    name="startPeriod"
                    type="date"
                  />
                  <DateTimeField
                    label="Fim do período"
                    name="endPeriod"
                    type="date"
                  />
                  <SubmitButton
                    startIcon={<Search />}
                    sx={{ alignSelf: "stretch", flexShrink: 0 }}
                    variant="contained"
                  >
                    Buscar
                  </SubmitButton>
                </Stack>
              </FormWrapper>
            </Stack>
            <Divider />
            <SearchField
              sx={{ m: 2 }}
              queryParamName="search"
              fullWidth
              placeholder="Buscar por nome ou código do paciente"
            />

            <Divider />
          </Box>
          <Collapse in={transactions.length === 0} unmountOnExit>
            <Typography sx={{ p: 2, color: "grey.500", textAlign: "center" }}>
              Nenhuma conciliação disponível
            </Typography>
          </Collapse>
          <Collapse in={transactions.length > 0} unmountOnExit>
            <Stack divider={<Divider flexItem />}>
              {transactions.map((transaction, index) => (
                <Stack
                  key={transaction.id}
                  sx={{ p: 2, bgcolor: index % 2 ? "grey.100" : undefined }}
                  spacing={2}
                >
                  <Stack direction="row" justifyContent="space-between">
                    <Typography sx={{ fontWeight: "bold" }}>
                      #{transaction.invoice.code}
                    </Typography>
                    <Chip
                      color={
                        ["canceled", "concluded"].includes(transaction.status)
                          ? "success"
                          : "warning"
                      }
                      size="small"
                      label={
                        ["canceled", "concluded"].includes(transaction.status)
                          ? "Finalizado"
                          : transaction.status === "pending"
                          ? "Pendente"
                          : "Inconsistênte"
                      }
                    />
                  </Stack>
                  <Collapse in={transaction.status === "pending"} unmountOnExit>
                    <Stack spacing={2}>
                      <Box sx={{ flex: 1 }}>
                        <Grid
                          container
                          key={transaction.id}
                          justifyContent="space-between"
                        >
                          <Grid item md={5}>
                            <InfoLabel
                              label="Nome do Paciente"
                              value={
                                <FormControlLabel
                                  name={`check-name-${transaction.id}`}
                                  label={
                                    transaction.invoice.responsible.fullName
                                  }
                                  sx={{ fontSize: 12, fontWeight: "bold" }}
                                  control={<Checkbox />}
                                  checked={
                                    checkedTransactions[transaction.id]?.name ??
                                    false
                                  }
                                  onChange={(_, checked) => {
                                    checkTransaction(
                                      transaction,
                                      "name",
                                      checked
                                    );
                                  }}
                                />
                              }
                            />
                            <InfoLabel
                              label="Convênio"
                              value={
                                <FormControlLabel
                                  name={`check-partnerAgreement-${transaction.id}`}
                                  label={
                                    transaction.transactionPartnerAgreement
                                      ?.partnerAgreement.description ?? "-"
                                  }
                                  sx={{ fontSize: 12, fontWeight: "bold" }}
                                  control={<Checkbox />}
                                  checked={
                                    checkedTransactions[transaction.id]
                                      ?.partnerAgreement ?? false
                                  }
                                  onChange={(_, checked) => {
                                    checkTransaction(
                                      transaction,
                                      "partnerAgreement",
                                      checked
                                    );
                                  }}
                                />
                              }
                              valueProps={{ textTransform: "uppercase" }}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <InfoLabel
                              label="Nº da Carteirinha / Voucher"
                              value={
                                <FormControlLabel
                                  name={`check-code-${transaction.id}`}
                                  label={
                                    transaction.transactionPartnerAgreement
                                      ?.code ?? "-"
                                  }
                                  sx={{ fontSize: 12, fontWeight: "bold" }}
                                  control={<Checkbox />}
                                  checked={
                                    checkedTransactions[transaction.id]?.code ??
                                    false
                                  }
                                  onChange={(_, checked) => {
                                    checkTransaction(
                                      transaction,
                                      "code",
                                      checked
                                    );
                                  }}
                                />
                              }
                              valueProps={{ textTransform: "uppercase" }}
                            />
                            <InfoLabel
                              label="Valor"
                              value={
                                <FormControlLabel
                                  name={`check-value-${transaction.id}`}
                                  label={formatNumber(transaction.value)}
                                  sx={{ fontSize: 12, fontWeight: "bold" }}
                                  control={<Checkbox />}
                                  checked={
                                    checkedTransactions[transaction.id]
                                      ?.value ?? false
                                  }
                                  onChange={(_, checked) => {
                                    checkTransaction(
                                      transaction,
                                      "value",
                                      checked
                                    );
                                  }}
                                />
                              }
                            />
                          </Grid>
                        </Grid>
                        <Paper
                          variant="outlined"
                          sx={{ px: 1, pt: 0.5, mt: 1 }}
                        >
                          <InfoLabel
                            label="Serviços"
                            value={
                              <FormControlLabel
                                name={`check-services-${transaction.id}`}
                                label={transaction.invoice?.orderItems
                                  ?.map((o) => o.order.service.name)
                                  .join(", ")}
                                sx={{ fontSize: 12, fontWeight: "bold" }}
                                control={<Checkbox />}
                                checked={
                                  checkedTransactions[transaction.id]
                                    ?.services ?? false
                                }
                                onChange={(_, checked) => {
                                  checkTransaction(
                                    transaction,
                                    "services",
                                    checked
                                  );
                                }}
                              />
                            }
                          />
                        </Paper>
                      </Box>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ justifyContent: "end" }}
                      >
                        <Button
                          disabled={loadingsAuthorize.includes(transaction.id)}
                          color="error"
                          onClick={() => {
                            recuseTransaction(transaction);
                          }}
                        >
                          Recusar
                        </Button>
                        <Button
                          sx={{ whiteSpace: "pre-wrap" }}
                          variant="contained"
                          disabled={loadingsAuthorize.includes(transaction.id)}
                          onClick={() => {
                            let prop: keyof CheckType | null = null;
                            if (!checkedTransactions[transaction.id]?.name) {
                              prop = "name";
                            } else if (
                              !checkedTransactions[transaction.id]?.value
                            ) {
                              prop = "value";
                            } else if (
                              !checkedTransactions[transaction.id]?.code
                            ) {
                              prop = "code";
                            } else if (
                              !checkedTransactions[transaction.id]
                                ?.partnerAgreement
                            ) {
                              prop = "partnerAgreement";
                            } else if (
                              !checkedTransactions[transaction.id]?.services
                            ) {
                              prop = "services";
                            } else {
                              authorizeTransaction(transaction);
                            }
                            if (prop) {
                              checkTransaction(transaction, prop, true);
                            }
                          }}
                        >
                          {!checkedTransactions[transaction.id]?.name
                            ? "Confirmar nome"
                            : !checkedTransactions[transaction.id]?.value
                            ? "Confirmar valor"
                            : !checkedTransactions[transaction.id]?.code
                            ? "Confirmar Nº da Carteirinha / Voucher"
                            : !checkedTransactions[transaction.id]
                                ?.partnerAgreement
                            ? "Confirmar Convênio"
                            : !checkedTransactions[transaction.id]?.services
                            ? "Confirmar Serviços"
                            : "Autorizar"}
                        </Button>
                      </Stack>
                    </Stack>
                  </Collapse>
                </Stack>
              ))}
            </Stack>
          </Collapse>
        </Collapse>
      </Paper>
    </PageContent>
  );
};

export default ConciliationPage;
