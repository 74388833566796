import { CircularProgress, Paper, Stack, Typography } from "@mui/material";
import React, { ComponentType, ReactNode, useMemo } from "react";
import { formatNumber } from "../helpers/currency";

interface InforCardProps {
  title: ReactNode;
  icon: ComponentType<any>;
  value: string | number;
  isCurrency?: boolean;
  loading?: boolean;
}

const InfoCard: React.FC<InforCardProps> = ({
  title,
  icon: Icon,
  value,
  loading,
  isCurrency = false,
}) => {
  const infoValue = useMemo(() => {
    if (isCurrency) {
      return formatNumber(+value);
    }
    return value;
  }, [value, isCurrency]);

  return (
    <Paper
      elevation={2}
      sx={{
        p: 3,
        flex: 1,
        borderBottom: (theme) => `5px solid ${theme.palette.primary.main}`,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack spacing={2} direction="column">
          <Typography
            component="span"
            sx={{
              fontSize: 11,
              textTransform: "uppercase",
              color: (theme) => theme.palette.grey[600],
            }}
          >
            {title}
          </Typography>
          <Typography sx={{ fontSize: 30, fontWeight: "bold", lineHeight: 1 }}>
            {!loading ? infoValue : <CircularProgress size={20} />}
          </Typography>
        </Stack>
        <Icon color="primary" sx={{ fontSize: 30 }} />
      </Stack>
    </Paper>
  );
};

export default InfoCard;
