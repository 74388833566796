import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Pagination as MuiPagination,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import SearchField from "../../components/fields/search-field";
import InfoLabel from "../../components/info-label";
import PageContent from "../../components/page-content";
import { Pagination } from "../../models/pagination.model";
import { Professional } from "../../models/professional.model";
import { Service } from "../../models/service.model";
import http from "../../services/http";

import { getRole } from "../../helpers/role";
import { Role, roles } from "../../structs/roles";
import { getProfessionalStatus } from "../../helpers/professional";
import { DefaultHeader } from "../../components/default-header";

const ProfessionalListPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loadingProfessional, setLoadingProfessional] = useState(false);
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<Pagination<Service>["meta"]>();

  const fetchProfessionals = useCallback(
    async (searchQueryParams: URLSearchParams) => {
      setLoadingProfessional(true);
      const { data, status } = await http.get<Pagination<Professional>>(
        "professionals",
        {
          params: {
            paginate: true,
            ...Object.fromEntries(searchQueryParams),
          },
        }
      );
      setLoadingProfessional(false);

      if (status === 200) {
        setProfessionals(data.items);
        setPaginationMeta(data.meta);
      }
    },
    []
  );

  const onTabChange = useCallback(
    (_: any, value: string) => {
      if (value === "all") {
        searchParams.delete("role");
      } else {
        searchParams.set("role", value);
      }
      searchParams.delete("page");
      navigate({ search: searchParams.toString() }, { replace: true });
    },
    [navigate, searchParams]
  );

  useEffect(() => {
    fetchProfessionals(searchParams);
  }, [fetchProfessionals, searchParams]);

  return (
    <PageContent header={<DefaultHeader title="Profissionais" />}>
      <Paper>
        <Tabs
          value={searchParams.get("role") ?? "all"}
          onChange={onTabChange}
          indicatorColor="primary"
          textColor="inherit"
          scrollButtons="auto"
          sx={{
            borderTopLeftRadius: (theme) => theme.shape.borderRadius,
            borderTopRightRadius: (theme) => theme.shape.borderRadius,
          }}
        >
          <Tab label="Todos" value="all" />
          {roles?.map((r: Role) => (
            <Tab
              sx={{ whiteSpace: "nowrap" }}
              label={`${r.label.male}${
                r.label.female !== r.label.male ? "(a)" : ""
              }`}
              value={r.value}
              key={r.value}
            />
          ))}
        </Tabs>
        <SearchField
          sx={{ m: 2 }}
          queryParamName="search"
          fullWidth
          placeholder="Buscar por nome, documento, nome de usuário ou papel"
        />
        <Divider />
        <Table>
          <TableBody>
            {professionals.map((professional) => (
              <TableRow key={professional.id}>
                <TableCell sx={{ width: "1%" }}>
                  <Avatar alt={professional.person.fullName} />
                </TableCell>
                <TableCell>
                  <InfoLabel
                    label="Nome"
                    value={professional.person.fullName}
                  />
                </TableCell>
                <TableCell>
                  <InfoLabel
                    label="Papel"
                    value={professional.account.roles
                      .map(
                        (r) => getRole(r.role).label[professional.person.gender]
                      )
                      .join(", ")}
                  />
                </TableCell>
                <TableCell>
                  <Grid container spacing={1}>
                    {(professional.person.documents ?? []).map((d) => (
                      <Grid item key={d.type + d.document}>
                        <InfoLabel
                          label={d.type.toLocaleUpperCase()}
                          value={d.document}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
                <TableCell>
                  <InfoLabel
                    label="Usuário"
                    value={professional.account.username}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "center", width: "0%" }}>
                  <Chip
                    label={getProfessionalStatus(
                      professional.status
                    ).label.toLocaleUpperCase()}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Collapse in={!loadingProfessional && professionals.length === 0}>
          <Typography
            variant="body2"
            sx={{ p: 2, display: "block", textAlign: "center" }}
          >
            Nenhum profissional encontrado
          </Typography>
        </Collapse>
        <Collapse in={loadingProfessional} sx={{ textAlign: "center" }}>
          <CircularProgress size={14} sx={{ my: 2 }} />
        </Collapse>
        <Divider />
        <Grid container sx={{ p: 2 }} justifyContent="space-between">
          <Grid item>
            {(paginationMeta?.totalPages ?? 0) > 1 && (
              <MuiPagination
                color="primary"
                page={paginationMeta?.currentPage}
                count={paginationMeta?.totalPages}
                onChange={(_, page) => {
                  searchParams.set("page", String(page));
                  navigate({
                    search: searchParams.toString(),
                  });
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Button
              component={Link}
              variant="contained"
              to="/professionals/new"
            >
              Novo Profissional
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </PageContent>
  );
};

export default ProfessionalListPage;
