import { Menu, MenuItem, MenuItemProps, MenuProps } from "@mui/material";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import React, { cloneElement, ReactNode } from "react";

interface IMenuPopover {
  id?: string;
  button: ReactNode;
  menu: MenuItemProps[];
  menuProps?: Partial<MenuProps>;
}

const MenuPopover: React.FC<IMenuPopover> = ({
  id,
  button,
  menu,
  menuProps,
}) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: id ?? "menu-popover",
  });

  return (
    <>
      {cloneElement(button as any, { ...bindTrigger(popupState) })}
      <Menu {...bindMenu(popupState)} {...menuProps}>
        {menu.map((item, key) => (
          <MenuItem {...item} key={key} />
        ))}
      </Menu>
    </>
  );
};

export default MenuPopover;
