import React, { useCallback, useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { DefaultHeader } from "../../components/default-header";
import PageContent from "../../components/page-content";
import FormWrapper, {
  SubmitButton,
} from "../../components/fields/form-wrapper";
import http from "../../services/http";
import { EfiConfiguration } from "../../models/efi-configuration.model";
import EfiConfigurationForm, {
  EfiConfigurationSchemaValidation,
} from "../../components/forms/efi-configuration-form";
import { useAuth } from "../../hooks/auth.hook";

const EfiIntegrationPage: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const snackbar = useSnackbar();

  const [efiConfiguration, setEfiConfiguration] = useState<
    EfiConfiguration["config"] | null
  >();

  const fetchEfiConfiguration = useCallback(async () => {
    const { status, data } = await http.get("integration/efi");

    if (status === 200) {
      setEfiConfiguration(data.config);
    } else {
      setEfiConfiguration(null);
    }
  }, []);

  const saveConfiguration = useCallback(
    async (values: any) => {
      const formValues = values;

      if (formValues.certificatePath instanceof File) {
        const formData = new FormData();
        formData.append("file", formValues.certificatePath);
        formData.append("path", "efi/certs");
        formData.append("filename", `cert-${auth.workspace?.company.id}.p12`);

        const { status, data } = await http.post("storage", formData);
        if (status !== 200) {
          snackbar.enqueueSnackbar("Não foi possível carregar o certificado", {
            variant: "error",
          });
          return;
        }
        formValues.certificatePath = data.path;
      }

      const { status, data } = await http.post("integration/efi", values);

      if (status !== 200) {
        snackbar.enqueueSnackbar("Não foi possível salvar as configurações", {
          variant: "error",
        });
        return;
      }
      navigate("..");
      snackbar.enqueueSnackbar("Efi Pagamentos configurado com sucesso", {
        variant: "success",
      });
      setEfiConfiguration(data.config);
    },
    [auth.workspace?.company.id, navigate, snackbar]
  );

  useEffect(() => {
    fetchEfiConfiguration();
  }, [fetchEfiConfiguration]);

  if (efiConfiguration === undefined) return null;

  return (
    <PageContent
      header={
        <DefaultHeader
          typographyProps={{ color: (theme) => theme.palette.primary.main }}
          title="Efí Pagamentos"
        />
      }
    >
      <FormWrapper
        validationSchema={EfiConfigurationSchemaValidation}
        initialValues={{
          clientId: efiConfiguration?.clientId,
          clientSecret: efiConfiguration?.clientSecret,
          certificatePath: efiConfiguration?.certificatePath,
          key: efiConfiguration?.key,
        }}
        onSubmit={saveConfiguration}
      >
        <Paper>
          <EfiConfigurationForm />
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <SubmitButton variant="contained">Salvar</SubmitButton>
        </Box>
      </FormWrapper>
    </PageContent>
  );
};

export default EfiIntegrationPage;
