import React from "react";
import { Box, Stack } from "@mui/material";
import Userbar from "./user-bar";

const Header: React.FC = () => {
  return (
    <Box sx={{ py: 1, pr: 1, pl: { xs: 1, md: 0 } }}>
      <Stack direction="row" alignItems="center">
        {/* <Paper
          component={Stack}
          direction="row"
          elevation={0}
          sx={(theme) => ({
            width: { md: 1 / 2, xs: 1 },
            bgcolor: `${darken(theme.palette.primary.main, 0.1)}!important`,
            color: theme.palette.primary.contrastText,
          })}
        >
          <InputBase
            fullWidth
            size="small"
            placeholder="Procure por Nº do Prontuário, Código de fatura, Códi?"
            sx={(theme) => ({
              ".MuiInputBase-input": {
                py: 0.5,
                px: 1,
                color: theme.palette.primary.contrastText,
              },
            })}
          />
          <IconButton
            size="small"
            sx={{ color: "primary.contrastText", m: 0.5 }}
          >
            <Search />
          </IconButton>
        </Paper> */}
        <Userbar />
      </Stack>
    </Box>
  );
};

export default Header;
