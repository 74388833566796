import {
  ArrowRightAlt,
  Download,
  Loyalty,
  MoreVert,
  RequestQuote,
  Star,
} from "@mui/icons-material";
import {
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
  Pagination as MuiPagination,
  Box,
} from "@mui/material";

import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import { kebabCase, orderBy, sum } from "lodash";
import { format } from "date-fns";
import download from "js-file-download";
import SearchField from "../../components/fields/search-field";
import InfoLabel from "../../components/info-label";
import PageContent from "../../components/page-content";
import http from "../../services/http";
import { DefaultHeader } from "../../components/default-header";
import FormWrapper from "../../components/fields/form-wrapper";
import DateTimeField from "../../components/fields/date-time-field";
import InfoCard from "../../components/info-card";
import { formatNumber } from "../../helpers/currency";
import { getInvoiceStatus } from "../../structs/invoice";
import { Invoice } from "../../models/invoice.model";
import LoadingTable from "../../components/loading-table";
import { getTransactionType } from "../../structs/transaction";
import MenuPopover from "../../components/menu-context";
import { PartnerAgreement } from "../../models/partner-agreement.model";
import { Pagination } from "../../models/pagination.model";

interface IReport {
  invoice: {
    count: number;
    total: {
      values: number;
      start: string | null;
      end: string | null;
    };
    totalHealthInsurance: {
      values: number;
      start: string | null;
      end: string | null;
    };
    totalPrivate: {
      values: number;
      start: string | null;
      end: string | null;
    };
  };
}

const FinancialPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingInvoices, setLoadingInvoices] = useState(false);

  const [loadingReports, setLoadingReports] = useState(false);

  const [reports, setReports] = useState<IReport>({
    invoice: {
      count: 0,
      total: {
        values: 0,
        start: null,
        end: null,
      },
      totalHealthInsurance: {
        values: 0,
        start: null,
        end: null,
      },
      totalPrivate: {
        values: 0,
        start: null,
        end: null,
      },
    },
  });

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<Pagination<Invoice>["meta"]>();
  const [partnerAgreements, setPartnerAgreements] = useState<
    PartnerAgreement[]
  >([]);

  const period = {
    startPeriod: searchParams.get("startPeriod"),
    endPeriod: searchParams.get("endPeriod"),
  };

  const fetchReports = useCallback(async () => {
    setLoadingReports(true);
    const { data, status } = await http.get<IReport>("reports/invoices", {
      params: {
        startPeriod: period.startPeriod,
        endPeriod: period.endPeriod,
      },
    });
    setLoadingReports(false);

    if (status === 200) {
      setReports(data);
    }
  }, [period.endPeriod, period.startPeriod]);

  const fetchInvoices = useCallback(
    async (searchQueryParams: URLSearchParams) => {
      setLoadingInvoices(true);

      if (searchQueryParams.get("date") === "all")
        searchQueryParams.delete("date");

      if (searchQueryParams.get("modality") === "all")
        searchQueryParams.delete("modality");

      const { data, status } = await http.get<Pagination<Invoice>>("invoices", {
        params: {
          paginate: true,
          ...Object.fromEntries(searchQueryParams),
          ...(searchQueryParams.has("id")
            ? {
                id: searchQueryParams.getAll("id"),
              }
            : {}),
        },
      });
      setLoadingInvoices(false);

      if (status === 200) {
        setInvoices(data.items);
        setPaginationMeta(data.meta);
      }
    },
    []
  );

  const fetchPartnerAgreements = useCallback(async (query?: string) => {
    const { data, status } = await http.get<PartnerAgreement[]>(
      "partner-agreements",
      {
        params: {
          ...(query ? { search: query } : {}),
        },
      }
    );

    if (status === 200) {
      setPartnerAgreements(data);
    }
  }, []);

  const exportInvoices = useCallback(
    async (searchQueryParams: URLSearchParams) => {
      if (searchQueryParams.get("date") === "all")
        searchQueryParams.delete("date");

      if (searchQueryParams.get("modality") === "all")
        searchQueryParams.delete("modality");

      const filename = Object.entries(Object.fromEntries(searchQueryParams))
        .map(([, v]) => kebabCase(v))
        .join(".");

      setLoadingDownload(true);
      const { data, status } = await http.get("invoices", {
        headers: {
          Accept: "application/xlsx",
        },
        responseType: "arraybuffer",
        params: {
          paginate: false,
          ...Object.fromEntries(searchQueryParams),
          ...(searchQueryParams.has("id")
            ? {
                id: searchQueryParams.getAll("id"),
              }
            : {}),
        },
      });
      setLoadingDownload(false);
      if (status === 200) {
        download(data, `${filename}.${Date.now()}.xlsx`);
      }
    },
    []
  );

  const onTabChange = useCallback(
    (_: any, value: string) => {
      if (value !== "") {
        searchParams.set("status", value);
      } else {
        searchParams.delete("status");
      }
      searchParams.delete("page");
      navigate({ search: searchParams.toString() }, { replace: true });
    },
    [navigate, searchParams]
  );

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  useEffect(() => {
    Promise.all([fetchInvoices(searchParams)]);
  }, [fetchInvoices, fetchReports, searchParams]);

  useEffect(() => {
    Promise.all([fetchPartnerAgreements()]);
  }, [fetchPartnerAgreements]);

  return (
    <PageContent
      overlayBar
      header={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
          spacing={2}
        >
          <DefaultHeader gutterBottom={false} title="Financeiro" />
          <FormWrapper
            initialValues={{
              startPeriod: searchParams.get("startPeriod") ?? null,
              endPeriod: searchParams.get("endPeriod") ?? null,
            }}
            onSubmit={({ startPeriod: sp, endPeriod: ep }) => {
              const startPeriod = sp;
              let endPeriod = ep;
              if (startPeriod) {
                searchParams.set(
                  "startPeriod",
                  moment(startPeriod).format("YYYY-MM-DD")
                );

                if (endPeriod && moment(startPeriod).isAfter(endPeriod)) {
                  endPeriod = startPeriod;
                }
              } else {
                searchParams.delete("startPeriod");
              }
              if (endPeriod) {
                searchParams.set(
                  "endPeriod",
                  moment(endPeriod).format("YYYY-MM-DD")
                );
              } else {
                searchParams.delete("endPeriod");
              }
              setSearchParams(searchParams);
            }}
          >
            {({ submitForm }) => (
              <Stack direction="row" spacing={2} alignItems="center">
                <FormLabel>Período</FormLabel>
                <DateTimeField
                  textFieldProps={{
                    variant: "outlined",
                    size: "small",
                    placeholder: "Início do Período",
                  }}
                  type="date"
                  name="startPeriod"
                  onChange={submitForm}
                />
                <FormLabel>à</FormLabel>
                <DateTimeField
                  textFieldProps={{
                    variant: "outlined",
                    size: "small",
                    placeholder: "Fim do Período",
                  }}
                  type="date"
                  name="endPeriod"
                  minDate={
                    searchParams.has("startPeriod")
                      ? moment(searchParams.get("startPeriod"))
                      : null
                  }
                  onChange={submitForm}
                />
              </Stack>
            )}
          </FormWrapper>
        </Stack>
      }
    >
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between" spacing={3}>
          <InfoCard
            loading={loadingReports}
            title={
              <>
                Faturamento Total
                <Chip
                  size="small"
                  label="Período"
                  sx={{ fontSize: "inherit", ml: 1 }}
                />
              </>
            }
            icon={RequestQuote}
            value={reports.invoice?.total?.values ?? 0}
            isCurrency
          />
          <InfoCard
            loading={loadingReports}
            title={
              <>
                Convênio
                <Chip
                  size="small"
                  label="Período"
                  sx={{ fontSize: "inherit", ml: 1 }}
                />
              </>
            }
            icon={Loyalty}
            value={reports.invoice.totalHealthInsurance.values}
            isCurrency
          />
          <InfoCard
            loading={loadingReports}
            title={
              <>
                Particular
                <Chip
                  size="small"
                  label="Período"
                  sx={{ fontSize: "inherit", ml: 1 }}
                />
              </>
            }
            icon={Star}
            value={reports.invoice.totalPrivate.values}
            isCurrency
          />
        </Stack>
        <Paper>
          <Box
            sx={{
              position: "sticky",
              top: 0,
              backdropFilter: "blur(10px)",
              backgroundColor: "transparent",
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              zIndex: 2,
            }}
          >
            <Tabs
              value={
                searchParams.has("status") ? searchParams.get("status") : ""
              }
              onChange={onTabChange}
              scrollButtons
              sx={{
                borderTopLeftRadius: (theme) => theme.shape.borderRadius,
                borderTopRightRadius: (theme) => theme.shape.borderRadius,
              }}
            >
              <Tab label="Todos" value="" />
              <Tab label="Pendentes" value="pending" />
              <Tab label="Concluídos" value="concluded" />
            </Tabs>
            <SearchField
              sx={{ p: 2 }}
              queryParamName="search"
              fullWidth
              placeholder="Buscar por Nome, Documento ou Convênio"
              buttons={[
                {
                  label: "Exportar",
                  props: {
                    variant: "contained",
                    startIcon: loadingDownload ? (
                      <CircularProgress size={13} />
                    ) : (
                      <Download />
                    ),
                    disabled: loadingDownload,
                    onClick: () => exportInvoices(searchParams),
                  },
                },
              ]}
            >
              <RadioGroup
                row
                sx={{ flexWrap: "nowrap" }}
                value={searchParams.get("modality") ?? "all"}
                onChange={(_, value) => {
                  if (value && value !== "all") {
                    searchParams.set("modality", value);
                  } else {
                    searchParams.delete("partnerAgreement");
                    searchParams.delete("modality");
                  }
                  navigate({
                    search: searchParams.toString(),
                  });
                }}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="Todos"
                />
                <FormControlLabel
                  value="healthInsurance"
                  control={<Radio />}
                  label="Convênio"
                />
              </RadioGroup>
              <Collapse
                orientation="horizontal"
                in={searchParams.get("modality") === "healthInsurance"}
              >
                <Select
                  variant="outlined"
                  size="small"
                  value={searchParams.get("partnerAgreement") ?? "-1"}
                  onChange={(event) => {
                    if (event.target.value === "-1") {
                      searchParams.delete("partnerAgreement");
                    } else {
                      searchParams.set(
                        "partnerAgreement",
                        `${event.target.value}`
                      );
                    }
                    setSearchParams(searchParams);
                  }}
                >
                  <MenuItem value="-1">Todos</MenuItem>
                  {partnerAgreements.map((partnerAgreement) => (
                    <MenuItem
                      value={partnerAgreement.id}
                      key={partnerAgreement.id}
                    >
                      {partnerAgreement.description}
                    </MenuItem>
                  ))}
                </Select>
              </Collapse>
            </SearchField>
            <Divider />
          </Box>
          <Collapse in={!loadingInvoices && invoices.length === 0}>
            <Typography
              variant="body2"
              sx={{ p: 2, display: "block", textAlign: "center" }}
            >
              Nenhuma fatura no periodo
            </Typography>
          </Collapse>
          <TableContainer>
            <Table>
              <TableBody>
                <LoadingTable loading={loadingInvoices} columns={6} />
                {invoices.map((invoice) => (
                  <TableRow key={invoice.id}>
                    <TableCell>
                      <InfoLabel
                        label="Responsável / Paciente"
                        value={invoice.responsible.fullName}
                        valueProps={{
                          sx: { whiteSpace: "normal" },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <InfoLabel
                        label="Modalidade"
                        value={
                          <Stack
                            direction="row"
                            alignItems="center"
                            divider={
                              <ArrowRightAlt
                                color="disabled"
                                fontSize="small"
                              />
                            }
                          >
                            <span>
                              {invoice.transactions
                                .map((t) => getTransactionType(t.type)?.label)
                                .join(", ")}
                              {invoice.transactions.length === 0 && "-"}
                            </span>
                            {invoice.transactions
                              .map((t) => t.type)
                              .includes("healthInsurance") && (
                              <span>
                                {invoice.transactions
                                  .map(
                                    (t) =>
                                      t.transactionPartnerAgreement
                                        ?.partnerAgreement?.description ?? null
                                  )
                                  .filter(Boolean)}
                              </span>
                            )}
                          </Stack>
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <InfoLabel
                        label="Último Pagamento"
                        value={
                          invoice.transactions.filter(
                            (t) => t.status === "concluded"
                          ).length > 0
                            ? format(
                                new Date(
                                  orderBy(
                                    invoice.transactions.filter(
                                      (t) => t.status === "concluded"
                                    ),
                                    "createdAt",
                                    "desc"
                                  )?.[0]?.createdAt!
                                ),
                                "dd/MM/yyyy HH:mm"
                              )
                            : "-"
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ width: "1%" }}>
                      <InfoLabel
                        label="Valor da Fatura"
                        value={formatNumber(invoice.totalValues)}
                      />
                    </TableCell>
                    <TableCell sx={{ width: "1%" }}>
                      <InfoLabel
                        label="Valor Pago"
                        value={formatNumber(
                          sum(
                            invoice.transactions
                              .filter((t) => t.status === "concluded")
                              .map((t) => +t.value)
                          )
                        )}
                      />
                    </TableCell>
                    <TableCell sx={{ width: "1%" }}>
                      <InfoLabel
                        label="Situação"
                        value={
                          <Chip
                            size="small"
                            label={getInvoiceStatus(invoice.status)?.label}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ width: "1%" }}>
                      <MenuPopover
                        button={
                          <IconButton>
                            <MoreVert />
                          </IconButton>
                        }
                        menu={[
                          {
                            children: "Visualizar Atendimento",
                            onClick() {
                              navigate({
                                pathname: "/orders",
                                search: createSearchParams({
                                  id: invoice.orderItems.map((o) => o.order.id),
                                }).toString(),
                              });
                            },
                          },
                          {
                            children: "Visualizar Fatura",
                            onClick() {
                              navigate(`/invoices/${invoice.id}`);
                            },
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {(paginationMeta?.totalPages ?? 0) > 1 && (
            <Paper
              elevation={0}
              sx={{
                position: "sticky",
                bottom: 0,
                backdropFilter: "blur(10px)",
                backgroundColor: "transparent",
                zIndex: 1,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
              }}
            >
              <Divider />
              <MuiPagination
                color="primary"
                sx={{ p: 2 }}
                page={paginationMeta?.currentPage}
                count={paginationMeta?.totalPages}
                onChange={(_, page) => {
                  searchParams.set("page", String(page));
                  navigate({
                    search: searchParams.toString(),
                  });
                }}
              />
            </Paper>
          )}
        </Paper>
      </Stack>
    </PageContent>
  );
};

export default FinancialPage;
