import React, { ReactNode } from "react";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { Lock } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { DefaultHeader } from "../../components/default-header";
import PageContent from "../../components/page-content";

import whatsAppLogo from "../../assets/images/whatsapp-logo.svg";
import efiLogo from "../../assets/images/efi-logo.svg";

interface IIntegrationItem {
  thumb: string;
  description: string;
  children: ReactNode;
}

const INTEGRATION_LINKS: IIntegrationItem[] = [
  {
    thumb: efiLogo,
    description:
      "A Efí disponibiliza os serviços no contexto do arranjo Pix,\nverificar os Pix recebidos, devolver e enviar Pix.",
    children: (
      <Button component={Link} to="./efi">
        Configurar
      </Button>
    ),
  },
  {
    thumb: whatsAppLogo,
    description:
      "Integração automatizada que permite o envio de resultados de\nexames diretamente aos pacientes de forma rápida e conveniente.",
    children: (
      <Button disabled startIcon={<Lock />}>
        Habilitar
      </Button>
    ),
  },
];

const IntegrationItem: React.FC<IIntegrationItem> = ({
  thumb,
  description,
  children,
}) => (
  <Grid container alignItems="center">
    <Grid item md={2}>
      <Box component="img" src={thumb} sx={{ width: 1, height: 50 }} />
    </Grid>
    <Grid item md>
      <Typography
        sx={{ color: "grey.800", fontSize: 14, whiteSpace: "pre-wrap" }}
      >
        {description}
      </Typography>
    </Grid>
    <Grid item md={false}>
      {children}
    </Grid>
  </Grid>
);

const IntegrationListPage = () => {
  return (
    <PageContent header={<DefaultHeader title="Integrações" />}>
      {INTEGRATION_LINKS.map((props, key) => (
        <Paper
          key={key}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          component={Stack}
          sx={{ p: 2 }}
        >
          <IntegrationItem {...props} />
        </Paper>
      ))}
    </PageContent>
  );
};

export default IntegrationListPage;
