import { orderBy } from "lodash";

export interface OrderStatus {
  label: string;
  value: string;
  order: number;
}

export interface ExamStatus {
  label: string;
  value: string;
  order: number;
}

export interface ExamChannelDeliveryStatus {
  label: string;
  value: string;
}

export const status: OrderStatus[] = orderBy(
  [
    { value: "pending_service", label: "Pendente", order: 0 },
    // { value: "validating_service", label: "Em validação", order: 1 },
    { value: "waiting_for_service", label: "Aguardando", order: 2 },
    { value: "in_attendance", label: "Em atendimento", order: 3 },
    // { value: "pending_payment", label: "Pendente de pagamento", order: 4 },
    { value: "service_finished", label: "Finalizado", order: 5 },
    { value: "service_canceled", label: "Cancelado", order: 6 },
    { value: "scheduled_service", label: "Agendado", order: 7 },
    // { value: "rescheduled_service", label: "Reagendado", order: 8 },
  ],
  ["order"],
  ["asc"]
);

export const channelDeliveries: ExamChannelDeliveryStatus[] = [
  { value: "withdrawal", label: "Retirada" },
  { value: "whatsApp", label: "WhatsApp" },
  { value: "email", label: "E-mail" },
];

export const examStatus: ExamStatus[] = orderBy(
  [
    { value: "pending", label: "Pendente", order: 0 },
    { value: "processing", label: "Processando", order: 1 },
    { value: "done", label: "Finalizado", order: 2 },
    { value: "verified_report", label: "Laudo verificado", order: 3 },
    { value: "finished", label: "Entregue", order: 4 },
  ],
  ["order"],
  ["asc"]
);

export const getChannelDelivery = (
  value: string
): ExamChannelDeliveryStatus => {
  return (
    channelDeliveries.find((s) => s.value === value) ?? {
      label: value,
      value,
    }
  );
};
export const getOrderStatus = (value: string): OrderStatus => {
  return (
    status.find((s) => s.value === value) ?? {
      label: value,
      value,
      order: -1,
    }
  );
};

export const getOrderExamStatus = (value: string): OrderStatus => {
  return (
    examStatus.find((s) => s.value === value) ?? {
      label: value,
      value,
      order: -1,
    }
  );
};
